import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Typography, useTheme } from '@mui/material';
import { Fon, Portfolio } from '../utils/types';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {
  portfolio: Portfolio;
  open: boolean;
  onClick: () => void;
}

export default function HomePortfolioItem({ portfolio, open, onClick }: Props) {

  const nav = useNavigate();
  const theme = useTheme();

  const fons = portfolio.fons.concat(portfolio.umbrella.map(umb => umb.fons).flat());

  const handleClick = (fon: Fon) => {
    let url = `/yatirim-fonlari/${portfolio.slug}`;
    if (fon.umbrellaId) url += `/semsiye-fonlari/${fon.umbrellaId}/${fon.code || fon.id}`;
    else url += `/${fon.code || fon.id}`;
    nav(url);
  }

  return (
    <Paper
      sx={{
        maxWidth: '600px',
        width: '100%',
        height: '100%',
        borderRadius: '12px',
        border: '1px solid rgba(230, 233, 237, 1)',
        borderTop: `10px solid ${theme.palette.primary.main}`,
        background: 'rgba(247, 247, 250, 1)',
        px: 2,
      }}
    >
      <Accordion expanded={open} elevation={0} disableGutters onChange={() => { fons.length > 0 && onClick() }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box
            p={2}
          >
            <Typography
              sx=
              {{
                fontWeight: 600,
                fontSize: { xs: '16px', sm: '18px' },
                lineHeight: '19px',
                color: 'rgba(28,27,31,1)',
                textAlign: 'center'
              }}
            >
              {portfolio.name}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}
          >
            {fons.map((item, index) => (
              <Box
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(item);
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  background: '#F0F3F5',
                  borderRadius: '5px',
                  border: '1px solid #E6E9ED',
                  gap: 1,
                  ml: 1,
                  py: '4px',
                  cursor: 'pointer',
                }}
              >
                <Box
                  sx={{
                    background: theme.palette.primary.main,
                    minWidth: '32px !important',
                    display: 'flex',
                    py: '2px',
                    my: '10px',
                    ml: '10px',
                    borderRadius: '3px',
                    alignItems: 'center',
                    justifyContent: 'center',

                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '8px',
                      lineHeight: '8px',
                      color: 'rgba(255, 255, 255, 1)',
                      textAlign: 'center',
                      mt: 0.3
                    }}
                  >
                    {item.code}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: 'rgba(28, 27, 31, 1)',
                    flex: 1,
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}
