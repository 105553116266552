import { Box, Button, Container, Grid, Paper, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import PageView from '../components/PageView';
import Title from '../components/Title';
import CompanyInfoCard from '../components/CompanyInfoCard';
import ContactCard from '../components/ContactCard';

export default function Contact() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
      }}
    >
      <Title title='İletişim' />
      <iframe
        title="Foneria Portföy Yönetimi A.Ş."
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.4524479339975!2d28.99059497718821!3d41.03723381763963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab770ae5812bb%3A0x47efea2b11e40924!2sFoneria%20Portf%C3%B6y!5e0!3m2!1str!2str!4v1705616010726!5m2!1str!2str"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade">
      </iframe>
      <PageView
        sx={{
          pb: 20
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center',
            gap: 2,
            mt: -30,
            zIndex:9999
          }}
        >
          <CompanyInfoCard />
          <ContactCard />
        </Box>
      </PageView>
    </Box>
  )
}


