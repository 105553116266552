import { Box, Button, Checkbox, FormControlLabel, TextField, Typography, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { sendForm } from '../services/AppServices'
import { AuthContext } from '../contexts/AuthContext'
import { useAlert } from '../utils/useAlert'
import SimpleConfirmDialog from './SimpleConfirmDialog'
import { kvkkHtml } from '../views/Kvkk'

export default function ContactCard() {

  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [checked, setChecked] = React.useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const { token } = useContext(AuthContext);
  const showSnackbar = useAlert();
  const onSubmit = async () => {
    try {
      const res = await sendForm(token, { name, email, phone, message })
      showSnackbar('Form başarıyla gönderildi', 'success')
      setName('')
      setEmail('')
      setPhone('')
      setMessage('')
    } catch (error) {
      console.error(error)
      showSnackbar('Form gönderilirken bir hata oluştu', 'error')
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '6px',
        py: 9,
        px: 7
      }}
    >
      <Typography
        sx={{
          fontSize: '25px',
          fontWeight: 600,

        }}
      >
        İletişim Formu
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: 500,
        }}
      >
        Fonlarımız hakkında daha detaylı bilgi almak istiyorsanız formu doldurmanız yeterli. Foneria Portföy Yöneticilerimiz en kısa sürede bilgi vermek için size ulaşacaktır.
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <TextField
          label="İsim Soyisim"
          variant='standard'
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          sx={{
            mt: 2
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 2
          }}
        >
          <TextField
            label="E-Posta Adresi"
            variant='standard'
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            sx={{
              mt: 2
            }}
          />
          <TextField
            label="Telefon Numarası"
            variant='standard'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
            sx={{
              mt: 2
            }}
          />
        </Box>
        <TextField
          label="Mesajınız"
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          variant='standard'
          fullWidth
          multiline
          rows={4}
          sx={{
            mt: 2
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            mt: 2
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                color="primary"
              />
            }
            label={
              <Typography>
                <span
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setOpen(true)
                  }}
                  style={{
                    color: theme.palette.primary.main,
                    cursor: 'pointer'
                  }}
                >
                  Şartlar ve Koşulları
                </span>
                &nbsp;
                kabul ediyorum
              </Typography>
            } />
          <Button
            variant='contained'
            type='submit'
            disabled={!checked}
            sx={{
              textTransform: 'none',
            }}
          >
            Formu Gönder
          </Button>
        </Box>
      </form>
      <SimpleConfirmDialog
        open={open}
        handleClose={() => setOpen(false)}
        title='Şartlar ve Koşullar'
        description={kvkkHtml}
        handleConfirm={() => { setOpen(false); setChecked(true) }}
        confirmButtonText='Kabul Ediyorum'
      />
    </Box>
  )
}
