import { Delete, OpenInNew } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material'
import React from 'react'
import AddNewLinkPaper from './AddNewLinkPaper';

type Props = {
  links: any;
  setLinks: React.Dispatch<React.SetStateAction<any>>;
}

export default function AnnouncementLinks({ links, setLinks }: Props) {

  const [newLinkActive, setNewLinkActive] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [url, setUrl] = React.useState<string>('');

  return (
    <Box>
      <Typography
        sx={{
          mt: 2,
          fontSize: '1.5rem',
          fontWeight: 700,
        }}
      >
        Linkler
      </Typography>
      <Divider />
      <List sx={{ mt: 2 }}>
        {Object.keys(links).map((key: string, index: number) => (
          <>
            <ListItem>
              <ListItemText
                primary={links[key].title}
                secondary={links[key].url}
              />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    const newLinks = { ...links };
                    delete newLinks[key];
                    setLinks(newLinks);
                  }}
                >
                  <Delete />
                </IconButton>
                <IconButton
                  onClick={() => {
                    window.open(links[key].url, '_blank');
                  }}
                >
                  <OpenInNew />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {index !== Object.keys(links).length - 1 && <Divider />}
          </>
        ))}
      </List>
      {newLinkActive && (
        <AddNewLinkPaper
          title={title}
          setTitle={setTitle}
          url={url}
          setUrl={setUrl}
          handleAdd={() => {
            setLinks({
              ...links,
              [(Object.keys(links).length + 1).toString()]: {
                title: title,
                url: url
              }
            });
            setNewLinkActive(false);
          }}
        />
      )}
      <Box>
        <Button
          onClick={() => {
            setNewLinkActive(!newLinkActive);
            setTitle('');
            setUrl('');
          }}
          variant='outlined'
          sx={{
            mt: 2
          }}
        >
          {newLinkActive ? 'İptal' : 'Yeni Link Ekle'}
        </Button>
      </Box>
    </Box>
  )
}
