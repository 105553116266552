import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import GeneralInfoCard from '../components/GeneralInfoCard';
import PageView from '../components/PageView';
import Title from '../components/Title';
import CommercialLeftBar from '../components/CommercialLeftBar';
import MarkdownViewer from '../components/MarkdownViewer';
import React, { useContext, useEffect } from 'react';
import useSWR from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { AuthContext } from '../contexts/AuthContext';

export default function About() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const {token} = useContext(AuthContext);
  const [title, setTitle] = React.useState<string>('Hakkımızda');
  const [text, setText] = React.useState<string>('');

  const { data, isLoading, error } = useSWR<any>(`${baseUrl}/contents/hakkimizda`, (url: string) => fetcher(url, token));


  useEffect(() => {
    if(data){
      const aboutData = JSON.parse(data.content);
      setTitle(aboutData.title);
      setText(aboutData.text);
    }
  },[data])

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        pb: 27
      }}
    >
      <Title title='Hakkımızda' />
      <PageView
        sx={{
          display: 'flex',
          gap: '10px',
          mb: 22
        }}
      >
        {matches && (<CommercialLeftBar />)}
        <GeneralInfoCard
          title={title}
          description={
            <>
              <MarkdownViewer text={text} />
              {/* <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '20px',
                  lineHeight: '30px',
                  color: 'rgba(33,37,41,1)',
                  pb: 3
                }}
              >
                Foneria Portföy Yönetim A.Ş. ;gelişmiş teknolojik altyapısı ve uzman kadrosuyla yenilikçi yatırım çözümleri sunuyor. Bireysel ve kurumsal yatırımcıların finansal hedeflerine ulaşmaları için, menkul kıymet fonlarından Girişim Sermayesi ve Gayrimenkul Yatırım fonlarına kadar geniş yelpazede yatırım çözümleri sunuyor.Teknoloji ve inovasyonu finansal hizmetlerimizin merkezine yerleştirerek her türlü yatırımcı için erişilebilir, şeffaf ve güvenilir bir yatırım deneyimi yaratıyor. Müşterilerine sunduğu deneyimi daha da iyi hale getirmek yolunda değişimin gücüne inanıyor ve konvansiyonları yıkıyor.
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '20px',
                  lineHeight: '30px',
                  color: 'rgba(33,37,41,1)',
                  pb: 3,
                }}
              >
                Müşterileriyle güven ve memnuniyet temelleri üzerine kurulu ilişkisini, uzman kadrosu ve gelişmiş analitik araçlarıyla piyasa dinamiklerini takip ederek güçlendiriyor.
                Foneria Portföy Yönetimi A.Ş.; yatırım alanındaki uzmanlığı, teknolojisinin gücü ve insani değerleriyle yatırım yolculuğunda her zaman yanınızda.
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '20px',
                  lineHeight: '30px',
                  color: 'rgba(33,37,41,1)',
                  pb: 3,
                }}
              >
                Foneria Portföy Yönetimi A.Ş.; yatırım alanındaki uzmanlığı, teknolojisinin gücü ve insani değerleriyle yatırım yolculuğunda her zaman yanınızda.
              </Typography> */}
            </>
          }
        />
      </PageView>
    </Box>
  );
}
