import { Box, Typography, useTheme } from '@mui/material'
import Companies from '../components/Companies'
import Title from '../components/Title';
import PageView from '../components/PageView';
import { useParams } from 'react-router-dom';
import Faq from '../components/Faq';
import LegalInformation from '../components/LegalInformation';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import GeneralInfoCard from '../components/GeneralInfoCard';

export default function InvestmentFunds() {
  const theme = useTheme();
  const { type } = useParams<{ type: string }>();
  const { portfolios } = useContext(AuthContext);


  const getTitle = () => {

    const title = portfolios.find(portfolio => portfolio.slug === type)?.name;

    if (title) {
      return title;
    }
    return "Yatırım Fonları"
  }

  const funds = portfolios.find(portfolio => portfolio.slug === type)?.fons;
  const umbrellas = portfolios.find(portfolio => portfolio.slug === type)?.umbrella;

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        pb: 8,
      }}>
      <Title title={getTitle()} />
      <PageView>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          <>

            {type === 'menkul-kiymet-yatirim-fonlari' && (
              <GeneralInfoCard
                title='Menkul Kıymet Yatırım Fonları Hakkında'
                description={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 20
                      }}
                    >
                      Menkul Kıymet Yatırım Fonları, lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sed turpis et ante imperdiet fermentum. Nulla eu porttitor orci, nec interdum felis.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 20
                      }}
                    >
                      Mauris eu sem imperdiet, auctor orci sed, rhoncus felis.  Menkul Kıymet Yatırım Fonlarında Foneria Portföy Yönetimi A.Ş., yatırım stratejisi xxx. Ut vitae dapibus sapien, non efficitur felis. Aenean porta laoreet tincidunt. Bu yaklaşım ile yönettiğimiz xxxxx TL büyüklüğünde xxxx Menkul Kıymet Yatırım Fonu praesent non velit sapien. Proin eros dui, posuere eu tellus sed, tempor mattis leo. Donec cursus scelerisque sapien, eget fringilla risus scelerisque ut.
                    </Typography>
                  </Box>
                }
              />
            )}
            {type === 'girisim-sermayesi-yatirim-fonlari' && (
              <GeneralInfoCard
                title='Girişim Sermayesi Yatırım Fonları Hakkında'
                description={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 20
                      }}
                    >
                      Girişim Sermayesi Yatırım Fonları, finans, servis, sağlık, telekomünikasyon, gıda, sağlık, bilişim, teknoloji, ulaştırma, imalat ve perakende sektörleri gibi alanlar başta olmak üzere birçok alanda küçük, orta veya büyük ölçekli şirketlere yatırım yapmak üzere Sermaye Piyasası Kurulu’nun belirlediği sınırlar çerçevesinde sadece nitelikli yatırımcılar* tarafından yatırım yapılabilen süreli fonlardır. Girişim sermayesi yatırım fonları bünyesinde amaç, büyüme potansiyeli olan, operasyonel, üretim ve satış alanlarında kendini geliştirmesi ile yüksek getiriye açık hale gelecek şirketlere yatırım yapmaktır.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        mt: 3
                      }}
                    >
                      <strong>Nitelikli yatırımcı:</strong> Nitelikli yatırımcı, yatırım fonları, emeklilik yatırım fonları, yatırım ortaklıkları, aracı kurumlar, bankalar, sigorta şirketleri, özel finans kurumları, portföy yönetim şirketleri, emekli ve yardım sandıkları, vakıflar, 506 sayılı Sosyal Sigortalar Kanununun geçici 20. maddesi uyarınca kurulmuş olan sandıklar, kamuya yararlı dernekler ile nitelikleri itibariyle bu kurumlara benzer olduğu Kurulca belirlenecek diğer yatırımcılar ve halka arz tarihi itibariyle en az 1 milyon Türk Lirası tutarında Türk ve/veya yabancı para ve sermaye piyasası aracına sahip olan gerçek ve tüzel kişileri ifade etmektedir.                      </Typography>
                    {/* <Typography
                        sx={{
                          fontSize: 20
                        }}
                      >
                        <a
                          href='#'
                          style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'underline'
                          }}
                        >
                          Bilgilendirme dokümanındaki
                        </a>
                        &nbsp;
                        adımları izleyerek Foneria Portföy Yönetimi A.Ş. bünyesinde yer alan girişim sermayesi yatırım fonlarına kolayca yatırım yapabilirsiniz.
                      </Typography> */}
                  </Box>
                }
              />
            )}
            {((funds && funds.length > 0) || (umbrellas && umbrellas.length > 0)) ? (
              <Companies
                funds={funds}
                umbrellas={umbrellas}
              />
            ) : (
              <Typography variant="h6" component="div">
                Henüz bu portföy için yatırım fonu bulunmamaktadır.
              </Typography>
            )}
          </>
          <LegalInformation />
          {/* <Faq /> */}
        </Box>
      </PageView>
    </Box>
  )
}
