import { Box, Card, CardContent, Container, Grid, Link, Typography } from '@mui/material';
import PageView from '../components/PageView';
import Title from '../components/Title';
import fa221 from '../assets/pdfs/fa-22-1.pdf';
import fa222 from '../assets/pdfs/fa-22-2.pdf';
import fa223 from '../assets/pdfs/fa-22-3.pdf';
import fa224 from '../assets/pdfs/fa-22-4.pdf';
import fa231 from '../assets/pdfs/fa-23-1.pdf';
import fa232 from '../assets/pdfs/fa-23-2.pdf';
import fa233 from '../assets/pdfs/fa-23-3.pdf';
import fa234 from '../assets/pdfs/fa-23-4.pdf';
import fa241 from '../assets/pdfs/fa-24-1.pdf';
import fi221 from '../assets/pdfs/fi-22-1.pdf';
import fi222 from '../assets/pdfs/fi-22-2.pdf';
import fi223 from '../assets/pdfs/fi-22-3.pdf';
import fi224 from '../assets/pdfs/fi-22-4.pdf';
import fi231 from '../assets/pdfs/fi-23-1.pdf';
import fi232 from '../assets/pdfs/fi-23-2.pdf';
import fi233 from '../assets/pdfs/fi-23-3.pdf';
import fi234 from '../assets/pdfs/fi-23-4.pdf';
import useSWR from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';

export default function FinancialInfo() {

  type Report = {
    quarter: string;
    faaliyetUrl?: any;
    finansalUrl?: any;
  };

  type Year = {
    id: number;
    year: string;
    reports: Report[];
  };


  const { data, isLoading, error } = useSWR<any>(`${baseUrl}/contents/finansal-bilgiler`, (url: string) => fetcher(url));

  if (isLoading) return <></>;

  const reportsData: Year[] = data ? JSON.parse(data.content) : [];

  return (
    <Box>
      <Title title="Finansal Bilgiler" />
      <PageView>
        <Box>
          <Container maxWidth="md">
            <Box py={4}>
              <Typography variant="h4" gutterBottom>
                Faaliyet ve Finansal Raporlar
              </Typography>
              <Grid container spacing={3}>
                {reportsData.map((yearData) => (
                  <Grid item xs={12} md={6} key={yearData.year}>
                    <Card
                      sx={{
                        height: '100%',
                      }}
                    >
                      <CardContent>
                        <Typography variant="h5" gutterBottom>{yearData.year}</Typography>
                        {yearData.reports.map((report, index) => (
                          <Box key={index} mb={2}>
                            <Typography variant="subtitle1" gutterBottom>{report.quarter}</Typography>
                            <Link href={`${baseUrl}/file/${report.faaliyetUrl?.id}`} target="_blank" rel="noopener">Faaliyet Raporu</Link>
                            <br />
                            <Link href={`${baseUrl}/file/${report.finansalUrl?.id}`} target="_blank" rel="noopener">Finansal Rapor</Link>
                          </Box>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>
      </PageView>
    </Box>
  )
}
