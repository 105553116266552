import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { Portfolio } from '../utils/types';
import { useNavigate } from 'react-router-dom';
import { Umbrella } from '../utils/types';

type UmbrellaCardItemProps = {
  umbrella: Umbrella;
}

export default function UmbrellaCardItem({ umbrella }: UmbrellaCardItemProps) {

  const nav = useNavigate();

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: 2,
        }
      }}
      onClick={() => { nav(`/admin/semsiye-fonlari/${umbrella.id}/duzenle`) }}
    >
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {umbrella.name}
        </Typography>
        {umbrella.description && (
          <Typography variant="body2" color="text.secondary">
            {umbrella.description}
          </Typography>
        )}
        <Typography variant="body2" color="text.secondary">
          Fon Sayısı: {umbrella?.fons?.length || 0}
        </Typography>
      </CardContent>
    </Card>
  )
}
