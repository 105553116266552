import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'
import { BannerItemType } from '../utils/types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

type BannerItemProps = {
  item: BannerItemType;
}

export default function BannerItem({ item }: BannerItemProps) {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const nav = useNavigate();

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: { xs: 400, sm: 800, md: 1300 },
        textAlign: 'center',
        mx: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch', // Çocukları y ekseninde uzat
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 2,
          width: item.image ? '50%' : '80%',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: { xs: '28px', sm: '36px', md: '50px' }, // Responsive font sizes
            lineHeight: { xs: '30px', sm: '40px', md: '60px' },
            textAlign: 'left',
            color: 'white',
            mb: 1,
          }}
        >
          {item.title}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: { xs: '16px', sm: '18px', md: '25px' }, // Responsive font sizes
            lineHeight: { xs: '24px', sm: '30px', md: '42px' },
            color: 'white',
            textAlign: 'left',
          }}
        >
          {item.description}
        </Typography>
        {item.link && (
          <Button
            onClick={() => { nav(item.link) }}
            variant='contained'
            sx={{
              maxWidth: 200,
              color: 'primary.main',
              '&:hover': {
                backgroundColor: 'white',
              },
              backgroundColor: 'white',
              textTransform: 'none',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {item.buttonText}
            <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
          </Button>
        )}
      </Box>
      <>
        {!isSmallScreen && (<Box
          sx={{
            height: isSmallScreen ? 'auto' : '600px',
            display: 'flex', // İçeriği flex olarak ayarla
            flexDirection: 'column', // Yönü sütun olarak ayarla
            justifyContent: item.alignment, // İçeriği hizala
          }}
        >
          {item.image && (<img
            src={item.image}
            alt={item.title}
            style={{
              width: '80%',
              height: 'auto',
              borderRadius: 8,
              marginTop: 16,
            }}
          />)}
        </Box>)}
      </>

    </Box>
  )
}
