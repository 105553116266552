import { Box, Button, FormControlLabel, Switch, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import FonSelect from '../components/FonSelect';
import { Fon, Umbrella } from '../utils/types';
import { LoadingButton } from '@mui/lab';
import { createUmbrella, deleteUmbrella, getUmbrella, updateUmbrella } from '../services/AppServices';
import { AuthContext } from '../contexts/AuthContext';
import { useAlert } from '../utils/useAlert';
import { baseUrl } from '../utils/constants';
import { mutate } from 'swr';
import DeleteDialog from '../components/DeleteDialog';
import AddUmbrellaFiles from '../containers/UmbrellaFiles';

export default function UmbrellaAddEdit() {

  const { id } = useParams();
  const [name, setName] = React.useState('');
  const [slug, setSlug] = React.useState('');
  const [umbrella, setUmbrella] = React.useState<Umbrella>();
  const [description, setDescription] = React.useState('');
  const [priority, setPriority] = React.useState(0);
  const [fons, setFons] = React.useState<Fon[]>([]);
  const [loading, setLoading] = React.useState(false);
  const { token } = useContext(AuthContext);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const nav = useNavigate();
  const showSnackbar = useAlert();

  const handleDelete = async () => {
    try {
      setDeleting(true);
      const res = await deleteUmbrella(token, +id!);
      showSnackbar('Şemsiye fonu başarıyla silindi', 'success');
      nav(`/admin/semsiye-fonlari`)
      mutate(`${baseUrl}/umbrella`);
    } catch (error: any) {
      console.log(error);
      showSnackbar(error.message, 'error');
    }
  }

  function createSlug(input: string): string {
    return input
      .toLowerCase()
      .replace(/ğ/g, 'g')
      .replace(/ü/g, 'u')
      .replace(/ş/g, 's')
      .replace(/ı/g, 'i')
      .replace(/ö/g, 'o')
      .replace(/ç/g, 'c')
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]/g, '');
  }

  useEffect(() => {
    if (name !== '') {
      setSlug(createSlug(name));
    } else {
      setSlug('');
    }
  }, [name])

  const fetchUmbrella = async () => {
    try {
      const res = await getUmbrella(token, +id!);
      setUmbrella(res);
      setName(res.name);
      setSlug(res.slug);
      setDescription(res.description);
      setPriority(res.priority);
      setFons(res.fons);
    } catch (error: any) {
      console.log(error);
      showSnackbar(error.message, 'error');
    }
  }

  React.useEffect(() => {
    if (id) {
      fetchUmbrella();
    }
  }, [id])

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      if (id) {

        const newFons = fons.filter((fon) => {
          return !umbrella?.fons?.find((f) => f.id === fon.id);
        })

        const fonsConnect = newFons.map((fon) => {
          return fon.id;
        })

        const deleteFons = umbrella?.fons?.filter((fon) => {
          return !fons.find((f) => f.id === fon.id);
        })

        const fonsDisconnect = deleteFons?.map((fon) => {
          return fon.id;
        });
        delete values.fons;
        const res = await updateUmbrella(token, +id, {
          ...values,
          fonsConnect,
          fonsDisconnect,
        });
        showSnackbar('Portföy başarıyla güncellendi', 'success');
        nav(`/admin/semsiye-fonlari`)
        mutate(`${baseUrl}/umbrella`);
      } else {
        const fonsConnect = fons.map((fon) => {
          return fon.id;
        })
        delete values.fons;
        const res = await createUmbrella(token, {
          ...values,
          fonsConnect,
        });
        showSnackbar('Portföy başarıyla oluşturuldu', 'success');
        nav(`/admin/semsiye-fonlari`)
        mutate(`${baseUrl}/umbrella`);
      }
    } catch (error: any) {
      console.log(error);
      showSnackbar(error.message, 'error');
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          maxWidth: 750,
          width: '100%',
          m: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 2,
          }}
        >
          {id ? 'Umbrella Düzenle' : 'Umbrella Ekle'}
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit({
              name,
              slug,
              description,
              priority,
              fons,
            });
          }}
        >
          <Box
            sx={{
              mb: 2,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <TextField
              required
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="İsim"
            />
            <TextField
              required
              fullWidth
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              label="Url ismi"
            />
            <TextField
              required
              fullWidth
              type='number'
              value={priority || ''}
              onChange={(e) => setPriority(Number(e.target.value))}
              label="Sıralama"
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label="Açıklama"
            />
            <FonSelect
              values={fons}
              onChange={(values) => setFons(values)}
            />
            {umbrella && <AddUmbrellaFiles
              umbrella={umbrella}
              fetchUmbrella={fetchUmbrella}
            />}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Box>
                {id && (
                  <Button
                    onClick={() => setDeleteDialogOpen(true)}
                    variant='outlined'
                  >
                    Sil
                  </Button>
                )}
              </Box>
              <Box>
                <Button>
                  Vazgeç
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                  loadingPosition="start"
                >
                  Kaydet
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
      <DeleteDialog
        title='Şemsiye Fon sil'
        description='Bu Şemsiye fonunu silmek istediğinize emin misiniz?'
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => handleDelete()}
        deleting={deleting}
      />
    </Box>
  )
}
