import { Box, TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import GradeItem from './GradeItem'
import { Fon } from '../utils/types';

type Props = {
  fund: Fon;
  selectedPeriod: string;
}

export default function FundsIncomeTableRowItem({ fund, selectedPeriod }: Props) {

  const getreturn = (period: string) => {
    switch (period) {
      case 'Son 1 Gün':
        return fund.lastDayReturn;
      case 'Son 1 Hafta':
        return fund.lastWeekReturn;
      case 'Son 1 Ay':
        return fund.lastMonthReturn;
      case 'Son 3 Ay':
        return fund.lastThreeMonthsReturn;
      case 'Son 6 Ay':
        return fund.lastSixMonthsReturn;
      case 'Son 1 Yıl':
        return fund.lastOneYearReturn;
      case 'Yıl Başından İtibaren':
        return fund.lastBeginningOfYearReturn;
      case 'Tüm Zamanlar':
        return fund.allTimeReturn;
      default:
        return 0;
    }
  }

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell>
        <Typography
          sx={{
            fontWeight: 500
          }}
        >
          <strong>
            {fund.code}
          </strong>
          &nbsp;
          {fund.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: 700,
            }}
          >
            {fund.riskGrade}
          </Typography>
          <GradeItem
            key={fund.id}
            grade={fund.riskGrade ? +fund.riskGrade : 0}
          />
        </Box>
      </TableCell>
      <TableCell
        sx={{
          fontSize: '15px',
          fontWeight: 700,
        }}
      >
        {fund.pricePerItem}
      </TableCell>
      <TableCell
        sx={{
          fontSize: '15px',
          fontWeight: 700,
        }}
        align='center'
      >
        {getreturn(selectedPeriod) ? (getreturn(selectedPeriod)! * 100).toFixed(6) : 0}%
      </TableCell>
    </TableRow>
  )
}
