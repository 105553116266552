import { Box, useMediaQuery, useTheme } from '@mui/material';
import arda from '../assets/arda.jpg';
import derya from '../assets/derya.jpg';
import emrah from '../assets/emrah.jpg';
import managers from '../assets/ilkay-civelek.jpg';
import leyla from '../assets/leyla.jpeg';
import semih from '../assets/semih-sezgin.jpg';
import managers2 from '../assets/yeliz-yavuz.jpg';
import CommercialLeftBar from '../components/CommercialLeftBar';
import ManagersInfo from '../components/ManagersInfo';
import PageView from '../components/PageView';
import Title from '../components/Title';
import useSWR from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';

export default function Managers() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { data: managersData, isLoading, error } = useSWR<any>(`${baseUrl}/contents/yoneticilerimiz`, (url: string) => fetcher(url));

  if (isLoading) {
    return <Box></Box>
  }

  const managersDatas = [
    {
      name: 'İlkay Civelek',
      position: 'Genel Müdür',
      image: managers,
      education: `Marmara Üniversitesi İktisadi İdari Bilimler Fakültesi, İşletme Bölümü 94’ |
                  Marmara Üniversitesi İ.İ.B.F. Muhasebe ve Finansman Yüksek Lisansı
      `,
      information: `
      Sermaye piyasalarında 30 yılı aşkın deneyime sahip olan Civelek, iş hayatına bağımsız denetçi olarak başlamıştır. Türkiye’nin ilk online hisse senedi platformu olan Ata Online’ın kurucu ekibinde Genel Müdür Yardımcısı olarak görev almıştır. 2002 yılında Oyak Yatırım’da Yaşar Yatırım ve OB Menkul birleşmesine liderlik etmiş ve Oyak Yatırım Ortaklığı’nın genel müdürü olarak görev yapmıştır. 2012 yılında Alternatif Yatırım’da  genel müdür yardımcısı olarak başladığı görevine genel müdür vekili olarak devam etmiştir. 2015 yılında  Ata Finans Grubunda 8 yıl boyunca kurumun yurtiçi piyasalardan sorumlu genel müdür yardımcısı olarak görev almıştır. Profesyonel iş hayatına İnfo Yatırım’da portföy yöneticisi olarak devam eden Civelek, Nisan 2024’te Foneria Portföy Yönetimi’ne katılarak genel müdür ve yönetim kurulu üyesi olarak çalışmaya devam etmektedir. 
      <br />
      <br />
      Civelek; finans piyasalarındaki uzmanlığının yanı sıra, şirket birleşmeleri ve değerleme, halka arz, iş geliştirme, stratejik iş ilişkileri, bireysel portföy yönetimi, çağrı merkezi ve CRM sistemi kurma, ürün ve kampanya yönetimi, müşteri sadakati ve pazarlama konularında deneyime sahiptir. 
      <br />
      <br />
      Mali Müşavirlik ruhsatı, Sermaye Piyasaları İleri Düzey, Türev Araçlar ve Kurumsal yönetim derecelendirme lisanslarına sahiptir.
      `,
      linkedin: 'https://www.linkedin.com/in/ilkaycivelek/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    },
    {
      name: "Leyla Ercan Uyum",
      position: "Genel Müdür Yardımcısı",
      image: leyla,
      information: `
      Sermaye piyasalarında 25 yılı aşkın deneyime sahip olan Leyla Uyum, iş hayatına 1998 yılında Türk Ticaret Bankası A.Ş. de uzman olarak başlamıştır.2000 yılında kuruluşundan itibaren Ak Portföy Yönetimi A.Ş. de portföy yöneticisi,2018 yılında Fiba Portföy Yönetimi A.Ş. de Müfettiş Müdür görevini üstlenmiştir. 2022 de iş hayatına Icbc Turkey Portföy Yönetimi A.Ş. de Direktör olarak devam eden Uyum, Temmuz 2024’te Foneria Portföy Yönetimi A.Ş. ye  katılarak Mali Ve İdari İşlerden Sorumlu Genel Müdür Yardımcısı olarak çalışmaya devam etmektedir.
      <br />
      <br />
      Sermaye Piyasaları İleri Düzey ve Türev Araçlar lisanslarına sahiptir.
      `,
      education: `
      Gazi Üniversitesi İktisadi İdari Bilimler Fakültesi, İktisat  Bölümü 95’ |
      Okan Üniversitesi İşletme Yüksek Lisansı 2016
      `,
      linkedin: "",
    },
    {
      name: 'Emrah Şahsıvar',
      position: 'Varlık Yönetimi Strateji Direktörü',
      information: `
        Finans Piyasalarında 15 yılı aşkın deneyime sahip olan Emrah Şahsıvar; meslek hayatına 2007 yılında Tekstil Yatırım’da Varlık Yönetimi ve Hazine Biriminde Uzman olarak başladı. Tekstilbank ve Tekstil Yatırım’ın fonlarının kurulması ve tüm fonların yönetilmesinde aktif görev alan Şahsıvar aynı zamanda kurum portföyünün yönetilmesinde de görev aldı. 2014 yılında Tekstil Yatırım’dan Fon Müdürü iken ayrılarak, Yapı Kredi Yatırım da Üst Segment Yatırım Danışmanı olarak göreve başladı. 10 yıl boyunca Yapı Kredi’nin nitelikli bireysel-kurumsal yatırımcılarına yatırım danışmanlığı ve finansal analiz desteği verdikten sonra Temmuz 2024’te Bölüm Yöneticisi iken Yapı Kredi Yatırım’dan ayrıldı. Ağustos 2024’te Foneria Portföy Yönetimi A.Ş. ye katılarak Varlık Yönetimi ve Strateji Direktörü olarak çalışmaya başladı.
        <br />
        <br />
        Sermaye Piyasaları İleri Düzey, Türev Araçlar ve Kurumsal yönetim derecelendirme lisanslarına sahiptir.
      `,
      linkedin: 'https://www.linkedin.com/in/emrah-%C5%9Fahs%C4%B1var-70522469?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
      education: `
      Anadolu Üniversitesi İşletme Lisans '70
      `,
      image: emrah
    },
    // {
    //   name: '',
    //   position: '',
    //   information: `

    //   `,
    //  education: '',
    //   linkedin: '',
    //   image: ''
    // },


    {
      name: "Semih Sezgin",
      position: "Direktör",
      image: semih,
      linkedin: "https://www.linkedin.com/in/semih-sezgin-7829117b/",
      edcation: `
        Dumlupınar Üniversitesi Kamu Yönetimi Bölümü’nden Lisans
      `,
      information:
        `
        Finans sektöründe 11 yıllık tecrübesi bulunan Sezgin, 9 yıl HSBC ve Odea Bankta portföy yöneticisi olarak görev yapmıştır. Şuan Foneria Portföy Yönetimi A.Ş. bünyesinde Direktör olarak çalışmaktadır.
        <br />
        <br />
        Sermaye Piyasaları İleri Düzey ve Türev Araçlar lisanslarına sahiptir.
        `
    },
    {
      name: 'Arda Armutçu',
      position: 'Risk Yönetimi Müdürü',
      information: `
        10 Yılı Sermaye piyasalarında Risk Yönetimi olmak üzere 15 yıl deneyime sahip olan Armutçu, Kaptan Şirketler Grubunda Finans Departmanında çalışmaya başlamış, Alternatifbank’ta Risk Yönetimi Departmanında ardından XTB Menkul Değerler’de Araştırma Uzmanı olarak çalışmış. 5 yıl süreyle Azimut Portföy Yönetimi’nde Risk Yöneticisi olarak çalışmıştır. Engie’de Enerji Piyasa Riski Analisti olarak çalıştıktan sonra Osmanlı Portföy’de Risk Yöneticisi olarak çalışmıştır. Ardından 1,5 yıl Riskactivede Risk Yönetimi Danışmanı olarak iş hayatına devam etmiştir. Lisans ve Yüksek Lisans Eğitimini İstanbul Bilgi Üniversitesi’nde tamamlayan Armutçu şu an İstanbul Üniversitesi’nde Latin Dili Eğitimi alanında ikinci lisans eğitimine devam etmektedir.
      `,
      linkedin: 'https://www.linkedin.com/in/arda-armut%C3%A7u-3810473a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
      education: `
        Bilgi Üniversitesi Finans Matematiği Lisans | 
        Uluslararası İlişkiler Yan Dal |
        Bilgi Üniversitesi - Finansal Ekonomi/Sayısal Finans Yüksek Lisans |
        Spk Düzey 3 ve Türev Araçlar Lisansı
      `,
      image: arda
    },
    {
      name: 'Yeliz Yavuz',
      position: 'Müşteri İlişkileri Müdürü',
      information: `
      15 yılı aşkın Bireysel Bankacılık deneyimine sahip olan Yavuz; 2008 yılında Akbank T.A.Ş Bireysel Bankacılık Satış ve Pazarlama Yönetimi alanında başladığı kariyerine,İnsan Kaynakları Yöneticiliği sonrası 1e1 Bankacılık Yöneticisi olarak devam etmiştir.
      <br />
      <br />
      Ocak 23’ itibariyle Foneria Portföy ‘de Müşteri İlişkileri Müdürlüğü görevini devam ettirmektedir.
      <br />
      <br />
      Sermaye Piyasası Düzey 1 lisansı, Müşteri İlişkileri Yönetimi (CRM) ve Yetenek Yönetimi, CRM -Pazarlama ve Satış Yönetimi Modülü Tasarım Ve Uygulama ,CSM Uyarlama&Tasarım Anahtar Kullanıcı Eğitimi ve Müşteri Hizmetleri Yönetimi Modülü Sertifikalarına sahiptir.
      `,
      linkedin: 'https://www.linkedin.com/in/yeliz-yavuz-1b708477/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
      education: `
      Gazi Üniversitesi İ.İ.B.FÇalışma Ekonomisi ve Endüstri İlişkileri 06’ |
      Anadolu Üniversitesi İnsan Kaynakları Yönetimi Bölümü 23’
      `,
      image: managers2
    },

    {
      name: 'Derya Tekbaş',
      position: 'Teftiş Direktörü',
      information: `
    Sermaye piyasalarında 20 yılı aşkın tecrübeye sahip olan Derya Tekbaş, iş hayatına 2002 yılında Ziraat Yatırım’da başlamıştır. Şu an Foneria Portföy Yönetimi A.Ş. bünyesinde Direktör olarak çalışmaktadır.
    <br />
    <br />
    Sermaye Piyasaları İleri Düzey ve Türev Araçlar lisansları ile ISO/IEC 27001 BGYS sertifikasına sahiptir.
      `,
      linkedin: 'http://www.linkedin.com/in/derya-tekba%C5%9F',
      education: `
      Anadolu Üniversitesi İşletme Lisans 2005 |
      Sakarya Üniversitesi İşletme Yönetimi Yüksek Lisans 2011
      `,
      image: derya
    },
  ]

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        gap: 8
      }}
    >
      <Title title='Yöneticilerimiz' />
      <PageView
        sx={{
          display: 'flex',
          gap: '10px',
          mb: 22
        }}
      >
        {matches && (<CommercialLeftBar />)}
        <ManagersInfo
          managersData={managersData.content ? JSON.parse(managersData.content) : []}
          hidePartnerShipTable
        />
      </PageView>
    </Box>
  )
}
