import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardActions, CardMedia, IconButton, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { createFund, deleteFund, getFund, updateFund } from '../services/AppServices';
import { AuthContext } from '../contexts/AuthContext';
import { useAlert } from '../utils/useAlert';
import { mutate } from 'swr';
import { baseUrl } from '../utils/constants';
import { Fon } from '../utils/types';
import DeleteDialog from '../components/DeleteDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { Add } from '@mui/icons-material';
import AddInformationFiles from '../containers/AddInformationFiles';

export default function FundAddEdit() {

  const { id } = useParams();
  const { token } = useContext(AuthContext);

  const [name, setName] = React.useState('');
  const [slug, setSlug] = React.useState('');
  const [longName, setLongName] = React.useState('');
  const [code, setCode] = React.useState('');
  const [priority, setPriority] = React.useState(0);
  const [lastDayReturn, setLastDayReturn] = React.useState(0);
  const [lastWeekReturn, setLastWeekReturn] = React.useState(0);
  const [lastMonthReturn, setLastMonthReturn] = React.useState(0);
  const [lastThreeMonthsReturn, setLastThreeMonthsReturn] = React.useState(0);
  const [lastSixMonthsReturn, setLastSixMonthsReturn] = React.useState(0);
  const [lastBeginningOfYearReturn, setLastBeginningOfYearReturn] = React.useState(0);
  const [allTimeReturn, setAllTimeReturn] = React.useState(0);
  const [lastOneYearReturn, setLastOneYearReturn] = React.useState(0);
  const [logoUrl, setLogoUrl] = React.useState('');
  const [pricePerItem, setPricePerItem] = React.useState(0);
  const [strategy, setStrategy] = React.useState('');
  const [fundDuration, setFundDuration] = React.useState('');
  const [sellingTarget, setSellingTarget] = React.useState('');
  const [minimumInvestment, setMinimumInvestment] = React.useState('');
  const [fundStartDate, setFundStartDate] = React.useState('');
  const [exitTime, setExitTime] = React.useState('');
  const [stoppage, setStoppage] = React.useState('');
  const [eventStartTime, setEventStartTime] = React.useState('');
  const [valorTime, setValorTime] = React.useState('');
  const [lastEventTime, setLastEventTime] = React.useState('');
  const [fundBuyValor, setFundBuyValor] = React.useState('');
  const [fundSellValor, setFundSellValor] = React.useState('');
  const [fundEntryExitFees, setFundEntryExitFees] = React.useState('');
  const [purchasingFundParticipationShares, setPurchasingFundParticipationShares] = React.useState('');
  const [managementFee, setManagementFee] = React.useState('');
  const [riskGrade, setRiskGrade] = React.useState(0);
  const [informationFiles, setInformationFiles] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const showSnackbar = useAlert();
  const nav = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const [fund, setFund] = React.useState<Fon>();

  function createSlug(input: string): string {
    return input
      .toLowerCase()
      .replace(/ğ/g, 'g')
      .replace(/ü/g, 'u')
      .replace(/ş/g, 's')
      .replace(/ı/g, 'i')
      .replace(/ö/g, 'o')
      .replace(/ç/g, 'c')
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]/g, '');
  }

  useEffect(() => {
    setSlug(createSlug(name));
  }, [name])

  const fetchFund = async () => {
    try {
      const res = await getFund(token, +id!);
      setFund(res);
      setName(res.name);
      setLongName(res.longName);
      setCode(res.code);
      setPriority(res.priority);
      setLastOneYearReturn(res.lastOneYearReturn);
      setSlug(res.slug);
      setLastDayReturn(res.lastDayReturn || 0);
      setLastWeekReturn(res.lastWeekReturn || 0);
      setLastMonthReturn(res.lastMonthReturn || 0);
      setLastThreeMonthsReturn(res.lastThreeMonthsReturn || 0);
      setLastSixMonthsReturn(res.lastSixMonthsReturn || 0);
      setLastBeginningOfYearReturn(res.lastBeginningOfYearReturn || 0);
      setAllTimeReturn(res.allTimeReturn || 0);
      setLogoUrl(res.logoUrl);
      setPricePerItem(res.pricePerItem);
      setStrategy(res.strategy);
      setFundDuration(res.fundDuration);
      setSellingTarget(res.sellingTarget);
      setMinimumInvestment(res.minimumInvestment);
      setFundStartDate(res.fundStartDate);
      setExitTime(res.exitTime);
      setFundEntryExitFees(res.fundEntryExitFees);
      setPurchasingFundParticipationShares(res.purchasingFundParticipationShares);
      setManagementFee(res.managementFee);
      setRiskGrade(res.riskGrade);
      setInformationFiles(res.informationFiles);
      setStoppage(res.stoppage);
      setEventStartTime(res.eventStartTime);
      setValorTime(res.valorTime);
      setLastEventTime(res.lastEventTime);
      setFundBuyValor(res.fundBuyValor);
      setFundSellValor(res.fundSellValor);
    } catch (error: any) {
      console.log(error);
      showSnackbar(error.message, 'error');
    }
  }

  React.useEffect(() => {
    if (id) {
      fetchFund();
    }
  }, [id])

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      if (id) {
        const res = await updateFund(token, +id, values);
        showSnackbar('Fon başarıyla güncellendi', 'success');
        nav(`/admin/fonlar`);
        mutate(`${baseUrl}/fon`);
      } else {
        delete values.informationFiles;
        const res = await createFund(token, values);
        showSnackbar('Fon başarıyla oluşturuldu', 'success');
        nav(`/admin/fonlar/${res.id}/duzenle`);
        mutate(`${baseUrl}/fon`);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await deleteFund(token, +id!);
      showSnackbar('Fon silindi.', 'success');
      nav(`/admin/fonlar`);
      mutate(`${baseUrl}/fon`);
    } catch (e) {
      console.log(e);
      showSnackbar('Fon silinirken bir hata oluştu.', 'error');
    } finally {
      setDeleteDialogOpen(false);
      setDeleting(false);
    }
  }

  const handleImageSelect = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setLogoUrl(e.target?.result as string);
    }
    reader.readAsDataURL(file);
  }

  const handleImageDelete = () => {
    setLogoUrl('');
  }

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          maxWidth: 750,
          width: '100%',
          m: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 2,
          }}
        >
          {id ? 'Fon Düzenle' : 'Fon Ekle'}
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit({
              name,
              longName,
              slug,
              code,
              priority,
              lastOneYearReturn,
              lastDayReturn,
              lastWeekReturn,
              lastMonthReturn,
              lastThreeMonthsReturn,
              lastSixMonthsReturn,
              lastBeginningOfYearReturn,
              allTimeReturn,
              logoUrl,
              pricePerItem,
              strategy,
              fundDuration,
              sellingTarget,
              minimumInvestment,
              fundStartDate,
              exitTime,
              stoppage,
              eventStartTime,
              valorTime,
              lastEventTime,
              fundBuyValor,
              fundSellValor,
              fundEntryExitFees,
              purchasingFundParticipationShares,
              managementFee,
              riskGrade,
            });
          }}
        >
          <Box
            sx={{
              mb: 2,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <TextField
              required
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="İsim"
            />
            <TextField
              required
              fullWidth
              value={longName}
              onChange={(e) => setLongName(e.target.value)}
              label="Uzun İsim"
            />
            <TextField
              required
              fullWidth
              value={slug || ''}
              onChange={(e) => setSlug(e.target.value)}
              label="Url ismi"
            />
            <TextField
              fullWidth
              value={code}
              onChange={(e) => setCode(e.target.value)}
              label="Kod"
            />
            <TextField
              fullWidth
              type='number'
              value={priority || ''}
              onChange={(e) => setPriority(Number(e.target.value))}
              label="Sıralama"
            />
            <TextField
              fullWidth
              value={lastDayReturn?.toString() || ''}
              type='number'
              onChange={(e) => setLastDayReturn(parseFloat(e.target.value))}
              label="Son 1 Gün Getirisi"
              inputProps={{
                step: "0.0001"  // Küsüratlı sayıları kabul etmek için adım miktarını ayarlayın
              }}
            />
            <TextField
              fullWidth
              value={lastWeekReturn?.toString() || ''}
              type='number'
              onChange={(e) => setLastWeekReturn(parseFloat(e.target.value))}
              label="Son 1 Hafta Getirisi"
              inputProps={{
                step: "0.0001"  // Küsüratlı sayıları kabul etmek için adım miktarını ayarlayın
              }}
            />
            <TextField
              fullWidth
              value={lastMonthReturn?.toString() || ''}
              type='number'
              onChange={(e) => setLastMonthReturn(parseFloat(e.target.value))}
              label="Son 1 Ay Getirisi"
              inputProps={{
                step: "0.0001"  // Küsüratlı sayıları kabul etmek için adım miktarını ayarlayın
              }}
            />
            <TextField
              fullWidth
              value={lastThreeMonthsReturn?.toString() || ''}
              type='number'
              onChange={(e) => setLastThreeMonthsReturn(parseFloat(e.target.value))}
              label="Son 3 Ay Getirisi"
              inputProps={{
                step: "0.0001"  // Küsüratlı sayıları kabul etmek için adım miktarını ayarlayın
              }}
            />
            <TextField
              fullWidth
              value={lastSixMonthsReturn?.toString() || ''}
              type='number'
              onChange={(e) => setLastSixMonthsReturn(parseFloat(e.target.value))}
              label="Son 6 Ay Getirisi"
              inputProps={{
                step: "0.0001"  // Küsüratlı sayıları kabul etmek için adım miktarını ayarlayın
              }}
            />
            <TextField
              fullWidth
              value={lastBeginningOfYearReturn?.toString() || ''}
              type='number'
              onChange={(e) => setLastBeginningOfYearReturn(parseFloat(e.target.value))}
              label="Yıl Başından İtibaren Getirisi"
              inputProps={{
                step: "0.0001"  // Küsüratlı sayıları kabul etmek için adım miktarını ayarlayın
              }}
            />
            <TextField
              fullWidth
              value={lastOneYearReturn?.toString() || ''}
              type='number'
              onChange={(e) => setLastOneYearReturn(parseFloat(e.target.value))}
              label="Son 1 Yıl Getirisi"
              inputProps={{
                step: "0.0001"  // Küsüratlı sayıları kabul etmek için adım miktarını ayarlayın
              }}
            />
            <TextField
              fullWidth
              value={allTimeReturn?.toString() || ''}
              type='number'
              onChange={(e) => setAllTimeReturn(parseFloat(e.target.value))}
              label="Tüm Zamanlar Getirisi"
              inputProps={{
                step: "0.0001"  // Küsüratlı sayıları kabul etmek için adım miktarını ayarlayın
              }}
            />

            <Card sx={{ display: 'inline-block' }}>
              <Typography variant="h6" component="div" sx={{ p: 2 }}>
                Logo
              </Typography>
              <CardMedia
                component="img"
                image={logoUrl || 'https://via.placeholder.com/400x400'}
                alt="cover-image"
                sx={{ maxHeight: 350, objectFit: 'contain' }}
              />
              <CardActions>
                <Button component="label" size="large" color="primary">
                  RESİM EKLE
                  <input type="file" hidden accept="image/*" onChange={handleImageSelect} />
                </Button>
                <IconButton aria-label="delete" size="small" color="primary" onClick={handleImageDelete}>
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
            <TextField
              fullWidth
              value={stoppage}
              onChange={(e) => setStoppage(e.target.value)}
              label="Stopaj Oranı"
            />
            <TextField
              fullWidth
              value={eventStartTime}
              onChange={(e) => setEventStartTime(e.target.value)}
              label="İşlem Başlangıç Saati"
            />
            <TextField
              fullWidth
              value={valorTime}
              onChange={(e) => setValorTime(e.target.value)}
              label="Valör Atama Saati"
            />
            <TextField
              fullWidth
              value={lastEventTime}
              onChange={(e) => setLastEventTime(e.target.value)}
              label="Son İşlem Saati"
            />
            <TextField
              fullWidth
              value={fundBuyValor}
              onChange={(e) => setFundBuyValor(e.target.value)}
              label="Fon Alış Valörü"
            />
            <TextField
              fullWidth
              value={fundSellValor}
              onChange={(e) => setFundSellValor(e.target.value)}
              label="Fon Satış Valörü"
            />

            <TextField
              fullWidth
              value={pricePerItem || ''}
              type='number'
              onChange={(e) => setPricePerItem(parseFloat(e.target.value))}
              label="Birim Fiyat"
              inputProps={{
                step: "0.000000001"  // Küsüratlı sayıları kabul etmek için adım miktarını ayarlayın
              }}
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              value={strategy}
              onChange={(e) => setStrategy(e.target.value)}
              label="Strateji"
            />
            <TextField
              fullWidth
              multiline
              rows={2}
              value={fundDuration}
              onChange={(e) => setFundDuration(e.target.value)}
              label="Fon Süresi"
            />
            <TextField
              fullWidth
              value={sellingTarget}
              onChange={(e) => setSellingTarget(e.target.value)}
              label="Satış Hedefi"
            />
            <TextField
              fullWidth
              value={minimumInvestment}
              onChange={(e) => setMinimumInvestment(e.target.value)}
              label="Asgari İşlem Limitleri"
            />
            <TextField
              fullWidth
              value={fundStartDate}
              onChange={(e) => setFundStartDate(e.target.value)}
              label="Fon Başlangıç Tarihi"
            />
            <TextField
              fullWidth
              value={exitTime}
              onChange={(e) => setExitTime(e.target.value)}
              label="Fondan Çıkış Dönemleri"
            />
            <TextField
              fullWidth
              value={fundEntryExitFees}
              onChange={(e) => setFundEntryExitFees(e.target.value)}
              label="Fon Giriş/Çıkış Ücretleri"
            />
            <TextField
              fullWidth
              value={purchasingFundParticipationShares}
              onChange={(e) => setPurchasingFundParticipationShares(e.target.value)}
              label="Fon Katılım Payları"
            />
            <TextField
              fullWidth
              value={managementFee}
              onChange={(e) => setManagementFee(e.target.value)}
              label="Yönetim Ücreti"
            />
            <TextField
              fullWidth
              value={riskGrade || ''}
              onChange={(e) => setRiskGrade(Number(e.target.value))}
              label="Risk Derecesi"
            />
            {fund && <AddInformationFiles
              fund={fund}
              fetchFund={fetchFund}
            />}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Box>
                {id && (
                  <Button
                    onClick={() => setDeleteDialogOpen(true)}
                    variant='outlined'
                  >
                    Sil
                  </Button>
                )}
              </Box>
              <Box>
                <Button>
                  Vazgeç
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                  loadingPosition="start"
                >
                  Kaydet
                </LoadingButton>
              </Box>

            </Box>
          </Box>
        </form>
      </Box>
      <DeleteDialog
        title='Fon sil'
        description='Bu fonu silmek istediğinize emin misiniz?'
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => handleDelete()}
        deleting={deleting}
      />
    </Box>
  )
}
