// export const updateUser = async (id: number, token: string, values: any) => {
//   const url = `${baseUrl}/users/${id}`;
//   const response = await fetch(url, {
//     method: 'PUT',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${token}`
//     },
//     body: JSON.stringify(values)
//   });

import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

//   const res = await handleResponse(response);
//   return res;
// }

export const uploadFile = async (fd: FormData, token: string) => {
  const url = `${baseUrl}/file`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    body: fd,
  });
  const resData = await handleResponse(response);
  return resData;
}

export const deleteFile = async (id: number, token: string) => {
  const url = `${baseUrl}/file/${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  const resData = await handleResponse(response);

  return resData;
}


export const createPortfolio = async (token: string, values: any) => {
  const url = `${baseUrl}/portfolio`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const getPortfolios = async () => {
  const url = `${baseUrl}/portfolio`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  const res = await handleResponse(response);
  return res;
}

export const updatePortfolio = async (token: string, id: number, values: any) => {
  const url = `${baseUrl}/portfolio/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const getPortfolio = async (token: string, id: number) => {
  const url = `${baseUrl}/portfolio/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const res = await handleResponse(response);
  return res;
}

export const deletePortfolio = async (token: string, id: number) => {
  const url = `${baseUrl}/portfolio/${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const res = await handleResponse(response);
  return res;
}



export const createUmbrella = async (token: string, values: any) => {
  const url = `${baseUrl}/umbrella`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const getUmbrellas = async () => {
  const url = `${baseUrl}/umbrella`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  const res = await handleResponse(response);
  return res;
}

export const updateUmbrella = async (token: string, id: number, values: any) => {
  const url = `${baseUrl}/umbrella/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const getUmbrella = async (token: string, id: number) => {
  const url = `${baseUrl}/umbrella/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const res = await handleResponse(response);
  return res;
}

export const deleteUmbrella = async (token: string, id: number) => {
  const url = `${baseUrl}/umbrella/${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const res = await handleResponse(response);
  return res;
}


export const getFund = async (token: string, id: number) => {
  const url = `${baseUrl}/fon/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const res = await handleResponse(response);
  return res;
}

export const createFund = async (token: string, values: any) => {
  const url = `${baseUrl}/fon`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const updateFund = async (token: string, id: number, values: any) => {
  const url = `${baseUrl}/fon/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const deleteFund = async (token: string, id: number) => {
  const url = `${baseUrl}/fon/${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const res = await handleResponse(response);
  return res;
}

export const createAnnouncement = async (token: string, values: any) => {
  const url = `${baseUrl}/announcement`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const updateAnnouncement = async (token: string, id: number, values: any) => {
  const url = `${baseUrl}/announcement/${id}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const deleteAnnouncement = async (token: string, id: number) => {
  const url = `${baseUrl}/announcement/${id}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const res = await handleResponse(response);
  return res;
}

export const getAnnouncement = async (token: string, id: number) => {
  const url = `${baseUrl}/announcement/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const res = await handleResponse(response);
  return res;
}

export const sendForm = async (token: string, values: any) => {
  const url = `${baseUrl}/announcement/contact`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);
  return res;
}

export const createContentData = async (token: string, values: any) => {
  const url = `${baseUrl}/contents`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      type: values.type,
      content: JSON.stringify(values.content)
    })
  });

  const res = await handleResponse(response);
  return res;
}
