import { Box } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import LoginContainer from '../containers/LoginContainer'
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function Login() {

  const { exp } = useContext(AuthContext);
  const nav = useNavigate();
  useEffect(() => {
    if (exp) {
      if (exp < new Date()) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
      } else {
        nav('/admin');
      }
    }
  }, [exp])

  return (
    <Box>
      <LoginContainer />
    </Box>
  )
}
