import { Box } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import Footer from './Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import Appbar from './Appbar'
import SideNav from './SideNav'
import { AuthContext } from '../contexts/AuthContext'

type AdminLayoutProps = {
  children: React.ReactNode
}

export default function AdminLayout({ children }: AdminLayoutProps) {

  const loc = useLocation();
  const nav = useNavigate();
  const { exp } = useContext(AuthContext);

  useEffect(() => {

    if (!loc.pathname.includes('/admin')) return;

    if (!Boolean(localStorage.getItem('token'))) {
      nav('/login');
    } else {
      if (exp) {
        if (exp < new Date()) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          nav('/login');
        }
      }
    }

  }, [loc.pathname])

  if (!loc.pathname.includes('/admin')) return null;

  if (!exp) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        pt: '126px'
      }}
    >
      <Appbar />
      <Box
        sx={{
          flex: 1,
          mb: 4,
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
          }}
        >
          <SideNav />
        </Box>
        {children}
      </Box>
      <Footer />
    </Box>
  )
}
