import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Grid } from '@mui/material';
import { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { AuthContext } from '../contexts/AuthContext';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { Announcement, AnnouncementType, Role } from '../utils/types';
import AnnouncementItem from '../components/AnnouncementItem';
export default function AdminAnnouncement() {

  const { token, user } = useContext(AuthContext);
  const nav = useNavigate();
  const { type } = useParams();

  const getType = () => {
    switch (type) {
      case 'duyurular':
        return AnnouncementType.DUYURU;
      case 'bultenler':
        return AnnouncementType.BULTEN;
      case 'anasayfa-duyuru':
        return AnnouncementType.BANNER;
      case 'fon-duyurular':
        return AnnouncementType.FON;
      case 'basin-duyurular':
        return AnnouncementType.BASIN;
      default:
        return 'duyuru';
    }
  }

  const { data: announcementsData, isLoading, error } = useSWR<Announcement[]>(`${baseUrl}/announcement`, (url: string) => fetcher(url, token));

  const announcements = announcementsData?.filter(announcement => announcement.type === getType());
  if (isLoading) return <div>Yükleniyor...</div>

  return (
    <Box
      sx={{
        width: '100%',
        p: 2,
      }}
    >
      {error && <div>Hata oluştu</div>}
      {announcements?.length === 0 && <div>İçerik bulunamadı</div>}
      {announcements && announcements?.length > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {announcements.map((announcement) => (
            <AnnouncementItem
              announcement={announcement}
              link={`/admin/app/${type}/${announcement.id}/duzenle`}
            />
          ))}
        </Box>
      )}
      {(user?.role === Role.ADMIN || user?.role === Role.SUPERADMIN) && (
        <Fab
          onClick={() => {
            nav(`/admin/app/${type}/yeni`)
          }}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
            color: 'white',
            backgroundColor: 'primary.main',
            '&:hover': {
              backgroundColor: 'primary.main',
            },
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </Box>
  )
}
