import { ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material';
import { Box, Button, Card, CardContent, IconButton, Paper, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import pdf2 from '../assets/pdfs/2.pdf';
import pdf3 from '../assets/pdfs/3.pdf';
import pdf4 from '../assets/pdfs/4.pdf';
import pdf5 from '../assets/pdfs/5.pdf';
import { baseUrl } from '../utils/constants';
import { AuthContext } from '../contexts/AuthContext';
import { deleteFile, uploadFile } from '../services/AppServices';
import { useAlert } from '../utils/useAlert';
import DeleteDialog from './DeleteDialog';

type Props = {
  handleSubmit: (values: any, type: string) => void;
  data: any;
}

export default function InformationFormEditor({ handleSubmit, data }: Props) {

  type Partner = {
    name: string;
    itemCount: number;
    itemPrice: number;
    itemRate: string;
  };

  const partners: Partner[] = [
    {
      name: "Bülent Kırımlı",
      itemCount: 20002000,
      itemPrice: 20002000,
      itemRate: "50,005%"
    },
    {
      name: "Arif Özer İsfendiyaroğlu",
      itemCount: 16000000,
      itemPrice: 16000000,
      itemRate: "40,000%"
    },
    {
      name: "İnci Aksun",
      itemCount: 2246000,
      itemPrice: 2246000,
      itemRate: "5,615%"
    },
    {
      name: "Berna Yiğit Sema Sevindi",
      itemCount: 1200000,
      itemPrice: 1200000,
      itemRate: "3,000%"
    },
    {
      name: "Bora Oruç",
      itemCount: 552000,
      itemPrice: 552000,
      itemRate: "1,380%"
    },
  ];

  type BoardMember = {
    name: string;
    title: string;
  };

  const boardMembers: BoardMember[] = [
    {
      name: "Arif Özer İsfendiyaroğlu",
      title: "Yönetim Kurulu Başkanı",
    },
    {
      name: "Bora Oruç",
      title: "Yönetim Kurulu Başkanı Vekili",
    },
    {
      name: "İnci Aksun",
      title: "Yönetim Kurulu Üyesi",
    },
    {
      name: "Berna Sema Yiğit Sevindi",
      title: "Yönetim Kurulu Üyesi",
    },
    {
      name: "Ali Fuat Erbil",
      title: "Yönetim Kurulu Üyesi",
    },
    {
      name: "İbrahim Emrah Gürelli",
      title: "Yönetim Kurulu Üyesi",
    },
    {
      name: "İlkay Civelek",
      title: "Yönetim Kurulu Üyesi / Genel Müdür / Portföy Yöneticisi",
    }
  ];

  const fundManagers: BoardMember[] = [
    {
      name: "İlkay Civelek",
      title: "Yönetim Kurulu Üyesi / Genel Müdür / Portföy Yöneticisi",
    },
    {
      name: "Zeliha Funda Kaya",
      title: "Portföy Yöneticisi",
    },
    {
      name: "Pınar Saydem",
      title: "Portföy Yöneticisi",
    },
    {
      name: "Semih Sezgin",
      title: "Portföy Yöneticisi",
    },
    {
      name: "Arif Utku Ünal",
      title: "Portföy Yöneticisi Yardımcısı",
    },
    {
      name: "Mehmet Karaköse",
      title: "Portföy Yöneticisi Yardımcısı",
    },
    {
      name: "Gökhan Alpen",
      title: "Yatırım Danışmanı ve Araştırma Uzmanı",
    },
  ];

  type Document = {
    title: string;
    file: any;
  }

  const documents: Document[] = [
    {
      title: "Ticaret Sicil Tasdiknamesi Belgesi",
      file: {
        "id": 13,
        "fieldname": "file",
        "name": "Foneria Portföy Birinci Fon Sepeti Fonu Yatırımcı Bilgi Formu",
        "originalname": "Birinci-Fon-Sepeti-YBF.pdf",
        "encoding": "7bit",
        "mimetype": "application/pdf",
        "destination": "./uploads",
        "filename": "151f2817072bebe76b423cd2d63209ac",
        "path": "uploads/151f2817072bebe76b423cd2d63209ac",
        "size": 587623,
        "createdAt": "2024-01-22T20:30:52.122Z",
        "updatedAt": "2024-01-22T20:30:52.122Z",
        "fonId": 5,
        "announcementId": null,
        "financialDataId": null,
        "umbrellaId": null
      }
    },
    {
      title: "Kayıtlı Sermaye Tavanı İçinde Sermaye Artırımına İlişkin YKK (18.09.2023)",
      file: {
        "id": 13,
        "fieldname": "file",
        "name": "Foneria Portföy Birinci Fon Sepeti Fonu Yatırımcı Bilgi Formu",
        "originalname": "Birinci-Fon-Sepeti-YBF.pdf",
        "encoding": "7bit",
        "mimetype": "application/pdf",
        "destination": "./uploads",
        "filename": "151f2817072bebe76b423cd2d63209ac",
        "path": "uploads/151f2817072bebe76b423cd2d63209ac",
        "size": 587623,
        "createdAt": "2024-01-22T20:30:52.122Z",
        "updatedAt": "2024-01-22T20:30:52.122Z",
        "fonId": 5,
        "announcementId": null,
        "financialDataId": null,
        "umbrellaId": null
      }
    },
    {
      title: "Esas Sözleşmenin 8.Maddesi (04.10.2023 İtibariyle)",
      file: {
        "id": 13,
        "fieldname": "file",
        "name": "Foneria Portföy Birinci Fon Sepeti Fonu Yatırımcı Bilgi Formu",
        "originalname": "Birinci-Fon-Sepeti-YBF.pdf",
        "encoding": "7bit",
        "mimetype": "application/pdf",
        "destination": "./uploads",
        "filename": "151f2817072bebe76b423cd2d63209ac",
        "path": "uploads/151f2817072bebe76b423cd2d63209ac",
        "size": 587623,
        "createdAt": "2024-01-22T20:30:52.122Z",
        "updatedAt": "2024-01-22T20:30:52.122Z",
        "fonId": 5,
        "announcementId": null,
        "financialDataId": null,
        "umbrellaId": null
      }
    },
    {
      title: "Sermaye Artırımına İlişkin Türk Ticaret Sicil Gazetesi Yazısı (04.10.2023)",
      file: {
        "id": 13,
        "fieldname": "file",
        "name": "Foneria Portföy Birinci Fon Sepeti Fonu Yatırımcı Bilgi Formu",
        "originalname": "Birinci-Fon-Sepeti-YBF.pdf",
        "encoding": "7bit",
        "mimetype": "application/pdf",
        "destination": "./uploads",
        "filename": "151f2817072bebe76b423cd2d63209ac",
        "path": "uploads/151f2817072bebe76b423cd2d63209ac",
        "size": 587623,
        "createdAt": "2024-01-22T20:30:52.122Z",
        "updatedAt": "2024-01-22T20:30:52.122Z",
        "fonId": 5,
        "announcementId": null,
        "financialDataId": null,
        "umbrellaId": null
      }
    },
    {
      title: "17.08.2023 Tarihli Genel Kurul Tutanağı",
      file: {
        "id": 13,
        "fieldname": "file",
        "name": "Foneria Portföy Birinci Fon Sepeti Fonu Yatırımcı Bilgi Formu",
        "originalname": "Birinci-Fon-Sepeti-YBF.pdf",
        "encoding": "7bit",
        "mimetype": "application/pdf",
        "destination": "./uploads",
        "filename": "151f2817072bebe76b423cd2d63209ac",
        "path": "uploads/151f2817072bebe76b423cd2d63209ac",
        "size": 587623,
        "createdAt": "2024-01-22T20:30:52.122Z",
        "updatedAt": "2024-01-22T20:30:52.122Z",
        "fonId": 5,
        "announcementId": null,
        "financialDataId": null,
        "umbrellaId": null
      }
    }
  ]

  type DataType = {
    formalInformation: {
      date: string;
      type: string;
    },
    partners: Partner[],
    boardMembers: BoardMember[],
    fundManagers: BoardMember[],
    documents: Document[]
  }

  const [datas, setDatas] = useState(data as DataType || {
    formalInformation: {
      date: '',
      type: ''
    },
    partners: [],
    boardMembers: [],
    fundManagers: [],
    documents: []
  });

  const [uploading, setUploading] = useState<boolean>(false);
  const { token } = useContext(AuthContext);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deletingFile, setDeletingFile] = useState<any>(null);
  const showSnackbar = useAlert();

  const handleDelete = async (file: any) => {
    try {
      setDeleting(true);
      const res = await deleteFile(file.id!, token);
      const newDocuments = datas.documents.filter((document: any) => document.file.id !== file.id);
      setDatas({ ...datas, documents: newDocuments });
      showSnackbar('Dosya silindi', 'success');
    } catch (error) {
      showSnackbar('Dosya silinirken bir hata oluştu', 'error');
      console.log(error);
    } finally {
      setDeleting(false);
      setDeleteDialogOpen(false);
    }
  }

  const handleFileUpload = async (e: any) => {
    setUploading(true);
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    const fileName = file.name;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', fileName);
    formData.append('type', 'returnLink');

    try {
      const res = await uploadFile(formData, token);
      const newDocuments = [...datas.documents];
      newDocuments.push({ title: fileName, file: res });
      setDatas({ ...datas, documents: newDocuments });
      showSnackbar('Dosya yüklendi', 'success');
    } catch (error) {
      console.log(error);
      showSnackbar('Dosya yüklenirken bir hata oluştu', 'error');
    } finally {
      setUploading(false);
      e.target.value = null;
    }
  }


  return (
    <Box>
      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          background: 'white'
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            Portföy Yöneticiliği ve Yatırım Danışmanlığı
          </Typography>

          <TextField
            id="date"
            label="Yetki Belgesi Tarihi"
            value={datas.formalInformation?.date}
            onChange={(e) => setDatas({ ...datas, formalInformation: { ...datas.formalInformation, date: e.target.value } })}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            id="type"
            label="Yetki Belgesi Türü"
            value={datas.formalInformation?.type}
            onChange={(e) => setDatas({ ...datas, formalInformation: { ...datas.formalInformation, type: e.target.value } })}
            fullWidth
            sx={{ mb: 2 }}
          />
        </CardContent>
      </Card>

      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          background: 'white',
          mt: 2
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            Ortaklar
          </Typography>

          {datas.partners?.map((partner: any, index: number) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 2,
                  background: 'white'
                }}
              >
                <Box
                  sx={{
                    top: 20,
                    right: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    gap: 1,
                  }}
                >
                  {index !== 0 && (
                    <IconButton
                      onClick={() => {
                        const newPartners = [...datas.partners];
                        const temp = newPartners[index];
                        newPartners[index] = newPartners[index - 1];
                        newPartners[index - 1] = temp;
                        setDatas({ ...datas, partners: newPartners });

                      }}
                    >
                      <ArrowUpward />
                    </IconButton>
                  )}
                  {index !== datas.partners.length - 1 && (
                    <IconButton
                      onClick={() => {
                        const newPartners = [...datas.partners];
                        const temp = newPartners[index];
                        newPartners[index] = newPartners[index + 1];
                        newPartners[index + 1] = temp;
                        setDatas({ ...datas, partners: newPartners });

                      }}
                    >
                      <ArrowDownward />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      const newPartners = [...datas.partners];
                      newPartners.splice(index, 1);
                      setDatas({ ...datas, partners: newPartners });
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
                <TextField
                  id="name"
                  label="Ortak Adı"
                  value={partner.name}
                  onChange={(e) => {
                    const newPartners = [...datas.partners];
                    newPartners[index].name = e.target.value;
                    setDatas({ ...datas, partners: newPartners });
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2

                  }}
                >
                  <TextField
                    id="itemCount"
                    label="Hisse Adedi"
                    value={partner.itemCount || ''}
                    onChange={(e) => {
                      const newPartners = [...datas.partners];
                      newPartners[index].itemCount = parseInt(e.target.value);
                      setDatas({ ...datas, partners: newPartners });
                    }}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    id="itemPrice"
                    label="Hisse Bedeli"
                    value={partner.itemPrice || ''}
                    onChange={(e) => {
                      const newPartners = [...datas.partners];
                      newPartners[index].itemPrice = parseInt(e.target.value);
                      setDatas({ ...datas, partners: newPartners });
                    }}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    id="itemRate"
                    label="Hisse Oranı"
                    value={partner.itemRate}
                    onChange={(e) => {
                      const newPartners = [...datas.partners];
                      newPartners[index].itemRate = e.target.value;
                      setDatas({ ...datas, partners: newPartners });
                    }}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                </Box>
              </Paper>
            </Box>
          ))}
          <Button
            variant="outlined"
            onClick={() => {
              setDatas({ ...datas, partners: [...datas.partners, { name: '', itemCount: 0, itemPrice: 0, itemRate: '' }] });
            }}
            sx={{
              mt: 2,
            }}
          >
            Ortak Ekle
          </Button>
        </CardContent>
      </Card>

      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          background: 'white',
          mt: 2
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            Yönetim Kurulu Üyeleri
          </Typography>

          {datas.boardMembers?.map((boardMember: any, index: number) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 2,
                  background: 'white'
                }}
              >
                <Box
                  sx={{
                    top: 20,
                    right: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    gap: 1,
                  }}
                >
                  {index !== 0 && (
                    <IconButton
                      onClick={() => {
                        const newBoardMembers = [...datas.boardMembers];
                        const temp = newBoardMembers[index];
                        newBoardMembers[index] = newBoardMembers[index - 1];
                        newBoardMembers[index - 1] = temp;
                        setDatas({ ...datas, boardMembers: newBoardMembers });

                      }}
                    >
                      <ArrowUpward />
                    </IconButton>
                  )}
                  {index !== datas.boardMembers.length - 1 && (
                    <IconButton
                      onClick={() => {
                        const newBoardMembers = [...datas.boardMembers];
                        const temp = newBoardMembers[index];
                        newBoardMembers[index] = newBoardMembers[index + 1];
                        newBoardMembers[index + 1] = temp;
                        setDatas({ ...datas, boardMembers: newBoardMembers });

                      }}
                    >
                      <ArrowDownward />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      const newBoardMembers = [...datas.boardMembers];
                      newBoardMembers.splice(index, 1);
                      setDatas({ ...datas, boardMembers: newBoardMembers });
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
                <TextField
                  id="name"
                  label="İsim"
                  value={boardMember.name}
                  onChange={(e) => {
                    const newBoardMembers = [...datas.boardMembers];
                    newBoardMembers[index].name = e.target.value;
                    setDatas({ ...datas, boardMembers: newBoardMembers });
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  id="title"
                  label="Ünvan"
                  value={boardMember.title}
                  onChange={(e) => {
                    const newBoardMembers = [...datas.boardMembers];
                    newBoardMembers[index].title = e.target.value;
                    setDatas({ ...datas, boardMembers: newBoardMembers });
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              </Paper>
            </Box>
          ))}
          <Button
            variant="outlined"
            onClick={() => {
              setDatas({ ...datas, boardMembers: [...datas.boardMembers, { name: '', title: '' }] });
            }}
            sx={{
              mt: 2,
            }}
          >
            Üye Ekle
          </Button>
        </CardContent>
      </Card>

      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          background: 'white',
          mt: 2
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            Fon Yöneticileri
          </Typography>

          {datas.fundManagers?.map((fundManager: any, index: number) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 2,
                  background: 'white'
                }}
              >
                <Box
                  sx={{
                    top: 20,
                    right: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    gap: 1,
                  }}
                >
                  {index !== 0 && (
                    <IconButton
                      onClick={() => {
                        const newFundManagers = [...datas.fundManagers];
                        const temp = newFundManagers[index];
                        newFundManagers[index] = newFundManagers[index - 1];
                        newFundManagers[index - 1] = temp;
                        setDatas({ ...datas, fundManagers: newFundManagers });

                      }}
                    >
                      <ArrowUpward />
                    </IconButton>
                  )}
                  {index !== datas.fundManagers.length - 1 && (
                    <IconButton
                      onClick={() => {
                        const newFundManagers = [...datas.fundManagers];
                        const temp = newFundManagers[index];
                        newFundManagers[index] = newFundManagers[index + 1];
                        newFundManagers[index + 1] = temp;
                        setDatas({ ...datas, fundManagers: newFundManagers });

                      }}
                    >
                      <ArrowDownward />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      const newFundManagers = [...datas.fundManagers];
                      newFundManagers.splice(index, 1);
                      setDatas({ ...datas, fundManagers: newFundManagers });
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
                <TextField
                  id="name"
                  label="İsim"
                  value={fundManager.name}
                  onChange={(e) => {
                    const newFundManagers = [...datas.fundManagers];
                    newFundManagers[index].name = e.target.value;
                    setDatas({ ...datas, fundManagers: newFundManagers });
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  id="title"
                  label="Ünvan"
                  value={fundManager.title}
                  onChange={(e) => {
                    const newFundManagers = [...datas.fundManagers];
                    newFundManagers[index].title = e.target.value;
                    setDatas({ ...datas, fundManagers: newFundManagers });
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              </Paper>
            </Box>
          ))}
          <Button
            variant="outlined"
            onClick={() => {
              setDatas({ ...datas, fundManagers: [...datas.fundManagers, { name: '', title: '' }] });
            }}
            sx={{
              mt: 2,
            }}
          >
            Yönetici Ekle
          </Button>
        </CardContent>
      </Card>

      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          background: 'white',
          mt: 2
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            Dokümanlar
          </Typography>

          {datas.documents?.map((document: any, index: number) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 2,
                  background: 'white'
                }}
              >
                <Box
                  sx={{
                    top: 20,
                    right: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    gap: 1,
                  }}
                >
                  {index !== 0 && (
                    <IconButton
                      onClick={() => {
                        const newDocuments = [...datas.documents];
                        const temp = newDocuments[index];
                        newDocuments[index] = newDocuments[index - 1];
                        newDocuments[index - 1] = temp;
                        setDatas({ ...datas, documents: newDocuments });

                      }}
                    >
                      <ArrowUpward />
                    </IconButton>
                  )}
                  {index !== datas.documents.length - 1 && (
                    <IconButton
                      onClick={() => {
                        const newDocuments = [...datas.documents];
                        const temp = newDocuments[index];
                        newDocuments[index] = newDocuments[index + 1];
                        newDocuments[index + 1] = temp;
                        setDatas({ ...datas, documents: newDocuments });

                      }}
                    >
                      <ArrowDownward />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      setDeleteDialogOpen(true);
                      setDeletingFile(document.file);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
                <TextField
                  id="title"
                  label="Başlık"
                  value={document.title}
                  onChange={(e) => {
                    const newDocuments = [...datas.documents];
                    newDocuments[index].title = e.target.value;
                    setDatas({ ...datas, documents: newDocuments });
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  href={`${baseUrl}/file/${document.file.id}`}
                  target="_blank"
                  sx={{ mb: 2 }}
                >
                  İncele
                </Button>
              </Paper>
            </Box>
          ))}
          <Button
            variant="outlined"
            component="label"
            sx={{
              mt: 2,
            }}
          >
            Doküman Ekle
            <input type="file" hidden onChange={handleFileUpload} />
          </Button>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: 'flex',
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={() => handleSubmit(datas, 'surekli-bilgilendirme-formu')}
        >
          Kaydet
        </Button>
      </Box>
      <DeleteDialog
        title='Dosya Sil'
        description='Dosyayı silmek istediğinize emin misiniz?'
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          if (deletingFile) {
            handleDelete(deletingFile);
          }
        }}
        deleting={deleting}
      />
    </Box>
  )
}