import { Box, Typography, useTheme } from '@mui/material';
import { Fon } from '../utils/types';

type Props = {
  fund: Fon;
};

export default function Funds({ fund }: Props) {
  const theme = useTheme();
  return (
    <Box>
      <Box
        sx={{
          background: 'white',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 2,
          alignItems: 'center',
          padding: '20px'
        }}
      >
        <Box
          sx={{
            background: 'rgba(19, 13, 111, 0.2)',
            height: '70px',
            width: '70px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={fund.logoUrl}
            alt='Company Logo'
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '22px',
                lineHeight: '35px',
                color: 'rgba(28,27,31,1)',
              }}
            >
              {fund.name}
            </Typography>
            <Box
              sx={{
                height: '26px',
                width: '67.76px',
                background: 'rgba(240,243,245,1)',
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '18px',
                  lineHeight: '35px',
                  textAlign: 'center',
                  color: 'rgba(19,23,34,0.9)',
                }}
              >
                {fund.code}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '35px',
              color: 'rgba(28,27,31,1)',
            }}
          >
            {fund.longName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '35px',
              textAlign: 'center',
              color: 'rgba(33,37,41,1)',
              mb: -1,
            }}
          >
            Fon Pay Fiyatı
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '35px',
              textAlign: 'center',
              color: theme.palette.primary.main,
            }}
          >
            {fund.pricePerItem}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
