import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Typography
} from '@mui/material';

export default function LegalInformation() {
  return (
    <Box
    sx={{
      borderRadius: '5px',
      background: 'rgba(255,255,255,1)',
      border: '1px solid rgba(219,222,233,1)',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 3,
        pt: 2,
        px: { xs: 3, md: 4, lg: 5, xl: 6 },
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '24px',
          color: 'rgba(28,27,31,1)',
        }}
      >
        Hukuki Bilgilendirme:
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '22px',
          color: 'rgba(28,27,31,1)',
          px: { xs: 3, md: 4, lg: 5, xl: 6 },
          mt: 2,
          pb: 3,
        }}
      >
        Geçmişteki performans gelecekteki sonuçları garanti etmez.
        Alıntılanan performans verileri geçmiş performansı temsil eder ve
        mevcut getiriler daha düşük veya daha yüksek olabilir. Yatırım
        yapmadan önce, her bir Fonun yatırım hedeflerini, risklerini,
        ücretlerini ve giderlerini dikkatlice değerlendirmelisiniz. Bu ve
        diğer bilgiler izahnamede yer almaktadır. Yatırım yapmadan önce
        lütfen içerikleri dikkatlice okuyunuz. Her fon sayfasında standart
        performans verilerini bulmak için lütfen yukarıya tıklayın.
        Olağandışı performans, kısmen alışılmadık derecede elverişli piyasa
        koşullarından kaynaklanabilir ve gelecekte tekrarlanmayabilir veya
        tutarlı bir şekilde elde edilemeyebilir. Fon'un en son ay sonu
        performansı, fon materyalleri bölümünde ve her fon sayfasında yer
        almaktadır.
      </Typography>
    </Box>
  </Box>
  )
}
