import { Box, Card, Typography, useTheme } from '@mui/material';
import cardPic from '../assets/card-pic.png';
import timeIcon from '../assets/time-icon.svg';
import trendIcon from '../assets/trend-icon.svg';
import { Fon, Umbrella } from '../utils/types';

type Props = {
  umbrella: Umbrella
}

export default function UmbrellaCard({ umbrella }: Props) {

  const theme = useTheme();

  return (
    <Card
      sx={{
        background: 'white',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: '100%',
        }}
      >
        {/* <Box
          sx={{
            position: 'absolute',
            top: '20px',
            left: '0px',
            background: theme.palette.primary.main,
            color: 'white',
            minWidth: '60px',
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Typography>
            {fund.code}
          </Typography>
        </Box> */}
        <img
          style={{
            width: '100%',
            objectFit: 'cover',
            borderRadius: '10px',
          }}
          src={cardPic}
          alt='card-pic'
        />
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '25px',
            }}
          >
            {umbrella.name}
          </Typography>
          <Box
            sx={{
              mt: 10
            }}
          >

            {/* {Boolean(fund.lastOneYearReturn) && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <img src={trendIcon} alt='time-icon' />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '18px',
                  }}
                >
                  Son 1 Yıl Fon Getirisi: %{(fund.lastOneYearReturn! * 100).toFixed(2)}
                </Typography>
              </Box>
            )} */}
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
