import { Box, Button, IconButton, Typography, useTheme } from '@mui/material'
import React from 'react'
import customerServices from '../assets/customer-services.svg'
import emailIcon from '../assets/mail-icon.svg'
import qrCode from '../assets/location-qrcode.svg'
import map from '../assets/map.svg'
import linkedInIcon from '../assets/linkedin-icon.svg'

export default function CompanyInfoCard() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: '6px',
        py: 9,
        px: 7
      }}
    >
      <Typography
        sx={{
          fontSize: '25px',
          fontWeight: 700,
          color: 'white'
        }}
      >
        Foneria Portföy Yönetimi A.Ş.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          gap: 2,
          mt: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mt: 3
          }}
        >
          <img src={customerServices} alt="customer-services" />
          <Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                color: 'white'
              }}
            >
              Müşteri Hizmetleri
            </Typography>
            <Typography
            noWrap
              sx={{
                fontSize: '20px',
                fontWeight: 500,
                color: 'white',
                opacity: 0.7
              }}
            >
              0 (212) 706 84 74
            </Typography>
          </Box>

        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mt: 3
            }}
          >
            <img src={emailIcon} alt="email-icon" />
            <Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: 'white'
                }}
              >
                E-Posta
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 500,
                  color: 'white',
                  opacity: 0.7
                }}
              >
                info@foneriaportfoy.com.tr
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          gap: 2,
          mt: 3
        }}
      >
        <img
          src={qrCode}
          alt="qr-code"
          style={{ width: 110 }}
        />
        <Box>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: 'white'
            }}
          >
            Adres
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              color: 'white',
              opacity: 0.7
            }}
          >
            Ömer Avni Mahallesi İnönü Caddesi Devres Han No:50 İç Kapı No:2 Kat: B2
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              color: 'white',
              opacity: 0.7
            }}
          >
            Beyoğlu/İSTANBUL
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              color: 'white',
              opacity: 0.7
            }}
          >
            Faks: 0212 706 4702
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
          mt: 3
        }}
      >
        <IconButton
          onClick={() => window.open('https://www.linkedin.com/company/foneria-portfoy', '_blank')}
        >
          <img src={linkedInIcon} alt="map" />
        </IconButton>
        <Button
          variant='outlined'
          onClick={() => window.open('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.4524479339975!2d28.99059497718821!3d41.03723381763963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab770ae5812bb%3A0x47efea2b11e40924!2sFoneria%20Portf%C3%B6y!5e0!3m2!1str!2str!4v1705616010726!5m2!1str!2str', '_blank')}
          sx={{
            color: 'white',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2
          }}
        >
          <img src={map} alt="map" />
          Yol Tarifi Al
        </Button>
      </Box>
    </Box>
  )
}
