import { Box, Paper, Typography, useTheme } from '@mui/material';
import bultenImg from '../assets/bulten-image.jpg';
import { Announcement } from '../utils/types';
import { baseUrl } from '../utils/constants';
import logo from '../assets/logo2.png';
type BultenItemProps = {
  bulten:Announcement
}

export default function BultenItem({ bulten }: BultenItemProps) {

  const theme = useTheme();

  const getDate = (date: string) => {
    // return like Ekim 2023
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('tr', { month: 'long' });
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  }

  return (
    <Paper
      onClick={() => window.open(`${`${baseUrl}/file/${bulten.files[0].id}`}`, '_blank')}
      sx={{
        position: 'relative',
        borderRadius: '12px',
        height: '100%',
        border: '1px solid rgba(230, 233, 237, 1)',
        background: 'white',
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
        },

      }}
    >
      
      <Box
        sx={{
          position: 'absolute',
          top: "14%",
          left: "20px",
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '29px',
          }}
        >
          {getDate(bulten.createdAt.toString())}
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '29px',
          }}
        >
          Aylık Bülten
        </Typography>
      </Box>
      <img
        src={bultenImg}
        alt=''
        style={{
          width: '100%',
          height: 'auto',
          borderRadius: '12px',
          objectFit: 'cover',
        }}
      />
      <Box p={2}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '22px',
            color: 'rgba(28,27,31,1)',
          }}
        >
          {bulten.message}
        </Typography>
      </Box>
    </Paper>
  )
}
