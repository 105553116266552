import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridToolbar,
  trTR
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { Role, User } from '../utils/types';
import { baseUrl } from '../utils/constants';
import { AuthContext } from '../contexts/AuthContext';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { deleteUser, updateUser } from '../services/UserServices';
import DeleteDialog from '../components/DeleteDialog';
import { fetcher } from '../utils/global';

export default function AdminUsersContainer() {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<GridRowId | null>(null);
  const [deleting, setDeleting] = useState(false);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'email', headerName: 'Email', editable: true, width: 150 },
    { field: 'firstName', headerName: 'Ad', editable: true, width: 130 },
    { field: 'lastName', headerName: 'Soyad', editable: true, width: 130 },
    {
      field: 'role',
      type: 'singleSelect',
      headerName: 'Rol',
      width: 130,
      editable: true,
      valueOptions: [Role.SUPERADMIN, Role.ADMIN, Role.USER],
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'İşlemler',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ]
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const { token } = React.useContext(AuthContext);

  const { data, error } = useSWR<User[]>(`${baseUrl}/users`, (url: string) => fetcher(url, token));

  useEffect(() => {
    if (data) {
      setRows(data.map((user) => {
        return {
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          role: user.role,
        }
      }));
    }
  }, [data])

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDelete = async (id: GridRowId) => {
    try {
      setDeleting(true);
      await deleteUser(+id, token);
      const newData = rows.filter((row) => row.id !== id);
      setRows(rows.filter((row) => row.id !== id));
      enqueueSnackbar('Kullanıcı silindi.', { variant: 'success' });
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Kullanıcı silinirken bir hata oluştu.', { variant: 'error' });
    } finally {
      setDeleteDialogOpen(false);
      setDeleting(false);
    }
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeleteDialogOpen(true);
    setSelectedRow(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
     
    try {
      const res = await updateUser(newRow.id, token, newRow);
      setRows(rows.map((row) => (row.id === newRow.id ? res : row)));
      enqueueSnackbar('Kullanıcı güncellendi.', { variant: 'success' });
      return res;
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Kullanıcı güncellenirken bir hata oluştu.', { variant: 'error' });
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <Box
      sx={{
        pt: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Typography
        variant='h4'
        sx={{
          mb: 2
        }}
      >
        Kullanıcılar
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        style={{
          maxWidth: matches ? 'calc(100vw - 64px)' : 'calc(100vw - 290px)',
          height: '100%',
          margin: '16px'
        }}
      />
      <DeleteDialog
        title='Kullanıcıyı sil'
        description='Bu kullanıcıyı silmek istediğinize emin misiniz?'
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => handleDelete(selectedRow!)}
        deleting={deleting}
      />
    </Box>
  );
}