import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import phone from '../assets/phone.png';
import mail from '../assets/mail 1.png';
import google from '../assets/googleplay.png';
import app from '../assets/appstore.png';
import { appStoreUrl, playStoreUrl } from '../utils/utils';
import FaxIcon from '@mui/icons-material/Fax';

export default function FooterContact() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        background: 'rgba(28, 27, 31, 1)',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-evenly',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          gap: { xs: '20px', md: '30px' },
          pb: { xs: '20px', md: 0 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '10px',
            alignItems: 'center',
            pr: { xs: 2 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              pr: { xs: 2 },
              cursor: 'pointer',
            }}
          >
            <img
              style={{
                height: 34,
                width: 34,
              }}
              src={phone}
              alt=''
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '20px',
                color: theme.palette.common.white,
              }}
            >
              0212 706 84 74
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              pr: { xs: 2 },
              cursor: 'pointer',
            }}
          >
            <FaxIcon sx={{ color: theme.palette.common.white, fontSize: 40 }} />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '20px',
                color: theme.palette.common.white,
              }}
            >
              0212 706 47 02
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row'},
            alignItems: 'center',
            gap: { xs: '20px', md: '30px' },
            pb: { xs: '20px', md: 0 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '15px',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                height: 34,
                width: 34,
              }}
              src={mail}
              alt='mail'
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: theme.palette.common.white,
                }}
              >
                Foneria Portföy Mail
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: theme.palette.common.white,
                }}
              >
                info@foneriaportfoy.com.tr
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '15px',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                height: 34,
                width: 34,
              }}
              src={mail}
              alt='mail'
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: theme.palette.common.white,
                }}
              >
                Foneria Portföy KEP Adresi
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: theme.palette.common.white,
                }}
              >
                foneriaportfoy@hs01.kep.tr
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 7,
          alignItems: 'center',
          pl: { xs: 3 }
        }}
      >
        <Button
          onClick={() => window.open(playStoreUrl, '_blank')}
        >
          <img src={google} alt='googleplay' />
          <Box>
            <Typography
              sx={{
                textTransform: 'none',
                color: theme.palette.common.white,
              }}
            >
              Google Play{' '}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.common.white,
              }}
            >
              'DEN İNDİRİN
            </Typography>
          </Box>
        </Button>
        <Button
          onClick={() => window.open(appStoreUrl, '_blank')}
        >
          <img src={app} alt='appstore' />
          <Box>
            <Typography
              sx={{
                textTransform: 'none',
                color: theme.palette.common.white,
              }}
            >
              App Store{" "}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.common.white,
              }}
            >
              'DAN İNDİRİN
            </Typography>
          </Box>
        </Button>
      </Box> */}
    </Box >
  );
}
