import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'

type SimpleConfirmDialogProps = {
  title: string;
  description: { __html: string };
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  confirmButtonText: string;
}

export default function SimpleConfirmDialog({ title, description, open, handleClose, handleConfirm, confirmButtonText }: SimpleConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div
          style={{
            padding: 24
          }}
          dangerouslySetInnerHTML={description}
        >

        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { handleClose() }}>Vazgeç</Button>
        <Button onClick={() => { handleConfirm() }}>{confirmButtonText}</Button>
      </DialogActions>

    </Dialog>
  )
}
