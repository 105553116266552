import React from 'react';
import grade1 from '../assets/rank-1.svg';
import grade2 from '../assets/rank-2.svg';
import grade3 from '../assets/rank-3.svg';
import grade4 from '../assets/rank-4.svg';
import grade5 from '../assets/rank-5.svg';
import grade6 from '../assets/rank-6.svg';
import grade7 from '../assets/rank-7.svg';

type GradeSvgProps = {
  grade: number; // 1 ile 7 arasında bir değer olmalı
};

const GradeSvg: React.FC<GradeSvgProps> = ({ grade }) => {

  return (
    <>
      {grade === 1 && <img src={grade1} alt="grade1" />}
      {grade === 2 && <img src={grade2} alt="grade2" />}
      {grade === 3 && <img src={grade3} alt="grade3" />}
      {grade === 4 && <img src={grade4} alt="grade4" />}
      {grade === 5 && <img src={grade5} alt="grade5" />}
      {grade === 6 && <img src={grade6} alt="grade6" />}
      {grade === 7 && <img src={grade7} alt="grade7" />}
    </>
  );
};

export default GradeSvg;
