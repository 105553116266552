import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArticleIcon from '@mui/icons-material/Article';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DomainIcon from '@mui/icons-material/Domain';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import Notification from '@mui/icons-material/Notifications';
import ShowChartIcon from '@mui/icons-material/PieChart';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import StarBorder from '@mui/icons-material/StarBorder';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, IconButton, Menu, MenuItem, SwipeableDrawer, Typography, useTheme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo2 from '../assets/logo2.png';
import { appStoreUrl, playStoreUrl } from '../utils/utils';
import { AuthContext } from '../contexts/AuthContext';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import { Home } from '@mui/icons-material';

type LeftDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const commercial = [
  "Hakkımızda",
  "Yönetim Kurulu",
  "Yöneticilerimiz",
]

const notifications = [
  "Duyurular",
  "Foneria Bülten",
  "Sık Sorulan Sorular",
  "Saklama Hesabı",
  "Sürekli Bilgilendirme Formu",
  "KVKK",
  "Acil Durum Eylem Planı",
  "Yasal Uyarı",
  "Finansal Bilgiler",
]

export default function LeftDrawer({ open, setOpen }: LeftDrawerProps) {

  const [expanded, setExpanded] = useState<string[]>([]);
  const navigate = useNavigate();
  const { portfolios } = useContext(AuthContext);
  const theme = useTheme();
  const investmentFunds = [...portfolios.map(portfolio => portfolio.name)];

  const nav = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openDownload = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpand = (item: string) => {
    if (expanded.includes(item)) {
      setExpanded(expanded.filter(i => i !== item))
    } else {
      setExpanded([item])
    }
  }

  const getIcon = (item: string) => {
    switch (item) {
      case "Hakkımızda":
        return <InfoIcon />
      case "Yöneticilerimiz":
        return <SupervisorAccountIcon />
      case "Yönetim Kurulu":
        return <DomainIcon />
      case "Finansal Bilgiler":
        return <BarChartIcon />
      case "Duyurular":
        return <Notification />
      case "Foneria Bülten":
        return <NewspaperIcon />
      case "Saklama Hesabı":
        return <AccountBalanceIcon />
      case "Sürekli Bilgilendirme Formu":
        return <ArticleIcon />
      case "KVKK":
        return <PrivacyTipIcon />
      case "Acil Durum Eylem Planı":
        return <LocalHospitalIcon />
      case "Sık Sorulan Sorular":
        return <PsychologyAltIcon />
      case "Yasal Uyarı":
        return <WarningIcon />
      default:
        return <StarBorder />
    }
  }

  const handleClicked = (item: string) => {

    const url = portfolios.find(portfolio => portfolio.name === item)?.slug

    switch (item) {
      case "Hakkımızda":
        nav('/hakkimizda');
        break;
      case "Yöneticilerimiz":
        nav('/yoneticilerimiz');
        break;
      case "Yönetim Kurulu":
        nav('/yonetim-kurulu');
        break;
      case "Finansal Bilgiler":
        nav('/finansal-bilgiler');
        break;
      case "Duyurular":
        nav('/duyurular');
        break;
      case "Foneria Bülten":
        nav('/foneria-bulten');
        break;
      case "Sık Sorulan Sorular":
        nav('/sss');
        break;
      case "Saklama Hesabı":
        nav('/saklama-hesabi');
        break;
      case "Sürekli Bilgilendirme Formu":
        nav('/surekli-bilgilendirme-formu');
        break;
      case "KVKK":
        nav('/kvkk');
        break;
      case "Acil Durum Eylem Planı":
        nav('/acil-durum-eylem-plani');
        break;
      case "Yasal Uyarı":
        nav('/yasal-uyari');
        break;
      case "Özel Portföy Yönetimi":
        nav('/ozel-portfoy-yonetimi');
        break;
      default:
        nav(`/yatirim-fonlari/${url}`);
    }
    setOpen(false);
    setExpanded([]);
  }

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={() => { setOpen(false) }}
      onOpen={() => { setOpen(true) }}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: '300px',
          pb: 4
        }
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          pt: 2
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <IconButton
            onClick={() => { setOpen(false) }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Box
          onClick={() => { nav('/'); setOpen(false) }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            my: 2,
            cursor: 'pointer',
          }}
        >
          <img
            src={logo2}
            alt="logo"
          />
        </Box>
        <Button
          onClick={() => { nav('/iletisim'); setOpen(false) }}
          sx={{
            display: { sm: 'none', md: 'flex' },
            borderRadius: '6px',
            background: theme.palette.primary.main,
            '&:hover': {
              background: theme.palette.primary.main,
            },
            mx: 'auto',
          }}
          variant='contained'
        >
          <Typography>İletişim</Typography>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openDownload}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: anchorEl?.clientWidth
            }
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => {
            handleClose();
            window.open(appStoreUrl, '_blank')
          }}>IOS</MenuItem>
          <MenuItem onClick={() => {
            handleClose();
            window.open(playStoreUrl, '_blank')
          }}>Android</MenuItem>
        </Menu>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              '& .MuiListItemIcon-root': {
                minWidth: '40px'
              },
            }}
            component="nav"
            disablePadding
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Foneria Menü
              </ListSubheader>
            }
          >
            <ListItemButton
              onClick={() => { nav('/'); setOpen(false) }}
            >
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Anasayfa" />
            </ListItemButton>

            <ListItemButton
              onClick={() => { handleExpand('Kurumsal') }}
            >
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Kurumsal" />
              {expanded.includes('Kurumsal') ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expanded.includes('Kurumsal')} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {commercial.map((item, index) => (
                  <ListItemButton
                    onClick={() => { handleClicked(item) }}
                    key={index}
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>
                      {getIcon(item)}
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>

            <ListItemButton
              onClick={() => { handleExpand('Yatırım Fonları') }}
            >
              <ListItemIcon>
                <TrendingUpIcon />
              </ListItemIcon>
              <ListItemText primary="Yatırım Fonları" />
              {expanded.includes('Yatırım Fonları') ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expanded.includes('Yatırım Fonları')} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {investmentFunds.map((fund, index) => (
                  <ListItemButton
                    onClick={() => { handleClicked(fund) }}
                    key={index}
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>
                      <ShowChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={fund} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>

            <ListItemButton
              onClick={() => { handleClicked("Özel Portföy Yönetimi") }}

            >
              <ListItemIcon>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <ListItemText primary="Özel Portföy Yönetimi" />
            </ListItemButton>

            <ListItemButton
              onClick={() => { handleExpand('Duyurular') }}
            >
              <ListItemIcon>
                <AnnouncementIcon />
              </ListItemIcon>
              <ListItemText primary="Duyurular" />
              {expanded.includes('Duyurular') ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expanded.includes('Duyurular')} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {notifications.map((item, index) => (
                  <ListItemButton
                    onClick={() => { handleClicked(item) }}
                    key={index}
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>
                      {getIcon(item)}
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </List>
        </Box>
      </Box>
    </SwipeableDrawer>
  )
}
