import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

export const registerUser = async (values: any) => {
  const url = `${baseUrl}/auth/register`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
      }
    )
  });

  const res = await handleResponse(response);

  return res;

}

export const loginUser = async (values: any) => {
  const url = `${baseUrl}/auth/login`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        email: values.email,
        password: values.password,
      }
    )
  });

  const res = await handleResponse(response);

  return res;
}

export const refreshTokenUser = async (token: string) => {
  const url = `${baseUrl}/auth/refreshtoken`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        token
      }
    )
  });

  const res = await handleResponse(response);

  return res;
}

export const changePassword = async (password: string, newPassword: string, newPassword2: string, token: string) => {
  const url = `${baseUrl}/auth/change-password`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(
      {
        oldPassword: password,
        newPassword: newPassword,
        confirmPassword: newPassword2
      }
    )
  });

  const res = await handleResponse(response);
  return res;
}

export const forgotPassword = async (email: string) => {
  const url = `${baseUrl}/auth/forgot-password`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        email
      }
    )
  });

  const res = await handleResponse(response);
  return res;
}

export const resetPassword = async (newPassword: string, confirmPassword: string, token: string) => {
  const url = `${baseUrl}/auth/reset-password`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(
      {
        newPassword,
        confirmPassword,
      }
    )
  });

  const res = await handleResponse(response);
  return res;
}
