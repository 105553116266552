import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Box, Button, Card, CardContent, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import PageView from '../components/PageView';
import Title from '../components/Title';
import pdf2 from '../assets/pdfs/2.pdf';
import pdf3 from '../assets/pdfs/3.pdf';
import pdf4 from '../assets/pdfs/4.pdf';
import pdf5 from '../assets/pdfs/5.pdf';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';

export default function InformationForm() {

  const theme = useTheme();
  type Partner = {
    name: string;
    itemCount: number;
    itemPrice: number;
    itemRate: string;
  };

  type BoardMember = {
    name: string;
    title: string;
  };

  type Document = {
    title: string;
    file:any;
  }

  type DataType = {
    formalInformation: {
      date: string;
      type: string;
    },
    partners: Partner[],
    boardMembers: BoardMember[],
    fundManagers: BoardMember[],
    documents: Document[]
  }

  const { data, isLoading, error } = useSWR<any>(`${baseUrl}/contents/surekli-bilgilendirme-formu`, (url: string) => fetcher(url));

  const [datas, setDatas] = useState<DataType>({
    formalInformation: {
      date: '',
      type: ''
    },
    partners: [],
    boardMembers: [],
    fundManagers: [],
    documents: []
  });


  useEffect(() => {
    if (data) {
      const parsedData: DataType = JSON.parse(data.content);
      setDatas(parsedData);
    }
  }, [data])


  if (isLoading) return <></>;


  const { formalInformation, partners, boardMembers, fundManagers, documents } = datas;


  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
      }}
    >
      <Title title="Sürekli Bilgilendirme Formu" />
      <PageView>
        <Box
          sx={{
            py: 3
          }}
        >
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              background: 'white'
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                Portföy Yöneticiliği ve Yatırım Danışmanlığı
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Yetki Belgesi Tarihi"
                    secondary="15/2/2022"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Yetki Belgesi Türü"
                    secondary="PYŞ / PY.59-YD23 / 190"
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
          <TableContainer component={Paper} sx={{ mt: 4, mb: 4, background: 'white' }}>
            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', p: 2 }}>
              Ortaklık Yapısı
            </Typography>
            <Table>
              <TableHead
                sx={{
                  //bold cell
                  '& th': {
                    fontWeight: 'bold',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'rgba(28,27,31,1)',
                    background: 'rgba(230, 233, 237, 1)',
                    border: '1px solid rgba(230, 233, 237, 1)',
                    py: 2,
                    px: 4
                  },
                }}
              >
                <TableRow>
                  <TableCell>Adı Soyadı veya Ticaret Unvanı</TableCell>
                  <TableCell align="center">Pay Adedi</TableCell>
                  <TableCell align="center">Pay Tutarı(TL)</TableCell>
                  <TableCell align="center">Pay Oranı (%)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  '& td': {
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'rgba(28,27,31,1)',
                    border: '1px solid rgba(230, 233, 237, 1)',
                    py: 2,
                  },
                  '& th': {
                    fontWeight: 'bold',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'rgba(28,27,31,1)',
                    py: 2,
                    px: 4
                  },
                }}
              >
                {partners.map((partner: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {partner.name}
                    </TableCell>
                    <TableCell align="center">{partner.itemCount.toLocaleString('tr-TR')}</TableCell>
                    <TableCell align="center">{partner.itemPrice.toLocaleString('tr-TR')}</TableCell>
                    <TableCell align="center">{partner.itemRate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer component={Paper} sx={{ mt: 4, mb: 4, background: 'white' }}>
            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', p: 2 }}>
              Yönetim Kurulu Üyeleri, Genel Müdür ve Genel Müdür Yard.
            </Typography>
            <Table>
              <TableHead
                sx={{
                  //bold cell
                  '& th': {
                    fontWeight: 'bold',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'rgba(28,27,31,1)',
                    background: 'rgba(230, 233, 237, 1)',
                    border: '1px solid rgba(230, 233, 237, 1)',
                    py: 2,
                    px: 4
                  },
                }}
              >
                <TableRow>
                  <TableCell>Adı Soyadı</TableCell>
                  <TableCell align="center">Unvanı</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  '& td': {
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'rgba(28,27,31,1)',
                    border: '1px solid rgba(230, 233, 237, 1)',
                    py: 2,
                  },
                  '& th': {
                    fontWeight: 'bold',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'rgba(28,27,31,1)',
                    py: 2,
                    px: 4
                  },
                }}
              >
                {boardMembers.map((member: BoardMember, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {member.name}
                    </TableCell>
                    <TableCell align="center">{member.title}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer component={Paper} sx={{ mt: 4, mb: 4, background: 'white' }}>
            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', p: 2 }}>
              Portföy Yöneticileri ve Yatırım Danışmanları
            </Typography>
            <Table>
              <TableHead
                sx={{
                  //bold cell
                  '& th': {
                    fontWeight: 'bold',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'rgba(28,27,31,1)',
                    background: 'rgba(230, 233, 237, 1)',
                    border: '1px solid rgba(230, 233, 237, 1)',
                    py: 2,
                    px: 4
                  },
                }}
              >
                <TableRow>
                  <TableCell>Adı Soyadı</TableCell>
                  <TableCell align="center">Unvanı</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  '& td': {
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'rgba(28,27,31,1)',
                    border: '1px solid rgba(230, 233, 237, 1)',
                    py: 2,
                  },
                  '& th': {
                    fontWeight: 'bold',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'rgba(28,27,31,1)',
                    py: 2,
                    px: 4
                  },
                }}
              >
                {fundManagers.map((member: BoardMember, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {member.name}
                    </TableCell>
                    <TableCell align="center">{member.title}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper sx={{ mt: 4, mb: 4, padding: 2, background: 'white' }}>
            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', p: 2 }}>
              İlgili Belgeler
            </Typography>
            <List>
              {documents.map((document, index) => (
                <ListItem key={index} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <ListItemText primary={document.title} />
                  <Button
                    variant="outlined"
                    color="primary"
                    component="a"
                    href={`${baseUrl}/file/${document.file.id}`}
                    target="_blank"
                    startIcon={<FileOpenIcon />}
                    sx={{ textTransform: 'none' }}
                  >
                    Aç
                  </Button>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Box>
      </PageView>
    </Box>
  )
}
