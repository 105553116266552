import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import resmiYazi from '../assets/pdfs/resmi-yazi.pdf';

export default function StopageComponent() {

  type Stopage = {
    type: string;
    changeStatus: string;
    stopageRate: string;
  }

  const stopages: Stopage[] = [
    {
      type: "Yerli Hisse Senedi Yoğun Fonlar",
      changeStatus: "Yok",
      stopageRate: "%0",
    },
    {
      type: "En az 1 yıl süreyle tutulan, içeriği en az % 51 yerli hisse senedi içeren serbest ve değişken fonlar",
      changeStatus: "Yok",
      stopageRate: "%0",
    },
    {
      type: "Para piyasası, İç Borçlanma araçları (Tahvil/Bono/Kira Sertifikaları); Kıymetli maden, katılım ve fon sepeti fonları",
      changeStatus: "Artış",
      stopageRate: "01.05.2024 - 31.07.2024 arasında <strong>%7.5</strong>",
    },
    {
      type: "Değişken, Karma, Dış Borçlanma araçları (Eurobond), yıırtdışı hisse senedi, serbest fonlar ile unvanlarında döviz ve yabancı ifadesi geçen yatırım fonları",
      changeStatus: "Yok",
      stopageRate: "%10",
    },
    {
      type: "Girişim Sertmayesi Yatırım Fonları",
      changeStatus: "Yok",
      stopageRate: "<strong>2 yıl boyunca</strong> portföyde tutulması şartıyla <strong>%0</strong>",
    },
    {
      type: "Gayrimenkul Yatırım Fonları",
      changeStatus: "Yok",
      stopageRate: "<strong>2 yıl boyunca</strong> portföyde tutulması şartıyla <strong>%0</strong>",
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <TableContainer component={Paper} sx={{ mt: 4, mb: 4, mx: 'auto', background: 'white' }}>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', p: 2 }}>
          30.04.2024 Tarihli Yatırım Fonları Stopaj Oranı Değişikliği
        </Typography>
        <Table>
          <TableHead
            sx={{
              //bold cell
              '& th': {
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '24px',
                color: 'rgba(28,27,31,1)',
                background: 'rgba(230, 233, 237, 1)',
                border: '1px solid rgba(230, 233, 237, 1)',
                py: 2,
                px: 4
              },
            }}
          >
            <TableRow>
              <TableCell>Fon Türü</TableCell>
              <TableCell align="center">Değişiklik Durumu</TableCell>
              <TableCell align="center">Stopaj Oranı</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& td': {
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                color: 'rgba(28,27,31,1)',
                border: '1px solid rgba(230, 233, 237, 1)',
                py: 2,
              },
              '& th': {
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '24px',
                color: 'rgba(28,27,31,1)',
                py: 2,
                px: 4
              },
            }}
          >
            {stopages.map((stopage: Stopage, index: number) => (
              <TableRow key={index}>
                <TableCell sx={{ maxWidth: 400 }} component="th" scope="row">
                  {stopage.type}
                </TableCell>
                <TableCell align="center">
                  {stopage.changeStatus}
                </TableCell>
                <TableCell align="center">
                  <span dangerouslySetInnerHTML={{ __html: stopage.stopageRate }} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Button
          variant='outlined'
          sx={{
            textTransform: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth:300
          }}
          href={resmiYazi}
          target="_blank"
        >
          Stopaj Oranları Hakkında Resmi Yazı
          <ArrowForwardIosIcon sx={{ fontSize: 18 }} />

        </Button>
      </Box>
    </Box>
  )
}
