import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Grid } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import UmbrellaCardItem from '../components/UmbrellaCardItem';
import { AuthContext } from '../contexts/AuthContext';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { Umbrella, Role } from '../utils/types';

export default function AdminUmbrellaList() {

  const { token, user } = useContext(AuthContext);

  const { data: umbrellas, isLoading, error } = useSWR<Umbrella[]>(`${baseUrl}/umbrella`, (url: string) => fetcher(url, token));
  const nav = useNavigate();

  return (
    <Box
      sx={{
        width: '100%',
        m: 2,
      }}
    >
      {isLoading && <div>Yükleniyor...</div>}
      {error && <div>Hata oluştu</div>}
      {umbrellas?.length === 0 && <div>Şemsiye Fon bulunamadı</div>}
      {(umbrellas && umbrellas?.length > 0) && (
        <Grid container spacing={2}>
          {umbrellas.map((umbrella) => (
            <Grid item xs={12} sm={6} md={4} key={umbrella.id}>
              <UmbrellaCardItem umbrella={umbrella} />
            </Grid>
          ))}
        </Grid>
      )}
      {(user?.role === Role.ADMIN || user?.role === Role.SUPERADMIN) && (
        <Fab
          onClick={() => { nav('/admin/semsiye-fonlari/yeni') }}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
            color: 'white',
            backgroundColor: 'primary.main',
            '&:hover': {
              backgroundColor: 'primary.main',
            },
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </Box>
  )
}
