import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import Title from '../components/Title'
import PageView from '../components/PageView'
import GeneralInfoCard from '../components/GeneralInfoCard'
import useSWR from 'swr'
import { baseUrl } from '../utils/constants'
import { fetcher } from '../utils/global'
import MarkdownViewer from '../components/MarkdownViewer'

export default function LegalWarning() {

  const theme = useTheme();

  const { data, isLoading, error } = useSWR<any>(`${baseUrl}/contents/yasal-uyari`, (url: string) => fetcher(url));

  if (isLoading) return <></>;

  const warningData = data ? JSON.parse(data.content) : [];


  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
      }}
    >
      <Title title="Yasal Uyarı" />
      <PageView>
        <Box
          sx={{
            p: 4
          }}
        >
          <GeneralInfoCard
            title=""
            description={
             <MarkdownViewer text={warningData} />
            }
          />
        </Box>
      </PageView>
    </Box>
  )
}
