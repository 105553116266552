import { Box } from '@mui/material'
import React from 'react'
import Title from '../components/Title'
import PageView from '../components/PageView'
import useSWR from 'swr'
import { baseUrl } from '../utils/constants'
import { fetcher } from '../utils/global'
import MarkdownViewer from '../components/MarkdownViewer'
export const kvkkHtml = {
	__html: `
	<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7e3b5346 golgekutu" data-id="7e3b5346" data-element_type="column">
		<div class="elementor-widget-wrap elementor-element-populated">
							<section class="elementor-section elementor-inner-section elementor-element elementor-element-7b52fb89 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7b52fb89" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
					<div class="elementor-container elementor-column-gap-default">
				<div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-8b06f66" data-id="8b06f66" data-element_type="column">
		<div class="elementor-widget-wrap elementor-element-populated">
							<div class="elementor-element elementor-element-5d0411a5 elementor-widget elementor-widget-heading" data-id="5d0411a5" data-element_type="widget" data-widget_type="heading.default">
			<div class="elementor-widget-container">
		<style>/*! elementor - v3.17.0 - 08-11-2023 */
.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}</style><h2 class="elementor-heading-title elementor-size-default">FONERİA PORTFÖY YÖNETİMİ A.Ş KVKK</h2>		</div>
			</div>
				</div>
	</div>
						</div>
	</section>
			<section class="elementor-section elementor-inner-section elementor-element elementor-element-7f2f24cc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7f2f24cc" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
					<div class="elementor-container elementor-column-gap-default">
				<div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-168b6fc4" data-id="168b6fc4" data-element_type="column">
		<div class="elementor-widget-wrap elementor-element-populated">
							<div class="elementor-element elementor-element-00a414e elementor-widget elementor-widget-text-editor" data-id="00a414e" data-element_type="widget" data-widget_type="text-editor.default">
			<div class="elementor-widget-container">
		<style>/*! elementor - v3.17.0 - 08-11-2023 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}</style>				<div class="m-3">
<p class="page-text">Kişisel verilerin işlenmesinde başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerinin korunmasını amaçlayan 7 Nisan 2016 tarihli ve 29677 Sayılı Resmî Gazete’de yayımlanan 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”)’nun “Veri Sorumlusunun Aydınlatma Yükümlülüğü” başlıklı 10. maddesi ile 10 Mart 2018 tarih ve 30356 sayılı Resmi Gazete’de yayımlanan Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ uyarınca işbu Aydınlatma Metni ile veri sorumlusu sıfatıyla Foneria Portföy Yönetimi A.Ş (“Foneria Portföy” veya “Şirket”) işlenen kişisel verileriniz hakkında sizleri bilgilendirmeyi hedeflemekteyiz.</p>
</div>
<div class="m-3">
<h6 class="page-head">1. İŞLENEN KİŞİSEL VERİLER</h6>
<p class="page-text">Foneria Portföy ile ilişkiniz kapsamında aşağıdaki kişisel verileriniz işlenmektedir;</p>
<ul>
<li class="page-text">a) Kimlik Bilgileriniz: Adı soyadı, TCKN, vergi kimlik numarası, pasaport no, uyruk, doğum tarihi/ yeri, kimlik veya ehliyet örneği</li>
<li class="page-text">b) İletişim Bilgileriniz: Adres bilgileri, e-posta, cep telefonu, irtibat numarası</li>
<li class="page-text">c) Demografik Bilgileri: Fotoğraf, meslek, eğitim seviyesi gelir seviyesi ve benzeri profil bilgileri</li>
<li class="page-text">d) Ödeme ve Finansal Bilgiler: Yan hak ve menfaat bilgileri, ücret bilgileri, fatura/ borç bilgisi, banka/ hesap/ kart bilgileri</li>
<li class="page-text">e) Müşteri İşlem Bilgileri: Müşteri şikayet, talep ve teşekkürleri</li>
<li class="page-text">f) İş, Profesyonel Yaşam ve Eğitim Bilgileri: Unvan</li>
</ul>
</div>
<div class="m-3">
<h6 class="page-head">2. KİŞİSEL VERİLERİN İŞLENME AMACI</h6>
<p class="page-text">Foneria Portföy ile ilişkiniz kapsamında elde edilen kişisel verileriniz aşağıda yer verilen amaçlarla işlenmektedir;</p>
<ul>
<li class="page-text">a) Sözleşme süreçlerinin yürütülmesi,</li>
<li class="page-text">b) Müşteri hesap açılış sürecinin yürütülmesi ile sistem tanımlanmasının kontrolünün sağlanması,</li>
<li class="page-text">c) Fonlara ilişkin performans bildirimlerinin yapılması,</li>
<li class="page-text">d) Özel portföy yönetim işlemlerinin portföy yönetim sözleşmesi ve mevzuata uygunluk kapsamında kontrol etkinliği,</li>
<li class="page-text">e) İç kontrol raporlama sürecinin yürütülmesi,</li>
<li class="page-text">f) Müşteri şikayet ve bildirimlerinin takibinin yapılması,</li>
<li class="page-text">g) MASAK Kontrolü sürecinin yürütülmesi</li>
<li class="page-text">h) Periyodik müşteri raporlarının kontrol sürecinin denetiminin sağlanması,</li>
<li class="page-text">i) İletişim faaliyetlerinin yürütülmesi,</li>
<li class="page-text">j) Tedarikçiler ile sözleşme sürecinin yürütülmesi,</li>
<li class="page-text">k) Faturalama sürecinin yürütülmesi,</li>
<li class="page-text">l) Hukuk işlerinin takibi ve yürütülmesi.</li>
</ul>
</div>
<div class="m-3">
<h6 class="page-head">3. İŞLENEN KİŞİSEL VERİLERİN AKTARIMI</h6>
<p class="page-text">Kişisel verileriniz; Kanun’un kişisel verilerin aktarılması ve yurt dışına aktarılmasına ilişkin hükümleri kapsamında işbu Aydınlatma Metni’nin 2. maddesinde yer alan amaçlarla; resmi kurum ve kuruluşlara, iş ortaklarımıza, hizmet sağlayıcı firmalar ve yetkililerine, bankalara, tedarikçilerimize ve destek hizmeti sağlayıcılarına aktarılabilmektedir.</p>
</div>
<div class="m-3">
<h6 class="page-head">4. KİŞİSEL VERİLERİN TOPLANMA YÖNTEMLERİ VE HUKUKİ SEBEPLERİ</h6>
<p class="page-text">Kişisel verileriniz, Şirketimizle hukuki ilişkinizin kurulması esnasında ve söz konusu ilişkinin devamı süresince sizlerden, üçüncü kişilerden ve yasal mercilerden olmak kaydıyla internet sitesi, muhtelif sözleşmeler, mobil uygulamalar, elektronik posta, başvuru formları gibi araçlar üzerinden, Şirketimiz ile yapılan yazılı veya sözlü iletişim kanalları aracılığıyla sözlü, yazılı veya elektronik ortamda toplanmaktadır. Bu doğrultuda toplanan kişisel verileriniz KVKK’nın 5, 6 ve 8. maddelerinde belirtilen ve aşağıda yer verilen hukuka uygunluk sebeplerine dayanılarak işlenmektedir.</p>
<ul>
<li class="page-text">a) Açık rızanızın bulunması</li>
<li class="page-text">b) Türk Borçlar Kanunu, Türk Ticaret Kanunu, Sermaye Piyasası Kanunu olmak üzere Şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş olması</li>
<li class="page-text">c) Müşterilerin tanınmasına ilişkin ulusal ve uluslararası alandaki ilke ve esaslara uyum sağlamak, mevzuat ve resmi otoritelerce öngörülen bilgi saklama, raporlama, bilgilendirme yükümlülüklerine uymak</li>
<li class="page-text">d) Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması, talep edilen ürün ve hizmetleri sunabilmek ve akdettiğiniz sözleşmelerinin gereğinin yerine getirilmesi</li>
<li class="page-text">e) Hukuki yükümlülüğün yerine getirebilmesi için zorunlu olması</li>
<li class="page-text">f) İlgili kişinin kendisi tarafından alenileştirilmiş olması</li>
<li class="page-text">g) Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması</li>
<li class="page-text">h) İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.</li>
</ul>
</div>
<div class="m-3">
<p class="page-text">Özel nitelikli kişisel verileriniz ise aşağıdaki hukuka uygunluk sebeplerine dayanılarak toplanmakta, saklanmakta ve işlenmektedir:</p>
<ul>
<li class="page-text">a) Açık rızanızın bulunması,</li>
<li class="page-text">b) Sağlık dışındaki kişisel veriler, kanunlarda öngörülen hallerde açık rızası aranmaksızın,</li>
<li class="page-text">c) Sağlığa ilişkin kişisel veriler ise ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbî teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından ilgilinin açık rızası aranmaksızın.</li>
</ul>
</div>
<div class="m-3">
<h6 class="page-head">5. HAK VE TALEPLERİNİZ İÇİN BİZİMLE İLETİŞİME GEÇMEK İSTERSENİZ</h6>
<p class="page-text">Kişisel Verilerin Korunması Kanunu’nun 11. maddesi hükümleri uyarınca ilgili kişinin haklarını düzenleyen taleplerinizi “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ” uyarınca dilekçe ile Ömer Avni Mahallesi İnönü Caddesi Devres Han No:50 İç Kapı No:2 Kat: B2 Gümüşsuyu, Beyoğlu/İSTANBUL adresimize bizzat elden iletebilir, noter kanalıyla ulaştırabilirsiniz. Bunun yanında, İlgili Tebliğ’in 5. maddesi uyarınca Şirketimize daha önce bildirdiğiniz ve sistemlerimizde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle kvkk@foneriaportfoy.com.tr adresine iletebilirsiniz.</p>
</div>						</div>
			</div>
				</div>
	</div>
						</div>
	</section>
				</div>
	</div>
	`
}

export default function Kvkk() {

  const { data, isLoading, error } = useSWR<any>(`${baseUrl}/contents/kvkk`, (url: string) => fetcher(url));

  if (isLoading) return <></>;

  const kvkkData = data ? JSON.parse(data.content) : [];

 
  return (
    <Box>
      <Title title="KVKK" />
      <PageView>
       <MarkdownViewer text={kvkkData} />
      </PageView>
    </Box>
  )
}
