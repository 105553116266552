import { LoadingButton } from '@mui/lab'
import { Box, Checkbox, FormControlLabel, Link, TextField } from '@mui/material'
import React, { useState } from 'react'

type LoginFormProps = {
  handleSubmit: (email: string, password: string) => void;
  loading: boolean;
}

export default function LoginForm({ handleSubmit, loading }: LoginFormProps) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Box
      sx={{
        mb: 4,
        width: '100%',
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(email, password);
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            m: 2,
          }}
        >
          <TextField
            required
            sx={{ width: '100%' }}
            id="outlined-basic"
            label="Email"
            type={'email'}
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            required
            id="outlined-basic"
            sx={{ width: '100%', mt: 2 }}
            label="Şifre"
            variant="outlined"
            type={'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              mt: 2,
            }}>
            <FormControlLabel control={<Checkbox defaultChecked />} label="Beni Hatırla" />
          </Box>
          <LoadingButton
            sx={{ width: '100%', height: 50, mt: 2 }}
            type='submit'
            variant="contained"
            loading={loading}
          >
            Giriş Yap
          </LoadingButton>
        </Box>
      </form>
    </Box>
  )
}
