import { Box } from '@mui/material'
import { useEffect } from 'react'
import Bulletin from '../components/Bulletin'
import Foneria from '../components/Foneria'
import FoneriaApp from '../components/FoneriaApp'
import Portfoy from '../components/Portfoy'
import FundsIncome from '../components/FundsIncome'

export default function Home() {

  useEffect(() => {
    if (window.location.hash === '#foneria-app') {
      const element = document.getElementById('foneria-app')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        pb: 27
      }}
    >
      <Foneria />
      <Box>
        <Portfoy />
        <FundsIncome />
      </Box>
      {/* <Bulletin /> */}
      {/* <Box
        id="foneria-app"
      >
        <FoneriaApp />
      </Box> */}
    </Box>
  )
}
