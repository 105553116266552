import { Home, Logout } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'; // Portföyler
import AccountTreeIcon from '@mui/icons-material/AccountTree'; // Şemsiye Fonları
import BarChartIcon from '@mui/icons-material/BarChart';
import CampaignIcon from '@mui/icons-material/Campaign'; // Duyurular
import GavelIcon from '@mui/icons-material/Gavel'; // Yasal Uyarı
import GroupIcon from '@mui/icons-material/Group';
import InfoIcon from '@mui/icons-material/Info';
import InsightsIcon from '@mui/icons-material/Insights'; // Panel
import NewspaperIcon from '@mui/icons-material/Newspaper'; // Bültenler
import PolicyIcon from '@mui/icons-material/Policy'; // KVKK
import QuizIcon from '@mui/icons-material/Quiz'; // SSS
import SavingsIcon from '@mui/icons-material/Savings'; // Fonlar
import { List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper } from '@mui/material';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { Role } from '../utils/types';
import SideNavItem from './SideNavItem';

type SideNavProps = {
  handleCloseDrawer?: () => void
}

export default function SideNav({ handleCloseDrawer }: SideNavProps = {}) {

  const loc = useLocation();
  const { user, logout } = useContext(AuthContext);
  const nav = useNavigate();

  return (
    <Paper
      sx={{
        width: 240,
        flexShrink: 0,
        height: 'calc(100vh - 164px)',
        borderRadius: 0,
        overflow: 'auto',
      }}
    >
      <List>
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin"
            label="Panel"
            icon={<InsightsIcon />}
            selected={loc.pathname === '/admin'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/kullanicilar"
            label="Kullanıcılar"
            icon={<GroupIcon />}
            selected={loc.pathname === '/admin/kullanicilar'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/portfoy"
            label="Portföyler"
            icon={<AccountBalanceIcon />}
            selected={loc.pathname === '/admin/portfoy'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/semsiye-fonlari"
            label="Şemsiye Fonları"
            icon={<AccountTreeIcon />}
            selected={loc.pathname === '/admin/semsiye-fonlari'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/fonlar"
            label="Fonlar"
            icon={<SavingsIcon />}
            selected={loc.pathname === '/admin/fonlar'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/app/duyurular"
            label="Genel Duyurular"
            icon={<CampaignIcon />}
            selected={loc.pathname === '/admin/app/duyurular'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/app/fon-duyurular"
            label="Fon Duyuruları"
            icon={<CampaignIcon />}
            selected={loc.pathname === '/admin/app/fon-duyurular'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/app/basin-duyurular"
            label="Basın Duyuruları"
            icon={<CampaignIcon />}
            selected={loc.pathname === '/admin/app/basin-duyurular'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/app/bultenler"
            label="Bültenler"
            icon={<NewspaperIcon />}
            selected={loc.pathname === '/admin/app/bultenler'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/app/anasayfa-duyuru"
            label="Anasayfa Duyuru"
            icon={<InfoIcon />}
            selected={loc.pathname === '/admin/app/anasayfa-duyuru'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/data/hakkimizda"
            label="Hakkımızda"
            icon={<InfoIcon />}
            selected={loc.pathname === '/admin/data/hakkimizda'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/data/yonetim-kurulu"
            label="Yönetim Kurulu"
            icon={<InfoIcon />}
            selected={loc.pathname === '/admin/data/yonetim-kurulu'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/data/yoneticilerimiz"
            label="Yöneticilerimiz"
            icon={<InfoIcon />}
            selected={loc.pathname === '/admin/data/yoneticilerimiz'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/data/ozel-portfoy-yonetimi"
            label="Özel Portföy Yönetimi"
            icon={<InfoIcon />}
            selected={loc.pathname === '/admin/data/ozel-portfoy-yonetimi'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/data/sss"
            label="Sıkça Sorulan Sorular"
            icon={<QuizIcon />}
            selected={loc.pathname === '/admin/data/sss'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/data/surekli-bilgilendirme-formu"
            label="Sürekli Bilgilendirme Formu"
            icon={<InfoIcon />}
            selected={loc.pathname === '/admin/data/surekli-bilgilendirme-formu'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/data/kvkk"
            label="KVKK"
            icon={<PolicyIcon />}
            selected={loc.pathname === '/admin/data/kvkk'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/data/acil-durum-eylem-plani"
            label="Acil Durum Eylem Planı"
            icon={<InfoIcon />}
            selected={loc.pathname === '/admin/data/acil-durum-eylem-plani'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/data/yasal-uyari"
            label="Yasal Uyarı"
            icon={<GavelIcon />}
            selected={loc.pathname === '/admin/data/yasal-uyari'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        {user?.role === Role.SUPERADMIN && (
          <SideNavItem
            to="/admin/data/finansal-bilgiler"
            label="Finansal Bilgiler"
            icon={<BarChartIcon />}
            selected={loc.pathname === '/admin/data/finansal-bilgiler'}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        <SideNavItem
          to="/"
          label="Site Anasayfa"
          icon={<Home />}
          selected={loc.pathname === '/'}
          handleCloseDrawer={handleCloseDrawer}
        />
        <ListItem disablePadding >
          <ListItemButton
            onClick={() => {
              logout!();
              nav('/');
            }}
          >
            <ListItemAvatar>
              <Logout />
            </ListItemAvatar>
            <ListItemText primary={"Çıkış Yap"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Paper>
  )
}
