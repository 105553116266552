import { Box, Grid, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import PageView from '../components/PageView'
import BultenItem from '../components/BultenItem'
import Title from '../components/Title'
import { AuthContext } from '../contexts/AuthContext'
import useSWR from 'swr'
import { Announcement, AnnouncementType } from '../utils/types'
import { baseUrl } from '../utils/constants'
import { fetcher } from '../utils/global'

export default function BultenContainer() {

  const theme = useTheme();

  const { token } = useContext(AuthContext);

  const { data: announcementsData, isLoading, error } = useSWR<Announcement[]>(`${baseUrl}/announcement`, (url: string) => fetcher(url, token));

  const bultens = announcementsData?.filter(announcement => announcement.type === AnnouncementType.BULTEN);

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
      }}
    >
      <Title title="Foneria Portföy Bültenler" />
      <PageView>
        <Box
          sx={{
            py: 5,
          }}
        >
          <Grid container spacing={2}>
            {bultens?.map((item) => (
              <Grid key={item.title} item xs={12} sm={6} md={4} lg={3}>
                <BultenItem
                 bulten={item}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </PageView>
    </Box>
  )
}
