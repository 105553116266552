import { Box, useTheme } from '@mui/material';
import PrivatePortfolioInformation from '../components/PrivatePortfolioInformation';
import Title from '../components/Title';


export default function PrivatePortfolio() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        pb: 27
      }}>
      <Title title='Özel Portföy Yönetimi' />
      <PrivatePortfolioInformation />
      {/* <PrivatePortfolioQuestion /> */}
    </Box>
  )
}
