import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Grid } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import PortfolioCardItem from '../components/PortfolioCardItem';
import { AuthContext } from '../contexts/AuthContext';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { Portfolio, Role } from '../utils/types';

export default function AdminPortfoyList() {

  const { token, user } = useContext(AuthContext);

  const { data: portfolios, isLoading, error } = useSWR<Portfolio[]>(`${baseUrl}/portfolio`, (url: string) => fetcher(url, token));
  const nav = useNavigate();

  return (
    <Box
      sx={{
        width: '100%',
        m: 2,
      }}
    >
      {isLoading && <div>Yükleniyor...</div>}
      {error && <div>Hata oluştu</div>}
      {portfolios?.length === 0 && <div>Portföy bulunamadı</div>}
      {(portfolios && portfolios?.length > 0) && (
        <Grid container spacing={2}>
          {portfolios.map((portfolio) => (
            <Grid item xs={12} sm={6} md={4} key={portfolio.id}>
              <PortfolioCardItem portfolio={portfolio} />
            </Grid>
          ))}
        </Grid>
      )}
      {(user?.role === Role.ADMIN || user?.role === Role.SUPERADMIN) && (
        <Fab
          onClick={() => { nav('/admin/portfoy/yeni') }}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
            color: 'white',
            backgroundColor: 'primary.main',
            '&:hover': {
              backgroundColor: 'primary.main',
            },
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </Box>
  )
}
