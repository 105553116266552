import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo2 from '../assets/logo2.png';
import LeftDrawer from './LeftDrawer';
import { AuthContext } from '../contexts/AuthContext';
import { useTheme } from '@mui/material';

const pages = [
  { name: 'Fonlar' },
  { name: 'Özel Portföy Yönetimi', path: '/ozel-portfoy-yonetimi' },
  { name: 'Foneria Bülten', path: '/foneria-bulten' },
];

export const investmentFundsInit = [
];

export default function Appbar() {
  const [scrollY, setScrollY] = React.useState(0);
  const navigate = useNavigate();
  const theme = useTheme();

  const { portfolios } = React.useContext(AuthContext);

  const investmentFunds = React.useMemo(() => {
    return [
      ...investmentFundsInit,
      ...portfolios.map(portfolio => portfolio.name)
    ]
  }, [portfolios])

  const nav = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  }

  const [leftBarOpen, setLeftBarOpen] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [anchorElNotification, setAnchorElNotification] = React.useState<null | HTMLElement>(null);

  const [anchorElCommercial, setAnchorElCommercial] = React.useState<null | HTMLElement>(null);

  const [anchorElInvestment, setAnchorElInvestment] =
    React.useState<null | HTMLElement>(null);

  const handleOpenCommercialMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCommercial(event.currentTarget);
  };

  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null);
  };

  const handleInvestmentMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElInvestment(event.currentTarget);
  };

  const handleOpenNotificationMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleInvestmentMenuClose = () => {
    setAnchorElInvestment(null);
  };

  const handleSelectInvestmentFund = (page: string) => {
    const url = portfolios.find(portfolio => portfolio.name === page)?.slug
    nav(`/yatirim-fonlari/${url}`);
    setAnchorElInvestment(null);
  }

  const handleCloseCommercialMenu = () => {
    setAnchorElCommercial(null);
  }

  const handleSelectCommercial = (page: string) => {
    if (page === "hakkimizda") {
      nav(`/hakkimizda`)
    } else if (page === "yoneticilerimiz") {
      nav(`/yoneticilerimiz`)
    } else if (page === "yonetim-kurulu") {
      nav(`/yonetim-kurulu`)
    }

    setAnchorElCommercial(null);
  }

  const handleSelectNotification = (page: string) => {
    if (page === "duyurular") {
      nav(`/duyurular`)
    } else if (page === "foneria-bulten") {
      nav(`/foneria-bulten`)
    } else if (page === "saklama-hesabi") {
      window.open("https://acikdeniz.denizbank.com/", "_blank")
    } else if (page === "surekli-bilgilendirme-formu") {
      nav(`/surekli-bilgilendirme-formu`)
    } else if (page === "kvkk") {
      nav(`/kvkk`)
    } else if (page === "acil-durum-eylem-plani") {
      nav(`/acil-durum-eylem-plani`)
    } else if (page === "yasal-uyari") {
      nav(`/yasal-uyari`)
    } else if (page === "sss") {
      nav(`/sss`)
    } else if (page === "finansal-bilgiler") {
      nav(`/finansal-bilgiler`)
    }

    setAnchorElNotification(null);
  }

  const handleClickDownload = () => {
    if (window.location.pathname === "/") {
      const element = document.getElementById('foneria-app')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      nav('/'); // Ana sayfaya yönlendir
      window.location.hash = '#foneria-app'; // URL'de hash'i güncelle
    }
  }

  return (
    <AppBar
      elevation={0}
      sx={{
        position: 'fixed',
        background: 'white',
        height: `${126 - Math.min(scrollY, 50)}px`,
        transition: 'height 0.3s ease-in-out',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      }}
    >
      <Container
        sx={{
          maxWidth: { xs: '100%', sm: '100%', md: '1440px', lg: '1440px' },
          margin: 'auto',
          height: '100%',
        }}
      >
        <Toolbar
          sx={{
            height: '100%',
          }}
          disableGutters
        >
          <LeftDrawer
            open={leftBarOpen}
            setOpen={setLeftBarOpen}
          />
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              onClick={() => setLeftBarOpen(true)}
              size='large'
              aria-label='menu'
              color='secondary'
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => nav('/')}
            >
              <img src={logo2} alt='logo' />
            </Box>
          </Box>
          {/* Desktop */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              justifyContent: 'space-between',
              flexGrow: 1,
              gap: 1,
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => nav('/')}
            >
              <img src={logo2} alt='logo' />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                height: '100%',
              }}
            >
              <Button
                onClick={handleOpenCommercialMenu}
                sx={{
                  color: 'white',
                  height: '100%',
                  '&:hover': {
                    background: 'white',
                  },
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'none',
                    fontWeight: 600,
                    fontSize: '17px',
                    lineHeight: '22.5px',
                    color: 'rgba(45, 43, 51, 1)',
                    '&:hover': {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {"Kurumsal"}
                  <KeyboardArrowDownOutlinedIcon />
                </Typography>
              </Button>
              <Menu
                sx={{
                  pt: 3
                }}
                id='commercial-menu'
                anchorEl={anchorElCommercial}
                open={Boolean(anchorElCommercial)}
                onClose={handleCloseCommercialMenu}
              >
                <MenuItem key={"hakkimizda"} onClick={() => handleSelectCommercial("hakkimizda")}>
                  {"Hakkımızda"}
                </MenuItem>
                <MenuItem key={"yonetim-kurulu"} onClick={() => handleSelectCommercial("yonetim-kurulu")}>
                  {"Yönetim Kurulu"}
                </MenuItem>
                <MenuItem key={"yoneticilerimiz"} onClick={() => handleSelectCommercial("yoneticilerimiz")}>
                  {"Yöneticilerimiz"}
                </MenuItem>
              </Menu>
              {pages.map((page) => (
                page.name !== 'Fonlar' ?
                  <Button
                    key={page.name}
                    component={Link}
                    to={page.path!}
                    sx={{
                      color: 'white',
                      height: '100%',
                      '&:hover': {
                        background: 'white',
                      },
                    }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'none',
                        fontWeight: 600,
                        fontSize: '17px',
                        lineHeight: '22.5px',
                        color: 'rgba(45, 43, 51, 1)',
                        '&:hover': {
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      {page.name}
                    </Typography>
                  </Button>
                  :
                  <Button
                    key={page.name}
                    onClick={handleInvestmentMenuOpen}
                    sx={{
                      color: 'white',
                      height: '100%',
                      '&:hover': {
                        background: 'white',
                      },
                    }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'none',
                        fontWeight: 600,
                        fontSize: '17px',
                        lineHeight: '22.5px',
                        color: 'rgba(45, 43, 51, 1)',
                        '&:hover': {
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      {page.name}
                      <KeyboardArrowDownOutlinedIcon />
                    </Typography>
                  </Button>
              ))}
              <Menu
                sx={{
                  pt: 3
                }}
                id='investment-menu'
                anchorEl={anchorElInvestment}
                open={Boolean(anchorElInvestment)}
                onClose={handleInvestmentMenuClose}
              >
                {investmentFunds.map((fund) => (
                  <MenuItem key={fund} onClick={() => handleSelectInvestmentFund(fund)}>
                    {fund}
                  </MenuItem>
                ))}
              </Menu>
              <Button
                onClick={handleOpenNotificationMenu}
                sx={{
                  color: 'white',
                  height: '100%',
                  '&:hover': {
                    background: 'white',
                  },
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'none',
                    fontWeight: 600,
                    fontSize: '17px',
                    lineHeight: '22.5px',
                    color: 'rgba(45, 43, 51, 1)',
                    '&:hover': {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {"Duyurular"}
                  <KeyboardArrowDownOutlinedIcon />
                </Typography>
              </Button>
              <Menu
                sx={{
                  pt: 3
                }}
                id='notification-menu'
                anchorEl={anchorElNotification}
                open={Boolean(anchorElNotification)}
                onClose={handleCloseNotificationMenu}
              >
                <MenuItem key={"duyuru"} onClick={() => handleSelectNotification("duyurular")}>
                  {"Duyurular"}
                </MenuItem>
                <MenuItem key={"foneria-bulten"} onClick={() => handleSelectNotification("foneria-bulten")}>
                  {"Foneria Bülten"}
                </MenuItem>
                <MenuItem key={"sss"} onClick={() => handleSelectNotification("sss")}>
                  {"Sık Sorulan Sorular"}
                </MenuItem>
                <MenuItem key={"saklama-hesabi"} onClick={() => handleSelectNotification("saklama-hesabi")}>
                  {"Saklama Hesabı"}
                </MenuItem>
                <MenuItem key={"surekli-bilgilendirme-formu"} onClick={() => handleSelectNotification("surekli-bilgilendirme-formu")}>
                  {"Sürekli Bilgilendirme Formu"}
                </MenuItem>
                <MenuItem key={"kvkk"} onClick={() => handleSelectNotification("kvkk")}>
                  {"KVKK"}
                </MenuItem>
                <MenuItem key={"acil-durum-eylem-plani"} onClick={() => handleSelectNotification("acil-durum-eylem-plani")}>
                  {"Acil Durum Eylem Planı"}
                </MenuItem>
                <MenuItem key={"yasal-uyari"} onClick={() => handleSelectNotification("yasal-uyari")}>
                  {"Yasal Uyarı"}
                </MenuItem>
                <MenuItem key={"finansal-bilgiler"} onClick={() => handleSelectNotification("finansal-bilgiler")}>
                  {"Finansal Bilgiler"}
                </MenuItem>
              </Menu>
            </Box>
            <Button
              onClick={() => { nav('/iletisim') }}
              sx={{
                display: { sm: 'none', md: 'flex' },
                borderRadius: '6px',
                textTransform: 'none',
                background: theme.palette.primary.main,
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}
              variant='contained'
            >
              İletişim
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
