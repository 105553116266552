import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PageView from './PageView';
import { Fon } from '../utils/types';
import GeneralInfoCard from './GeneralInfoCard';

// const data = [
//   {
//     title: 'Fon Süresi:',
//     title1: "Fon süresi son 2 yıl tasfiye dönemi olmak üzere, 02.08.2023'den itibaren 7 yıldır."
//   },
//   {
//     title: 'Satış:',
//     title1: "Nitelikli yatırımcılar"
//   }, {
//     title: 'Asgari İşlem Limitleri:',
//     title1: "Asgari bir işlem limiti bulunmamaktadır."
//   }, {
//     title: 'Fon Başlangıç Tarihi:',
//     title1: "2.08.2023"
//   }, {
//     title: 'Fondan Çıkış Dönemleri',
//     title1: "Fon süresinin sonuna kadar çıkış yoktur."
//   },
// ]
// const data1 = [
//   {
//     title: 'Fona Giriş ve Fondan Çıkış Ücretleri:',
//     title1: "Giriş veya çıkış ücreti yoktur.",
//     title2: ''
//   },
//   {
//     title: 'Fon Katılma Payı Alımı:',
//     title1: 'İhraç belgesinde belirtilen "Alım Talimatı İhbar Dönemi" tarihlerinde verilen alım talimatları takip eden ayın ilk 7 iş günü içinde gerçekleştirilir.',
//     title2: ''

//   },
//   {
//     title: 'Yönetim Ücreti:',
//     title1: "Yıllık",
//     title2: ' %3 + BMV'

//   },
// ]

type Props = {
  fund: Fon
}

export default function FundStrategy({ fund }: Props) {
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const data1 = [
    {
      title: 'Fon Süresi:',
      content: fund.fundDuration
    },
    {
      title: 'Satış:',
      content: fund.sellingTarget
    }, {
      title: 'Asgari İşlem Limitleri:',
      content: fund.minimumInvestment
    }, {
      title: 'Fon Başlangıç Tarihi:',
      content: fund.fundStartDate
    }, {
      title: 'Fondan Çıkış Dönemleri',
      content: fund.exitTime
    },
    {
      title: "Fon Alış Valörü:",
      content: fund.fundBuyValor
    },
    {
      title: "Fon Satış Valörü:",
      content: fund.fundSellValor
    },
  ];

  const data2 = [
    {
      title: 'Fona Giriş ve Fondan Çıkış Ücretleri:',
      content: fund.fundEntryExitFees
    },
    {
      title: 'Fon Katılma Payı Alımı:',
      content: fund.purchasingFundParticipationShares
    },
    {
      title: 'Yönetim Ücreti:',
      content: fund.managementFee
    },
    {
      title: "Stopaj Oranı:",
      content: fund.stoppage
    },
    {
      title: "İşlem Başlangıç Saati:",
      content: fund.eventStartTime
    },
    {
      title: "Valör Atama Saati:",
      content: fund.valorTime
    },
    {
      title: "Son İşlem Saati:",
      content: fund.lastEventTime
    },
  ]

  const anyData1 = data1.some(item => item.content);
  const anyData2 = data2.some(item => item.content);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}
    >
      {fund.strategy && (
        <GeneralInfoCard
          sx={{
            border: '1px solid rgba(230, 233, 237, 1)',
            borderRadius: '4px',
          }}
          title="Fon Stratejisi"
          description={fund.strategy}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          gap: 4,
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}>
        {anyData1 &&
          <Box
            sx={{
              flex: '1 1 45%',
              border: '1px solid rgba(230, 233, 237, 1)',
              borderRadius: '4px',
              padding: '20px',
              background: 'white',
              minWidth: 0,
            }}
          >
            {data1.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: item.content ? 'flex' : 'none',
                  paddingBottom: '10px',
                  marginBottom: index < data1.length - 1 ? '10px' : '0',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'rgba(33, 37, 41, 1)',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.title}
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: 'rgba(28, 27, 31, 1)',
                      marginLeft: '10px',
                    }}
                  >
                    {item.content}
                  </Typography>
                </Typography>
              </Box>
            ))}
          </Box>
        }
        {anyData2 &&
          <Box
            sx={{
              flex: '1 1 45%',
              minWidth: 0,
              border: '1px solid rgba(230, 233, 237, 1)',
              borderRadius: '4px',
              padding: '20px',
              background: 'white'
            }}
          >
            {data2.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: item.content ? 'flex' : 'none',
                  marginBottom: index < data2.length - 1 ? '10px' : '0',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: 'rgba(33, 37, 41, 1)',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.title}
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: 'rgba(28, 27, 31, 1)',
                      marginLeft: '10px',
                    }}
                  >
                    {item.content}
                  </Typography>
                </Typography>
              </Box>
            ))}
          </Box>
        }
      </Box>
    </Box>
  );
}
