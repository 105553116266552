import { Box, SxProps, Typography } from '@mui/material'
import user from '../assets/people.png';

type GeneralInfoCardProps = {
  title: string
  description: React.ReactNode
  sx?: SxProps
}

export default function GeneralInfoCard({ title, description, sx }: GeneralInfoCardProps) {
  return (
    <Box
      sx={{
        borderRadius: '6px',
        background: 'rgba(255,255,255,1)',
        pb: 2,
        ...sx
      }}
    >
      <Box
        sx={{
          borderRadius: '6px',
          background: 'rgba(255,255,255,1)',
          pb: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            px: { xs: 2, md: 3, lg: 4, xl: 5 },
            justifyContent: 'flex-start',
            alignItems: 'center',
            py: { xs: 2, md: 3 },
            gap: 3,
          }}
        >
          {/* <Box
            sx={{
              background: 'rgba(255, 0, 143, 0.1)',
              width: 52,
              height: 52,
              borderRadius: '6px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={user} alt='' style={{}} />
          </Box> */}
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '35px',
              color: 'rgba(28,27,31,1)',
              mt: 1,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            px: { xs: 2, md: 3, lg: 4, xl: 5 },
          }}
        >
          {description}
        </Box>
      </Box>
    </Box>
  )
}
