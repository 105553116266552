import { ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import { type } from 'os'
import React from 'react'
import { Link } from 'react-router-dom'

type SideNavItemProps = {
  to: string
  label: string
  icon: React.ReactNode
  selected: boolean
  handleCloseDrawer?: () => void
}

export default function SideNavItem({ to, label, icon, selected, handleCloseDrawer }: SideNavItemProps) {
  return (
    <Link
      style={{
        textDecoration: 'none',
        color: 'inherit'
      }}
      to={to}
      onClick={handleCloseDrawer}
    >
      <ListItem disablePadding >
        <ListItemButton selected={selected}>
          <ListItemAvatar>
            {icon}
          </ListItemAvatar>
          <ListItemText primary={label} />
        </ListItemButton>
      </ListItem>
    </Link>
  )
}
