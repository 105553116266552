import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { Portfolio } from '../utils/types';
import { useNavigate } from 'react-router-dom';

type PortfolioCardItemProps = {
  portfolio: Portfolio;
}

export default function PortfolioCardItem({ portfolio }: PortfolioCardItemProps) {

  const nav = useNavigate();

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: 2,
        }
      }}
      onClick={() => { nav(`/admin/portfoy/${portfolio.id}/duzenle`) }}
    >
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {portfolio.name}
        </Typography>
        {portfolio.description && (
          <Typography variant="body2" color="text.secondary">
            {portfolio.description}
          </Typography>
        )}
        <Typography variant="body2" color="text.secondary">
          Fon Sayısı: {portfolio?.fons?.length || 0}
        </Typography>
      </CardContent>
    </Card>
  )
}
