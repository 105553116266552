import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/tr';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminLayout from './components/AdminLayout';
import Layout from './components/Layout';
import AuthProvider from './contexts/AuthContext';
import { lightTheme } from './theme';
import About from './views/About';
import AddEditAnnouncement from './views/AddEditAnnouncement';
import AdminAnnouncement from './views/AdminAnnouncement';
import AdminFundList from './views/AdminFundList';
import AdminPortfoyList from './views/AdminPortfoyList';
import AdminUsers from './views/AdminUsers';
import Bulten from './views/Bulten';
import Contact from './views/Contact';
import EmergencyStutationPlan from './views/EmergencyStutationPlan';
import FaqPage from './views/FaqPage';
import FinancialInfo from './views/FinancialInfo';
import FundAddEdit from './views/FundAddEdit';
import Home from './views/Home';
import InformationForm from './views/InformationForm';
import InvestmentFund from './views/InvestmentFund';
import InvestmentFunds from './views/InvestmentFunds';
import Kvkk from './views/Kvkk';
import LegalWarning from './views/LegalWarning';
import Login from './views/Login';
import ManagerCommunity from './views/ManagerCommunity';
import Managers from './views/Managers';
import Notifications from './views/Notifications';
import PortfolioAddEdit from './views/PortfolioAddEdit';
import PrivatePortfolio from './views/PrivatePortfolio';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import Dashboard from './views/Dashboard';
import AdminUmbrellaList from './views/AdminUmbrellaList';
import UmbrellaAddEdit from './views/UmbrellaAddEdit';
import UmbrellaFunds from './views/UmbrellaFunds';
import StopagePage from './views/StopagePage';
import AdminData from './views/AdminData';

const GA_TRACKING_ID = 'GTM-MWG87QN5';

function initialGA() {
  ReactGA.initialize(GA_TRACKING_ID);
  ReactGA.pageview(window.location.toString());
}

function App() {

  useEffect(() => {
    initialGA();
  }, []);

  return (
    <SnackbarProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='tr'>
        <AuthProvider>
          <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <BrowserRouter>
              <Layout>
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path='/ozel-portfoy-yonetimi' element={<PrivatePortfolio />} />
                  <Route path='/hakkimizda' element={<About />} />
                  <Route path='/yatirim-fonlari/:type' element={<InvestmentFunds />} />
                  <Route path='/yatirim-fonlari/:type/:code' element={<InvestmentFund />} />
                  <Route path='/yatirim-fonlari/:type/semsiye-fonlari/:id/:code' element={<InvestmentFund />} />
                  <Route path='/yatirim-fonlari/:type/semsiye-fonlari/:id' element={<UmbrellaFunds />} />
                  <Route path='/duyurular' element={<Notifications />} />
                  <Route path='/iletisim' element={<Contact />} />
                  <Route path='/kvkk' element={<Kvkk />} />
                  <Route path='/stopaj-orani-degisikligi' element={<StopagePage />} />
                  <Route path='/yasal-uyari' element={<LegalWarning />} />
                  <Route path='/acil-durum-eylem-plani' element={<EmergencyStutationPlan />} />
                  <Route path='/surekli-bilgilendirme-formu' element={<InformationForm />} />
                  <Route path='/foneria-bulten' element={<Bulten />} />
                  <Route path='/yoneticilerimiz' element={<Managers />} />
                  <Route path='/yonetim-kurulu' element={<ManagerCommunity />} />
                  <Route path='/finansal-bilgiler' element={<FinancialInfo />} />
                  <Route path='/sss' element={<FaqPage />} />
                </Routes>
              </Layout>
              {/* <Guard> */}
              <AdminLayout>
                <Routes>
                  <Route path='/admin' element={<Dashboard />} />
                  <Route path='/admin/kullanicilar' element={<AdminUsers />} />
                  <Route path='/admin/portfoy' element={<AdminPortfoyList />} />
                  <Route path='/admin/portfoy/yeni' element={<PortfolioAddEdit />} />
                  <Route path='/admin/portfoy/:id/duzenle' element={<PortfolioAddEdit />} />
                  <Route path='/admin/semsiye-fonlari' element={<AdminUmbrellaList />} />
                  <Route path='/admin/semsiye-fonlari/yeni' element={<UmbrellaAddEdit />} />
                  <Route path='/admin/semsiye-fonlari/:id/duzenle' element={<UmbrellaAddEdit />} />
                  <Route path='/admin/fonlar' element={<AdminFundList />} />
                  <Route path='/admin/data/:type' element={<AdminData />} />
                  <Route path='/admin/fonlar/yeni' element={<FundAddEdit />} />
                  <Route path='/admin/fonlar/:id/duzenle' element={<FundAddEdit />} />
                  <Route path='/admin/app/:type' element={<AdminAnnouncement />} />
                  <Route path='/admin/app/:type/yeni' element={<AddEditAnnouncement />} />
                  <Route path='/admin/app/:type/:id/duzenle' element={<AddEditAnnouncement />} />
                </Routes>
              </AdminLayout>
              {/* </Guard> */}
            </BrowserRouter>
          </ThemeProvider>
        </AuthProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default App;
