import { Box, Button, Grid, Menu, MenuItem, useTheme } from '@mui/material';
import NotificationItem from '../components/NotificationItem';
import PageView from '../components/PageView';
import Title from '../components/Title';
import useSWR from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Announcement, AnnouncementType } from '../utils/types';
import { ExpandMore } from '@mui/icons-material';
import React from 'react';

export default function Notifications() {

  const theme = useTheme();
  const { token } = useContext(AuthContext);
  const [announcements, setAnnouncements] = React.useState<Announcement[] | null>(null);
  const [filterAnchorEl, setFilterAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(filterAnchorEl);
  const [selectedFilter, setSelectedFilter] = React.useState<string>('Tümünü Göster');
  const [selectedRank, setSelectedRank] = React.useState<string>('Yeniden Eskiye');
  const { data: announcementsData, isLoading, error } = useSWR<Announcement[]>(`${baseUrl}/announcement`, (url: string) => fetcher(url, token));

  useEffect(() => {
    if (announcementsData) {
      setAnnouncements(announcementsData?.filter(announcement =>
        announcement.type === AnnouncementType.DUYURU ||
        announcement.type === AnnouncementType.FON ||
        announcement.type === AnnouncementType.BASIN
      ) || []);
    }
  }, [announcementsData])

  useEffect(() => {
    if (selectedFilter === 'Tümünü Göster') {
      setAnnouncements(announcementsData?.filter(announcement =>
        announcement.type === AnnouncementType.DUYURU ||
        announcement.type === AnnouncementType.FON ||
        announcement.type === AnnouncementType.BASIN
      ) || []);
    } else if (selectedFilter === 'Genel Duyurular') {
      setAnnouncements(announcementsData?.filter(announcement =>
        announcement.type === AnnouncementType.DUYURU
      ) || []);
    } else if (selectedFilter === 'Fon Duyuruları') {
      setAnnouncements(announcementsData?.filter(announcement =>
        announcement.type === AnnouncementType.FON
      ) || []);
    } else if (selectedFilter === 'Basın') {
      setAnnouncements(announcementsData?.filter(announcement =>
        announcement.type === AnnouncementType.BASIN
      ) || []);
    }
  }, [selectedFilter])

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleClickSelectedFilter = (filter: string) => {
    setSelectedFilter(filter);
    setFilterAnchorEl(null);
  }

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const [rankAnchorEl, setRankAnchorEl] = React.useState<null | HTMLElement>(null);
  const rankOpen = Boolean(rankAnchorEl);
  const handleClickRank = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRankAnchorEl(event.currentTarget);
  };

  const handleClickSelectedRank = (rank: string) => {
    setSelectedRank(rank);
    setRankAnchorEl(null);
    if (rank === 'Yeniden Eskiye') {
      setAnnouncements(prev => prev?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) || [])
    }
    if (rank === 'Eskiden Yeniye') {
      setAnnouncements(prev => prev?.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()) || [])
    }
  }

  const handleRankClose = () => {
    setRankAnchorEl(null);
  };

  if (isLoading) return <div></div>

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Title title='Duyurular' />
      <Box
        sx={{
          py: 3,
          background: 'white',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <PageView>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <Button
              variant='outlined'
              onClick={handleClickFilter}
              sx={{
                borderColor: '#EAEAEB',
                color: theme.palette.text.primary,
                display: 'flex',
                gap: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                textTransform: 'none',
                fontSize: 16,
              }}
            >
              {selectedFilter}
              <ExpandMore
                sx={{
                  color: theme.palette.text.primary
                }}
              />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={filterAnchorEl}
              open={open}
              onClose={handleFilterClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleClickSelectedFilter("Tümünü Göster")}>Tümünü Göster</MenuItem>
              <MenuItem onClick={() => handleClickSelectedFilter("Genel Duyurular")}>Genel Duyurular</MenuItem>
              <MenuItem onClick={() => handleClickSelectedFilter("Fon Duyuruları")}>Fon Duyuruları</MenuItem>
              <MenuItem onClick={() => handleClickSelectedFilter("Basın")}>Basın</MenuItem>
            </Menu>
            <Button
              variant='outlined'
              onClick={handleClickRank}
              sx={{
                borderColor: '#EAEAEB',
                color: theme.palette.text.primary,
                display: 'flex',
                gap: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: 16,
                textTransform: 'none',
              }}
            >
              {selectedRank}
              <ExpandMore
                sx={{
                  color: theme.palette.text.primary
                }}
              />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={rankAnchorEl}
              open={rankOpen}
              onClose={handleRankClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleClickSelectedRank("Yeniden Eskiye")}>Yeniden Eskiye</MenuItem>
              <MenuItem onClick={() => handleClickSelectedRank("Eskiden Yeniye")}>Eskiden Yeniye</MenuItem>
            </Menu>
          </Box>
        </PageView>
      </Box>
      <PageView
        sx={{
          pt: 10,
          pb: 20
        }}
      >
        <Grid container spacing={2}>
          {announcements?.map((notification) => (
            <Grid item sm={12} md={6} key={notification.id} sx={{ width: '100%' }}>
              <NotificationItem
                notification={notification}
              />
            </Grid>
          ))}
        </Grid>
      </PageView>
    </Box>
  )
}
