import { Box, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useContext, useState } from 'react';
import useSWR from 'swr';
import BarChart from '../components/BarChart';
import { AuthContext } from '../contexts/AuthContext';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
export default function Dashboard() {

  const { token } = useContext(AuthContext);
  //   Son 1 saat
  //   Günlük
  //   Haftalık
  //   Aylık
  //   Yıllık
  // Tüm Zamanlar
  type TimeFilter = 'hour' | 'day' | 'week' | 'month' | 'year' | 'all-time';

  const [timeFilter, setTimeFilter] = useState<TimeFilter>('all-time');

  const { data: pageVisit, isLoading, error } = useSWR<{ pageName: string, count: number }[]>(`${baseUrl}/page-visit?filter=${timeFilter}`, (url: string) => fetcher(url, token));
  const { data: siteVisit, isLoading: siteVisitLoading, error: siteVisitError } = useSWR<number>(`${baseUrl}/site-visit`, (url: string) => fetcher(url, token));

  return (
    <Box
      sx={{
        width: '100%',
        p: 4,
      }}
    >
      {(isLoading || siteVisitLoading) && <div>Yükleniyor...</div>}
      {error && <div>Hata oluştu</div>}
      {siteVisitError && <div>Hata oluştu</div>}
      {pageVisit && siteVisit && (
        <Box>
          <Typography
            variant='h2'
            sx={{
              fontWeight: 600,
              fontSize: { xs: '28px', md: '44px' },
              lineHeight: { xs: '36px', md: '53px' },
              textAlign: 'center',
              color: 'rgba(28,27,31,1)',
              pb: 5
            }}
          >
            Site ziyaret: {siteVisit}
          </Typography>
          <Box sx={{ width: 200 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Filtre</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={timeFilter}
                label="Filtre"
                onChange={(e) => setTimeFilter(e.target.value as TimeFilter)}
              >
                <MenuItem value={'hour'}>Son 1 saat</MenuItem>
                <MenuItem value={'day'}>Günlük</MenuItem>
                <MenuItem value={'week'}>Haftalık</MenuItem>
                <MenuItem value={'month'}>Aylık</MenuItem>
                <MenuItem value={'year'}>Yıllık</MenuItem>
                <MenuItem value={'all-time'}>Tüm Zamanlar</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <BarChart
            pageVisit={pageVisit}
          />
          {/* <BarChartX pageVisit={pageVisit} /> */}
        </Box>

      )}

    </Box>
  )
}
