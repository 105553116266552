import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import FundsIncomeTableRowItem from './FundsIncomeTableRowItem';
import { Fon } from '../utils/types';

type Props = {
  funds: Fon[];
  selectedPeriod: string;
};

export default function FundsIncomeTable({ funds, selectedPeriod }: Props) {

  return (
    <Box
      sx={{
        px: 2,
        py: 3,
        width: '100%',
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                '&:last-child th, &:last-child td': {
                  borderBottom: 0,
                  fontWeight: 600
                },
                backgroundColor: '#F0F3F5',
                height: '52px',
              }}
            >
              <TableCell
                key={1}
                sx={{
                  py: 1,
                  borderRadius: '10px 0 0 10px',
                }}
              >
                Fon Kodu Fon Adı
              </TableCell>
              <TableCell
                key={2}
                sx={{
                  py: 1,
                }}
              >
                Risk Seviyesi
              </TableCell>
              <TableCell
                key={3}
                sx={{
                  py: 1,
                }}
              >
                Birim Pay Fyatı
              </TableCell>
              <TableCell
                key={4}
                sx={{
                  py: 1,
                  borderRadius: '0 10px 10px 0',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Getiri (%)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {funds.map((fund, index) => (
              <FundsIncomeTableRowItem
                key={index}
                fund={fund}
                selectedPeriod={selectedPeriod}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
