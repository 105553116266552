import { ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Card, CardActions, CardMedia, IconButton, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
// import aliBey from '../assets/ali-fuat-erbil.jpg';
// import arifBey from '../assets/arif-isfendiyaroglu.jpg';
// import bernaHanim from '../assets/berna-sema-yigit-sevindi.jpg';
// import boraBey from '../assets/bora-oruc.jpg';
// import ibrahimBey from '../assets/ibrahim.jpg';
// import managers from '../assets/ilkay-civelek.jpg';
import placeholderImge from '../assets/image-placeholder.svg';
// import inciHanim from '../assets/inci-aksun.jpg';
// import managers1 from '../assets/muge-akgul.jpg';
// import salim from '../assets/selim-kasap.jpg';
// import semih from '../assets/semih-sezgin.jpg';
// import managers2 from '../assets/yeliz-yavuz.jpg';

type Props = {
  handleSubmit: (values: any, type: string) => void;
  type: 'yoneticilerimiz' | 'yonetim-kurulu';
  data: any;
}

export default function ManagersCommunityEditor({ handleSubmit, type, data }: Props) {

  // const managersDatas = [
  //   {
  //     name: 'Arif Özer İsfendiyaroğlu',
  //     position: 'Yönetim Kurulu Başkanı',
  //     image: arifBey,
  //     information: `18 yılı üst düzey yöneticilik ve yönetim kurulu üyeliği pozisyonlarında olmak üzere finans sektöründe 34 yılı aşkın bir deneyime sahiptir. Garanti, Denizbank, Akbank ve Yapı Kredi gibi Türkiye'nin önde gelen bankalarında Genel Müdür Yardımcısı olarak görev yapmıştır. 2020 yılında, yatırımda eşitlik yaratma amacıyla Türkiye'nin yeni nesil yatırım platformu Foneria'yı, ardından 2022'de Foneria Portföy Yönetimi A.Ş.'yi kurmuştur. İsfendiyaroğlu, şu anda hem Foneria'nın Kurucusu ve Yönetim Kurulu Başkanı olarak hem de Foneria Portföy Yönetimi A.Ş.'nin Yönetim Kurulu Başkanı olarak görev yapmaktadır.`,
  //     education: `İTÜ Mühendislik Fakültesi ‘90, İstanbul Bilgi Universitesi & Manchester School  Executive MBA 2000`,
  //     linkedin: 'https://www.linkedin.com/in/arif-isfendiyaroğlu-729bab84/',
  //   },
  //   {
  //     name: 'Bora ORUÇ',
  //     position: 'Yönetim Kurulu Başkan Vekili',
  //     image: boraBey,
  //     information: `25 yıllık sermaye piyasaları deneyiminin son 10 yılında üst düzey yöneticilik ve yönetim kurulu üyeliği pozisyonlarında görev almıştır. Kariyerine 1999 yılında Sermaye Piyasası Kurulu'nda başlayan Oruç 2014 ile 2020 yılları arasında SPK Başkan Yardımcılığı yapmıştır. Bora Oruç profesyonel hayatına Foneria Portföy Yönetimi A.Ş. Yönetim Kurulu Başkan Vekili ve OMG Capital Advisors'da Yönetici Ortak olarak devam etmektedir.`,
  //     education: `ODTÜ Ekonomi ‘99, University of Cambridge MBA ‘09`,
  //     linkedin: 'https://www.linkedin.com/in/bora-oruç-8942871a0/',
  //   },
  //   {
  //     name: 'Ali Fuat Erbil',
  //     position: 'Yönetim Kurulu Üyesi',
  //     image: aliBey,
  //     information: `32 yıllık finans deneyiminin 25 yılında üst düzey yöneticilik ve yönetim kurulu başkanlığı pozisyonlarında görev almıştır. 2019 yılına kadar Garanti Bankası Genel Müdür ve Yönetim Kurulu üyesi olarak görev yapan Erbil, bu süre zarfında banka iştiraklerinde de Yönetim Kurulu Başkanı unvanıyla yer almıştır. Credit Europe Bank Hollanda ve Ukrayna, Doğan Holding A.Ş., Kanal D Romanya, TAM Finans Faktoring A.Ş., Fibabank A.Ş. ve Foneria Portföy Yönetimi A.Ş. şirketlerinde yönetim kurulu üyesi olarak görevini sürdürürken, aynı zamanda ABD merkezli C2FO’nın Global Danışma Kurulu Üyesidir.`,
  //     education: `ODTÜ Bilgisayar Mühendisliği ‘90, Bilkent Üniversitesi MBA ’92, İTÜ İşletme PhD ‘97`,
  //     linkedin: 'https://www.linkedin.com/in/ali-fuat-erbil-b88244/',
  //   },
  //   {
  //     name: 'İnci Aksun',
  //     position: 'Yönetim Kurulu Üyesi',
  //     image: inciHanim,
  //     information: `2021 yılına kadar TEB, Akbank, Yapı Kredi Bankası gibi Türkiye’nin köklü finans kurumlarında Bireysel Bankacılık, Krediler, İş Geliştirme, Pazarlama ve Ürün Yönetimi alanlarında görev yapan Aksun, 2015 yılında Akbank Bireysel Bankacılık Pazarlama Bölüm Başkanı pozisyonuna atandı. 2020 yılında bireysel yatırımcıya yönelik  yeni nesil dijital hizmetler sunan Foneria’nın kuruluşunda Strateji ve Finans’tan sorumlu kurucu olarak yer aldı. Halen Foneria’da Strateji ve Finanstan Sorumlu Yönetim Kurulu Üyeliği ile Foneria Portföy Yönetimi A.Ş.’de Yönetim Kurulu Üyeliği görevlerini sürdürmektedir.`,
  //     education: `Bilkent Üniversitesi Elektronik Mühendisliği ‘95, Bilkent Üniversitesi MBA ’91`,
  //     linkedin: 'https://www.linkedin.com/in/inci-aksun-882b9713/',
  //   },
  //   {
  //     name: 'Berna Sema Yiğit Sevindi',
  //     position: 'Yönetim Kurulu Üyesi',
  //     image: bernaHanim,
  //     information: `Finans sektöründe 27 yıllık tecrübesi bulununan Sevindi, Denizbank Finansal Hizmetler grubunda, Deniz Yatırım, Deniz Portföy Yönetimi A.Ş.’de 18 yıl yönetici pozisyonunda, QNB Finans Yatırım Menkul Değerler A.Ş. ve 24 Gayrimenkul ve Girişim Sermayesi Portföy Yönetimi A.Ş.’de Genel Müdür Yardımcısı olarak üst düzey yöneticilik yapmıştır. 
  //     Gayrimenkul Yatırım Fonları ve Muhasebe Uygulamaları kitabının yazarıdır. GYODER’de Gayrimenkul Yatırım Fonları komite başkanı olarak görev almaktadır. 
  //     `,
  //     education: `İstanbul Teknik Üniversitesi İşletme ve Teknoloji Yönetimi Yüksek Lisans`,
  //     linkedin: 'https://www.linkedin.com/in/berna-sema-yiğit-sevindi-80384a3b/',
  //   },
  //   {
  //     name: "İbrahim Emrah Gürelli",
  //     position: "Yönetim Kurulu Üyesi",
  //     image: ibrahimBey,
  //     information: `
  //     1975 İzmir’de doğan Emrah Gürelli , 1993 yılında İzmir Bornova Anadolu Lisesi’nden mezun oldu. Üniversite eğitimini Orta Doğu Teknik Üniversitesi Psikoloji bölümünde tamamladıktan sonra , iş hayatına Meksa Menkul Değerler’de yatırım uzmanı olarak başladı. Daha sonra Tekstil Menkul Değerler’de yatırım danışmanı olarak  kariyerini sürdürdü. Bu sırada Marmara Üniversitesi Sermaye Piyasaları ve Borsa bölümünde yüksek lisansını tamamladı. Kariyerine Ak Yatırım Menkul Değerler’de Müdür  Yardımcısı olarak devam eden Gürelli son olarak Tekstil  (ICBC) Yatırım Varlık Yönetimi’nde Bölüm Başkanı olarak görev aldı.
  //     <br />
  //     <br />
  //     2013 yılında finans piyasalarında yaklaşık 14 yıl süren kariyer hayatını sonlandırma kararı alan Emrah Gürelli , Gürelli Danışmanlık şirketini kurdu. Kuruluşundan bu yana YRCG Danışmanlık A.Ş.’nde yönetici ortak olarak görevine devam etmektedir.      `,
  //     education: "",
  //     linkedin: "https://www.linkedin.com/in/emrah-g%C3%BCrelli-b35b5145",
  //   }
  // ]

  // const managersDatass = [
  //   {
  //     name: 'İlkay Civelek',
  //     position: 'Genel Müdür',
  //     image: managers,
  //     education: `Marmara Üniversitesi İktisadi İdari Bilimler Fakültesi, İşletme Bölümü 94’ |
  //                 Marmara Üniversitesi İ.İ.B.F. Muhasebe ve Finansman Yüksek Lisansı
  //     `,
  //     information: `
  //     Sermaye piyasalarında 30 yılı aşkın deneyime sahip olan Civelek, iş hayatına bağımsız denetçi olarak başlamıştır. Türkiye’nin ilk online hisse senedi platformu olan Ata Online’ın kurucu ekibinde Genel Müdür Yardımcısı olarak görev almıştır. 2002 yılında Oyak Yatırım’da Yaşar Yatırım ve OB Menkul birleşmesine liderlik etmiş ve Oyak Yatırım Ortaklığı’nın genel müdürü olarak görev yapmıştır. 2012 yılında Alternatif Yatırım’da  genel müdür yardımcısı olarak başladığı görevine genel müdür vekili olarak devam etmiştir. 2015 yılında  Ata Finans Grubunda 8 yıl boyunca kurumun yurtiçi piyasalardan sorumlu genel müdür yardımcısı olarak görev almıştır. Profesyonel iş hayatına İnfo Yatırım’da portföy yöneticisi olarak devam eden Civelek, Nisan 2024’te Foneria Portföy Yönetimi’ne katılarak genel müdür ve yönetim kurulu üyesi olarak çalışmaya devam etmektedir. 
  //     <br />
  //     <br />
  //     Civelek; finans piyasalarındaki uzmanlığının yanı sıra, şirket birleşmeleri ve değerleme, halka arz, iş geliştirme, stratejik iş ilişkileri, bireysel portföy yönetimi, çağrı merkezi ve CRM sistemi kurma, ürün ve kampanya yönetimi, müşteri sadakati ve pazarlama konularında deneyime sahiptir. 
  //     <br />
  //     <br />
  //     Mali Müşavirlik ruhsatı, Sermaye Piyasaları İleri Düzey, Türev Araçlar ve Kurumsal yönetim derecelendirme lisanslarına sahiptir.
  //     `,
  //     linkedin: 'https://www.linkedin.com/in/ilkaycivelek/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
  //   },
  //   {
  //     name: 'Salim Kasap',
  //     position: 'Genel Müdür Yardımcısı',
  //     image: salim,
  //     information: `
  //     Sermaye piyasalarında 25 yılı aşkın deneyime sahip olan Kasap, iş hayatına Alternatif Bank'ta başlamıştır. Alternatif bank hazine departmanından Alternatif Yatırım yatırım fonları departmanına geçen Kasap, 1997 yılına kadar bu görevine devam etmiştir. 1997-1999 yıllarında Site Yatırım Hazine müdürlüğü görevi sonrası 2008 yılına kadar Raymond James Menkul Kıymetler Hazine Müdürü olarak görev yapmıştır. Kasap, 2009 yılında Tera Menkul Uluslararası Piyasalar Müdürlüğü , Turkish Yatırım Strateji Müdürlüğü, Ziraat Portföy Genel Müdür Yardımcılığı, Şeker Yatırım FX Müdürlüğü, Finans Portföy CIO, Gedik Yatırım Portföy Aracılığı Direktörü ve Hedef Portföy Portföy Yöneticiliği görevlerini üstlenmiştir.Mayıs 2024 itibariyle Foneria Portföy Yönetimi’nde genel müdür yardımcısı olarak çalışmaya devam etmektedir.  
  //     <br />
  //     <br />
  //     Kasap; elektronik işlemler, türev ürünler, finansla model geliştirme, iş geliştirme, stratejik iş ilişkileri, bireysel portföy yönetimi, müşteri sadakati ve pazarlama konularında deneyime sahiptir. 
  //     <br />
  //     <br />
  //     Sermaye Piyasaları İleri Düzey, Türev Araçlar lisanslarına sahiptir.
  //     `,
  //     education: `
  //     Dokuz Eylül Üniversitesi İşletme Fakültesi, İngilizce İşletme Bölümü 95’ | 
  //     Marmara Üniversitesi Bankacılık ve Sigortacılık Enstitüsü Sermaye Piyasaları Yüksek lisansı
  //     `,
  //     linkedin: 'https://www.linkedin.com/in/salim-kasap-8ba5a020/'
  //   },
  //   {
  //     name: 'Müge Akgül',
  //     position: 'Teftiş Direktörü',
  //     information: `
  //     2006 yılında denetim sektöründe bağımsız denetçi olarak başladığı kariyerine, sermaye piyasası aracı kurumlarında ve özel sektörde çeşitli şirketlerde sırasıyla Müfettiş, Kıdemli Müfettiş, Başmüfettiş ve Teftiş Birimi Başkanı olarak devam etmiştir. Foneria Portföy Yönetimi A.Ş.’deki görevinden önce aynı zamanda bir Mali Müşavir olarak anayasal suçlarda ve vergi ceza davalarında bilirkişilik yapmıştır. ACFE ve USİUD üyesidir.
  //     <br />
  //     <br />
  //     İstanbul Üniversitesi İktisat lisans, Aston American University MBA-Finansal Yönetim derecesine sahiptir ve halen Aston American University’de E-DBA yapmaktadır.
  //     `,
  //     linkedin: 'https://www.linkedin.com/in/muge-akgul-36584217b/?trk=public_profile_browsemap_profile-result-card_result-card_full-click&originalSubdomain=tr',
  //     image: managers1
  //   },
  //   {
  //     name: 'Yeliz Yavuz',
  //     position: 'Müşteri İlişkileri Müdürü',
  //     information: `
  //     15 yılı aşkın Bireysel Bankacılık deneyimine sahip olan Yavuz; 2008 yılında Akbank T.A.Ş Bireysel Bankacılık Satış ve Pazarlama Yönetimi alanında başladığı kariyerine,İnsan Kaynakları Yöneticiliği sonrası 1e1 Bankacılık Yöneticisi olarak devam etmiştir.
  //     <br />
  //     <br />
  //     Ocak 23’ itibariyle Foneria Portföy ‘de Müşteri İlişkileri Müdürlüğü görevini devam ettirmektedir.
  //     <br />
  //     <br />
  //     Sermaye Piyasası Düzey 1 lisansı, Müşteri İlişkileri Yönetimi (CRM) ve Yetenek Yönetimi, CRM -Pazarlama ve Satış Yönetimi Modülü Tasarım Ve Uygulama ,CSM Uyarlama&Tasarım Anahtar Kullanıcı Eğitimi ve Müşteri Hizmetleri Yönetimi Modülü Sertifikalarına sahiptir.
  //     `,
  //     linkedin: 'https://www.linkedin.com/in/yeliz-yavuz-1b708477/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
  //     education: `
  //     Gazi Üniversitesi İ.İ.B.FÇalışma Ekonomisi ve Endüstri İlişkileri 06’ |
  //     Anadolu Üniversitesi İnsan Kaynakları Yönetimi Bölümü 23’
  //     `,
  //     image: managers2
  //   },
  //   {
  //     name: "Semih Sezgin",
  //     position: "Direktör",
  //     image: semih,
  //     linkedin: "https://www.linkedin.com/in/semih-sezgin-7829117b/",
  //     information:
  //       `
  //       Finans sektöründe 11 yıllık tecrübesi bulunan Sezgin, 9 yıl HSBC ve Odea Bankta portföy yöneticisi olarak görev yapmıştır. Şuan Foneria Portföy Yönetimi A.Ş. bünyesinde Direktör olarak çalışmaktadır.
  //       <br />
  //       <br />
  //       Dumlupınar Üniversitesi Kamu Yönetimi Bölümü’nden lisans derecesine sahiptir.
  //       <br />
  //       <br />
  //       Sermaye Piyasaları İleri Düzey ve Türev Araçlar lisanslarına sahiptir.
  //       `
  //   },
  // ]

  // console.log(data)

  const [managersData, setManagersData] = useState<any>(data || [])

  const handleImageSelect = (e: any, index: number) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const url = e.target?.result as string;
      setManagersData((prev: any) => {
        const newManagersData = [...prev];
        newManagersData[index].image = url;
        return newManagersData;
      })
    }
    reader.readAsDataURL(file);
  }

  const handleImageDelete = (index: number) => {
    setManagersData((prev: any) => {
      const newManagersData = [...prev];
      newManagersData[index].image = '';
      return newManagersData;
    })
  }

  const handleAddNew = () => {
    setManagersData((prev: any) => {
      const newManagersData = [...prev];
      newManagersData.push({
        name: '',
        position: '',
        image: '',
        information: '',
        education: '',
        linkedin: '',
      })
      return newManagersData;
    })
  }

  const handleDelete = (index: number) => {
    setManagersData((prev: any) => {
      const newManagersData = [...prev];
      newManagersData.splice(index, 1);
      return newManagersData;
    })
  }

  const handleMoveUp = (index: number) => {
    if (index === 0) return;
    setManagersData((prev: any) => {
      const newManagersData = [...prev];
      const temp = newManagersData[index];
      newManagersData[index] = newManagersData[index - 1];
      newManagersData[index - 1] = temp;
      return newManagersData;
    })
  }

  const handleMoveDown = (index: number) => {
    if (index === managersData.length - 1) return;
    setManagersData((prev: any) => {
      const newManagersData = [...prev];
      const temp = newManagersData[index];
      newManagersData[index] = newManagersData[index + 1];
      newManagersData[index + 1] = temp;
      return newManagersData;
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {managersData.map((manager: any, index: number) => (
        <Box key={index}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              gap: 2,
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 20,
                right: 20,
                display: 'flex',
                gap: 1,
              }}
            >
              {index !== 0 && (
                <IconButton
                  onClick={() => handleMoveUp(index)}
                >
                  <ArrowUpward />
                </IconButton>
              )}
              {index !== managersData.length - 1 && (
                <IconButton
                  onClick={() => handleMoveDown(index)}
                >
                  <ArrowDownward />
                </IconButton>
              )}
              <IconButton
                onClick={() => handleDelete(index)}
              >
                <Delete />
              </IconButton>
            </Box>
            <Card sx={{ display: 'inline-block' }}>
              <Typography variant="h6" component="div" sx={{ p: 2 }}>
                Fotoğraf
              </Typography>
              <CardMedia
                component="img"
                image={manager.image || placeholderImge}
                alt="cover-image"
                sx={{ maxHeight: 350, objectFit: 'contain' }}
              />
              <CardActions>
                <Button component="label" size="large" color="primary">
                  RESİM EKLE
                  <input type="file" hidden accept="image/*" onChange={(e) => handleImageSelect(e, index)} />
                </Button>
                <IconButton aria-label="delete" size="small" color="primary" onClick={() => { handleImageDelete(index) }}>
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
            <TextField
              label="Ad Soyad"
              value={manager.name}
              onChange={(e) => {
                setManagersData((prev: any) => {
                  const newManagersData = [...prev];
                  newManagersData[index].name = e.target.value;
                  return newManagersData;
                })
              }}
              fullWidth
            />
            <TextField
              label="Pozisyon"
              value={manager.position}
              onChange={(e) => {
                setManagersData((prev: any) => {
                  const newManagersData = [...prev];
                  newManagersData[index].position = e.target.value;
                  return newManagersData;
                })
              }}
              fullWidth
            />
            <TextField
              label="Eğitim"
              value={manager.education}
              multiline
              rows={3}
              onChange={(e) => {
                setManagersData((prev: any) => {
                  const newManagersData = [...prev];
                  newManagersData[index].education = e.target.value;
                  return newManagersData;
                })
              }}
              fullWidth
            />
            <TextField
              label="Linkedin"
              value={manager.linkedin}
              onChange={(e) => {
                setManagersData((prev: any) => {
                  const newManagersData = [...prev];
                  newManagersData[index].linkedin = e.target.value;
                  return newManagersData;
                })
              }}
              fullWidth
            />
            <TextField
              label="Bilgi"
              value={manager.information}
              onChange={(e) => {
                setManagersData((prev: any) => {
                  const newManagersData = [...prev];
                  newManagersData[index].information = e.target.value;
                  return newManagersData;
                })
              }}
              fullWidth
              multiline
              rows={8}
            />
          </Paper>
        </Box>
      ))}
      <Box
        sx={{
          display: 'flex',
          gap: 2,

        }}
      >
        <Button
          variant='outlined'
          onClick={handleAddNew}
        >
          Yeni Ekle
        </Button>
        <Button
          variant="contained"
          onClick={() => handleSubmit(managersData, type)}
        >
          Kaydet
        </Button>
      </Box>
    </Box>
  )
}
