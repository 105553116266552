import { Box } from '@mui/material';
import React from 'react'

type Props = {
  text: string;
  style?: React.CSSProperties;
}

export default function MarkdownViewer({ text, style }: Props) {
  return (
    <Box
    sx={{
      fontSize: '20px',
      lineHeight: '30px',
      color: 'rgba(33,37,41,1)',
      fontWeight: 400,
      '& p': {
        margin: 0,
        padding: 0
      },
      ...style,
    
    }}
    >
      <div
        style={{
          //disable p tag margin and padding
        }}
        dangerouslySetInnerHTML={{
          __html: text
        }}
      >
      </div>
    </Box>
  )
}
