import { Box, Grid } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import FundCard from './FundCard';
import PageView from './PageView';
import { Fon, Umbrella } from '../utils/types';
import UmbrellaCard from './UmbrellaCard';

type Props = {
  funds?: Fon[]
  umbrellas?: Umbrella[]
}

export default function Companies({ funds, umbrellas }: Props) {
  const { type } = useParams<{ type: string }>();

  const getLink = (fund: Fon) => {
    if (fund.umbrellaId) {
      return `/yatirim-fonlari/${type}/semsiye-fonlari/${fund.umbrellaId}/${fund.code || fund.id}`
    } else {
      return `/yatirim-fonlari/${type}/${fund.code || fund.id}`
    }
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {funds?.map((fund, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Link to={getLink(fund)}
              style={{
                textDecoration: 'none',
              }}>
              <FundCard
                key={index}
                fund={fund}
              />
            </Link>
          </Grid>
        ))}
        {umbrellas?.map((umbrella, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Link to={`/yatirim-fonlari/${type}/semsiye-fonlari/${umbrella.id}`}
              style={{
                textDecoration: 'none'
              }}>
              <UmbrellaCard
                key={index}
                umbrella={umbrella}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

