import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Appbar from './Appbar'
import Footer from './Footer'
import { baseUrl } from '../utils/constants'
import { debounce } from 'lodash';

type LayoutProps = {
  children: React.ReactNode
}

const sendPageView = async (page: string) => {
  if (window.location.href.includes('localhost')) return;
  try {
    await fetch(`${baseUrl}/page-visit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        pageName: page
      })
    });
  } catch (err) {
    console.log(err);
  }
};

const sendPageViewDebounced = debounce(sendPageView, 100);


export default function Layout({ children }: LayoutProps) {

  const loc = useLocation();

  useEffect(() => {
    if (loc.pathname.includes('admin')) return;
    sendPageViewDebounced(loc.pathname);
    window.scrollTo(0, 0);
  }, [loc.pathname]);

  if (loc.pathname.includes('/admin')) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 2px)',
        pt: '126px'
      }}
    >
      <Appbar />
      <Box
        sx={{
          flex: 1,
          zIndex: 1,
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  )
}
