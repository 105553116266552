import React from 'react';
import { Box, Divider, IconButton, Typography, useTheme, useMediaQuery, Button } from '@mui/material';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { FileType } from '../utils/types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { baseUrl } from '../utils/constants';

type DocumentProps = {
    documents: FileType[];
}

const Document: React.FC<DocumentProps> = ({ documents }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                border: '1px solid rgba(230, 233, 237, 1)',
                borderRadius: '8px',
                background: 'white',
                overflow: 'hidden',
                p: 4
            }}
        >
            <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ fontWeight: 600, mb: 2, ml: 2 }}>
                Fon Bilgilendirme Dokümanları
            </Typography>
            {documents.map((item, index) => (
                <Box key={index}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 2,
                            gap: 2
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2,width:'100%' }}>
                            <Box
                                sx={{
                                    background: 'rgba(28, 27, 31, 0.2)',
                                    borderRadius: '50%',
                                    p: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <FileOpenIcon fontSize={isMobile ? 'medium' : 'large'} />
                            </Box>
                            <Typography variant={isMobile ? 'body1' : 'h6'} sx={{ fontWeight: 500 }}>
                                {item.name}
                            </Typography>
                        </Box>
                        <Button
                            href={`${baseUrl}/file/${item.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                            sx={{
                                borderRadius: '4px',
                                color: 'white',
                                textTransform: 'none',
                            }}
                        >
                            İncele
                        </Button>
                    </Box>
                    {index !== documents.length - 1 && <Divider />}
                </Box>
            ))}
        </Box>
    );
}

export default Document;
