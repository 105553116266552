import { Box, Typography, useTheme } from '@mui/material';
import image from '../assets/partnership.jpg';
import React from 'react';


export default function PartnerShipTable() {
  const theme = useTheme();

  const data = [
    {
      owner: "Bülent KIRIMLI",
      ratio: "50,0%",
    },
    {
      owner: "Arif Özer İSFENDİYAROĞLU",
      ratio: "40,0%",
    },
    {
      owner: "İnci AKSUN",
      ratio: "5,6%",
    },
    {
      owner: "Berna Sema YİĞİT SEVİNDİ",
      ratio: "3,0%",
    },
    {
      owner: "Bora ORUÇ",
      ratio: "1,4%",
    }
  ];

  return (
    <Box
      sx={{
        px: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '26px',
          lineHeight: '30px',
          color: theme.palette.primary.main,
        }}
      >
        Foneria Portföy Yönetimi A.Ş. Ortaklık Yapısı
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          gap: 2,
          width: '100%',
          py: 3,
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <img
            src={image}
            className="img-hover-effect"
            alt="partnership"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: 10,
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Box
            sx={{
              background: '#F0F3F5',
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              py: '12px',
              px: '20px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              Pay Sahibi
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              Pay Oranı
            </Typography>
          </Box>
          {data.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                py: '12px',
                px: '20px',
                borderBottom: '1px dashed #E6E9ED',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                }}
              >
                {item.owner}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                }}
              >
                {item.ratio}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
