import { LoadingButton } from '@mui/lab'
import { Paper, Box, TextField, Typography, Button } from '@mui/material'
import React from 'react'

type Props = {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  handleAdd: () => void;
}

export default function AddNewLinkPaper({ title, setTitle, url, setUrl, handleAdd }: Props) {
  return (
    <Paper
      sx={{
        background: 'rgba(255, 255, 255, 1)',
        margin: '0 auto',
        padding: 2,
        mt: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          width: '100%',
          gap: 2
        }}
      >
        <Box
          sx={{
            flex: 1
          }}
        >
          <TextField
            label="Link Adı"
            name="name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{
              mt: 2
            }}
          />
          <TextField
            label="Link"
            name="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{
              mt: 2
            }}
          />
          <Button
            variant="contained"
            onClick={handleAdd}
            sx={{
              mt: 2
            }}
          >
            Ekle
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}
