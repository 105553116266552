import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useSWR from 'swr';
import ann from '../assets/anno.png';
import { AuthContext } from '../contexts/AuthContext';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { Announcement, AnnouncementType, BannerItemType } from '../utils/types';
import BannerItem from './BannerItem';
import PageView from './PageView';

interface Content {
  title: string;
  description: string;
}

const contentListdata: Content[] = [
  {
    title: "Foneria’da siz de portföy sahibi olun, kafanız çok rahat olsun.",
    description:
      "Tutarı her ne olursa olsun yatırımlarını fonlarda değerlendirmek isteyen tüm müşterilerimize, özel portföy yönetimi hizmetini uygun şartlarda kolay bir şekilde ulaştırmaktır.",
  },
];

export default function Foneria() {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { token } = useContext(AuthContext);

  const { data: announcementsData, isLoading, error } = useSWR<Announcement[]>(`${baseUrl}/announcement`, (url: string) => fetcher(url, token));

  const banners = announcementsData?.filter(announcement => announcement.type === AnnouncementType.BANNER);

  // const contentList = announcementsData ? contentListdata.concat(bultens?.map((item) => ({
  //   title: item.title,
  //   description: item.message,
  // })) || []) : contentListdata;

  const contentList = banners ? banners.map((item) => ({
    title: item.title,
    description: item.message,
  })) : contentListdata;

  // background: 'linear-gradient(90deg, #78002e9a 0%, #d9d9d9 20%, #d9d9d9 80%, #78002e9a 100%)'

  const bannerInfos: BannerItemType[] = [
    // {
    //   title: "Yatırımda Fırsat Eşitliği ",
    //   description: "Portföy yönetimi hizmetini, teknolojiden de faydalanarak geniş kitlelere ulaştırmak için Foneria ile işbirliği ile geliştirilen Otonom Portföyler ile tanışın",
    //   link: "/yatirim-fonlari/otonom-portfoyler",
    //   image: banner1,
    //   alignment: 'center'
    // },
    {
      title: "Yatırım Fonlarında Stopaj Değişikliği",
      description: "",
      link: "/stopaj-orani-degisikligi",
      buttonText: "Detaylı Bilgi İçin",
      image: ann,
      alignment: 'center'
    },
    {
      title: "Portföyünüz GİRİŞİM SERMAYESİ YATIRIM FONLARI ile çeşitlensin!",
      description: " Yatırımlarınız, farklı alanlarda yenilikçi projelere yatırım yapan girişim sermayesi yatırım fonlarımızda değerlensin!",
      link: "/yatirim-fonlari/girisim-sermayesi-yatirim-fonlari",
      alignment: 'center',
      buttonText: "İncelemek İçin Tıklayın",
    },
    {
      title: "Foneria Portföy Çoklu Katılım Fonu (FCK)",
      description: "Menkul kıymet yatırım fonlarımıza bir yenisi eklendi; FCK. İlk pay ihracı gerçekleşen fonun alım işlemleri için Foneria uygulamasını, banka ve aracı kurumları ziyaret edebilirsiniz.",
      link: "/yatirim-fonlari/katilim-fonlari/FCK",
      alignment: 'center',
      buttonText: "İncelemek İçin Tıklayın",
    },
  ]

  return (
    <Box
      sx={{
        background: 'linear-gradient(to right, #78002E, #ffffff)'
        // background: 'linear-gradient(89.95deg, rgba(120, 0, 46, 0.82) 0.04%, rgba(166, 102, 127, 0.904957) 0.04%, #D9D9D9 99.96%)'
      }}
    >
      <PageView
        sx={{
          height: isSmallScreen ? 'auto' : '600px',
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          padding: isSmallScreen ? '20px' : '0',
          overflow: 'hidden',
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <Carousel
          autoPlay
          infiniteLoop
          showArrows
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          interval={10000}
          transitionTime={1000}
          stopOnHover={false}
          swipeable={isSmallScreen}
          emulateTouch={false}
          dynamicHeight={false}
          width="100%"
        >
          {/* {contentList.map((content, index) => (
            <Box
              sx={{
                width: '100%',
                maxWidth: { xs: 300, sm: 500, md: 750 }, // Responsive max width
                textAlign: 'center',
                mx: 'auto',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '28px', sm: '36px', md: '50px' }, // Responsive font sizes
                  lineHeight: { xs: '30px', sm: '40px', md: '60px' },
                  color: 'rgba(28, 27, 31, 1)',
                  textAlign: 'left',
                  mb: 1,
                }}
              >
                {content.title}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: '16px', sm: '18px', md: '25px' }, // Responsive font sizes
                  lineHeight: { xs: '24px', sm: '30px', md: '42px' },
                  color: 'rgba(45, 43, 51, 1)',
                  textAlign: 'left',
                }}
              >
                {content.description}
              </Typography>
            </Box>
          ))} */}
          {bannerInfos.map((bannerInfo, index) => (
            <BannerItem
              key={index}
              item={bannerInfo}
            />
          ))}
        </Carousel>
      </PageView>
    </Box>
  );
}
