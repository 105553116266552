import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';

type Props = {
  data: any
}

export default function ManagerItem({ data }: Props) {

  return (
    <Box
      sx={{
        flexDirection: ['column', 'row'],
        display: 'flex',
        alignItems: 'flex-start',
        gap: 5,
        py: 7,
        px: { xs: 2, sm: 4, md: 6 },
      }}
    >
      <img
        className="img-hover-effect"
        src={data.image}
        style={{ width: '250px', aspectRatio: 1 }}
        alt="managers"
      />
      <Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '30px',
            color: 'rgba(28,27,31,1)',
          }}
        >
          {data.name}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '35px',
            color: 'rgba(28,27,31,1)',
            pb: 2
          }}
        >
          {data.position}
        </Typography>
        <Typography
          style={{
            textAlign: 'justify',
            whiteSpace: 'pre-line',
          }}
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
            pb: 2,
            pr: 2,
          }}
        >
          {data.information}
        </Typography>
        {data.education && (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <span style={{ fontWeight: 700 }}>Eğitim:</span>
            <Typography
              sx={{
                whiteSpace: 'pre-line',
              }}
            >
            {data.education}
            </Typography>
          </Box>
        )}
        {data.linkedin && (
          <Box>
            <Typography>
              <span style={{ fontWeight: 700 }}>Bağlantılar: </span>
              <a
                style={{ color: 'rgba(33, 37, 41, 1)' }}
                href={data.linkedin}
                target="_blank"
                rel="noreferrer">
                LinkedIn
              </a>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
