import { Box, Button, IconButton, Typography } from '@mui/material';
import logo2 from '../assets/logo2.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FooterContact from './FooterContact';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const investmentItems = [
  {
    title: "Kurumsal",
    items: ["Hakkımızda", "Yöneticilerimiz", "Yönetim Kurulu"],
  },
  {
    title: "Fonlar",
    items: [],
  },
  {
    title: "Özel Portföy Yönetimi",
    items: [],
  },
  {
    title: "Duyurular",
    items: [
      "Genel Duyurular",
      "Fon Duyuruları",
      "Basında Biz",
      "Foneria Bülten"
    ],
  },
  {
    title: "Yardımcı Bağlantılar",
    items: ["TEFAS", "KAP", "Saklama Hesabı", "Sürekli Bilgilendirme Formu",
      "KVKK",
      "Acil Durum Eylem Planı",
      "Yasal Uyarı",
      "Finansal Bilgiler",],
  },
];

export default function Footer() {

  const { portfolios } = useContext(AuthContext);
  const nav = useNavigate();

  const investmentFunds = [ ...portfolios.map(portfolio => portfolio.name)];

  investmentItems[1].items = investmentFunds;

  const handleClick = (text: string) => {
    const slug = portfolios.find(portfolio => portfolio.name === text)?.slug;

    if (slug) {
      return nav(`/yatirim-fonlari/${slug}`);
    }

    switch (text) {
      case "Hakkımızda":
        return nav(`/hakkimizda`);
      case "Yöneticilerimiz":
        return nav(`/yoneticilerimiz`);
      case "Yönetim Kurulu":
        return nav(`/yonetim-kurulu`);
      case "Finansal Bilgiler":
        return nav(`/finansal-bilgiler`);
      case "İletişim":
        return nav(`/iletisim`);
      case "Duyurular":
        return nav(`/duyurular`);
      case "Foneria Bülten":
        return nav(`/foneria-bulten`);
      case "Sürekli Bilgilendirme Formu":
        return nav(`/surekli-bilgilendirme-formu`);
      case "KVKK":
        return nav(`/kvkk`);
      case "Acil Durum Eylem Planı":
        return nav(`/acil-durum-eylem-plani`);
      case "Yasal Uyarı":
        return nav(`/yasal-uyari`);
      case "TEFAS":
        return window.open('https://www.tefas.gov.tr/FonAnaliz.aspx', '_blank');
      case "KAP":
        return window.open('https://www.kap.org.tr/tr/', '_blank');
      case "Saklama Hesabı":
        return window.open('https://acikdeniz.denizbank.com/', '_blank');
      default:
        break;
    }
  }

  return (
    <Box>
      <FooterContact />
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          background: 'white',
          px: 1
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            gap: { md: 10, xs: 5 },
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            py: 7
          }}
        >
          <Box
            sx={{
              width: '230px',
              order: [2, 1],
              textAlign: ['center', 'left'],
              gap: 1
            }}
          >
            <img src={logo2} alt='logo' style={{ width: '100%' }} />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '15px',
                lineHeight: '20px',
                color: 'rgba(28, 27, 31, 1)',

              }}
            >
              Foneria Portföy Yönetimi A.Ş.
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '20px',
                color: 'rgba(45, 43, 51, 1)',
              }}
            >
              Ömer Avni Mah. İnönü Caddesi Devres Han No:50 İç Kapı No:2 Kat: B2
              Gümüşsuyu, Beyoğlu/İSTANBUL
            </Typography>
            <IconButton
              onClick={() => window.open('https://www.linkedin.com/company/foneria-portfoy', '_blank')}
            >
              <LinkedInIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: { md: 8, sm: 6, xs: 5 },
              flexDirection: ['column', 'row'],
              order: [1, 2],
              textAlign: ['center', 'left'],
            }}
          >
            {investmentItems.map((section, index) => (
              <Box key={index}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '15px',
                    lineHeight: '20px',
                    color: 'rgba(28, 27, 31, 1)',
                    textAlign: 'left',
                    pl: 1
                  }}
                >
                  {section.title}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    '& > button:not(:last-child)': {
                      mb: -1,
                    },
                  }}
                >
                  {section.items.map((text, i) => (
                    <Button
                      key={i}
                      onClick={() => handleClick(text)}
                      sx={{
                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '13px',
                          lineHeight: '22px',
                          color: 'rgba(45, 43, 51, 1)',
                        }}
                      >
                        {text}
                      </Typography>
                    </Button>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
