import { Box, Typography } from "@mui/material";

interface TitleProps {
  title: string;
}

export default function Title({ title }: TitleProps) {
  return (
    <Box
      sx={{
        px: 2,
        minHeight: "220px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: 'linear-gradient(89.95deg, rgba(120, 0, 46, 0.82) 0.04%, rgba(166, 102, 127, 0.904957) 0.04%, #D9D9D9 99.96%)'
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: { xs: "2rem", md: "3rem" },
          lineHeight: "60px",
          color: "191b1f",
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}
