import { Box, InputAdornment, Menu, MenuItem, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import PageView from './PageView'
import { ExpandMore } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import FundsIncomeTable from './FundsIncomeTable';
import { AuthContext } from '../contexts/AuthContext';
import { Fon } from '../utils/types';

export default function FundsIncome() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { portfolios } = useContext(AuthContext);
  const [funds, setFunds] = React.useState<Fon[]>([]);
  const [filteredFunds, setFilteredFunds] = React.useState<Fon[]>([])
  const [searchText, setSearchText] = React.useState<string>('');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedPeriod, setSelectedPeriod] = React.useState<string>('Son 1 Gün');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSelectedPeriod = (period: string) => {
    setSelectedPeriod(period);
    setAnchorEl(null);
  }

  useEffect(() => {
    if (filteredFunds.length === 0) return;
    let fundsTmp = [...filteredFunds];
    if (selectedPeriod === 'Son 1 Gün') fundsTmp.sort((a, b) => (b.lastDayReturn || 0) - (a.lastDayReturn || 0));
    if (selectedPeriod === 'Son 1 Hafta') fundsTmp.sort((a, b) => (b.lastWeekReturn || 0) - (a.lastWeekReturn || 0));
    if (selectedPeriod === 'Son 1 Ay') fundsTmp.sort((a, b) => (b.lastMonthReturn || 0) - (a.lastMonthReturn || 0));
    if (selectedPeriod === 'Son 3 Ay') fundsTmp.sort((a, b) => (b.lastThreeMonthsReturn || 0) - (a.lastThreeMonthsReturn || 0));
    if (selectedPeriod === 'Son 6 Ay') fundsTmp.sort((a, b) => (b.lastSixMonthsReturn || 0) - (a.lastSixMonthsReturn || 0));
    if (selectedPeriod === 'Son 1 Yıl') fundsTmp.sort((a, b) => (b.lastOneYearReturn || 0) - (a.lastOneYearReturn || 0));
    if (selectedPeriod === 'Yıl Başından İtibaren') fundsTmp.sort((a, b) => (b.lastBeginningOfYearReturn || 0) - (a.lastBeginningOfYearReturn || 0));
    if (selectedPeriod === 'Tüm Zamanlar') fundsTmp.sort((a, b) => (b.allTimeReturn || 0) - (a.allTimeReturn || 0));
    setFilteredFunds(fundsTmp);
  }, [selectedPeriod])

  useEffect(() => {
    if (portfolios) {
      let allFunds: Fon[] = [];
      portfolios.forEach(portfolio => {
        allFunds = allFunds.concat(portfolio.fons);
        if (portfolio.umbrella) {
          portfolio.umbrella.forEach(umbrella => {
            allFunds = allFunds.concat(umbrella.fons);
          })
        }
      });
      //lastdayreturn a göre büyükten küçüğe sırala
      if (selectedPeriod === 'Son 1 Gün') allFunds.sort((a, b) => (b.lastDayReturn || 0) - (a.lastDayReturn || 0));
      if (selectedPeriod === 'Son 1 Hafta') allFunds.sort((a, b) => (b.lastWeekReturn || 0) - (a.lastWeekReturn || 0));
      if (selectedPeriod === 'Son 1 Ay') allFunds.sort((a, b) => (b.lastMonthReturn || 0) - (a.lastMonthReturn || 0));
      if (selectedPeriod === 'Son 3 Ay') allFunds.sort((a, b) => (b.lastThreeMonthsReturn || 0) - (a.lastThreeMonthsReturn || 0));
      if (selectedPeriod === 'Son 6 Ay') allFunds.sort((a, b) => (b.lastSixMonthsReturn || 0) - (a.lastSixMonthsReturn || 0));
      if (selectedPeriod === 'Son 1 Yıl') allFunds.sort((a, b) => (b.lastOneYearReturn || 0) - (a.lastOneYearReturn || 0));
      if (selectedPeriod === 'Yıl Başından İtibaren') allFunds.sort((a, b) => (b.lastBeginningOfYearReturn || 0) - (a.lastBeginningOfYearReturn || 0));
      if (selectedPeriod === 'Tüm Zamanlar') allFunds.sort((a, b) => (b.allTimeReturn || 0) - (a.allTimeReturn || 0));
      setFunds(allFunds);
      setFilteredFunds(allFunds);
    }
  }, [portfolios])

  useEffect(() => {
    if (searchText) {
      let filtered = funds.filter(fund => fund.name.toLowerCase().includes(searchText.toLowerCase()) || fund.code.toLowerCase().includes(searchText.toLowerCase()));
      if (selectedPeriod === 'Son 1 Gün') filtered.sort((a, b) => (b.lastDayReturn || 0) - (a.lastDayReturn || 0));
      if (selectedPeriod === 'Son 1 Hafta') filtered.sort((a, b) => (b.lastWeekReturn || 0) - (a.lastWeekReturn || 0));
      if (selectedPeriod === 'Son 1 Ay') filtered.sort((a, b) => (b.lastMonthReturn || 0) - (a.lastMonthReturn || 0));
      if (selectedPeriod === 'Son 3 Ay') filtered.sort((a, b) => (b.lastThreeMonthsReturn || 0) - (a.lastThreeMonthsReturn || 0));
      if (selectedPeriod === 'Son 6 Ay') filtered.sort((a, b) => (b.lastSixMonthsReturn || 0) - (a.lastSixMonthsReturn || 0));
      if (selectedPeriod === 'Son 1 Yıl') filtered.sort((a, b) => (b.lastOneYearReturn || 0) - (a.lastOneYearReturn || 0));
      if (selectedPeriod === 'Yıl Başından İtibaren') filtered.sort((a, b) => (b.lastBeginningOfYearReturn || 0) - (a.lastBeginningOfYearReturn || 0));
      if (selectedPeriod === 'Tüm Zamanlar') filtered.sort((a, b) => (b.allTimeReturn || 0) - (a.allTimeReturn || 0));
      setFilteredFunds(filtered);
    } else {
      let filtered = funds;
      if(filtered.length === 0) return;
      if (selectedPeriod === 'Son 1 Gün') filtered.sort((a, b) => (b.lastDayReturn || 0) - (a.lastDayReturn || 0));
      if (selectedPeriod === 'Son 1 Hafta') filtered.sort((a, b) => (b.lastWeekReturn || 0) - (a.lastWeekReturn || 0));
      if (selectedPeriod === 'Son 1 Ay') filtered.sort((a, b) => (b.lastMonthReturn || 0) - (a.lastMonthReturn || 0));
      if (selectedPeriod === 'Son 3 Ay') filtered.sort((a, b) => (b.lastThreeMonthsReturn || 0) - (a.lastThreeMonthsReturn || 0));
      if (selectedPeriod === 'Son 6 Ay') filtered.sort((a, b) => (b.lastSixMonthsReturn || 0) - (a.lastSixMonthsReturn || 0));
      if (selectedPeriod === 'Son 1 Yıl') filtered.sort((a, b) => (b.lastOneYearReturn || 0) - (a.lastOneYearReturn || 0));
      if (selectedPeriod === 'Yıl Başından İtibaren') filtered.sort((a, b) => (b.lastBeginningOfYearReturn || 0) - (a.lastBeginningOfYearReturn || 0));
      if (selectedPeriod === 'Tüm Zamanlar') filtered.sort((a, b) => (b.allTimeReturn || 0) - (a.allTimeReturn || 0));
      setFilteredFunds(filtered);
    }
  }, [searchText])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.paper,
      }}
    >
      <PageView>
        <Box>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: {
                xl: '44px',  // Ekstra büyük ekranlar
                lg: '40px',  // Büyük ekranlar
                md: '36px',  // Orta büyük ekranlar
                sm: '32px',  // Küçük ekranlar (tablet)
                xs: '28px',  // Çok küçük ekranlar (mobil)
              },
              lineHeight: {
                xl: '53px',
                lg: '48px',
                md: '43px',
                sm: '38px',
                xs: '33px',
              },
              textAlign: 'center',
              color: 'rgba(28,27,31,1)',
              mb: 5,
            }}
          >
            Fon Getirileri
          </Typography>
          {/* <Typography
            sx={{
              fontWeight: 500,
              fontSize: {
                xl: '28px',
                lg: '26px',
                md: '24px',
                sm: '22px',
                xs: '20px',
              },
              lineHeight: {
                xl: '42px',
                lg: '38px',
                md: '34px',
                sm: '30px',
                xs: '26px',
              },
              textAlign: 'center',
              color: 'rgba(45,43,51,1)',
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras porta, ipsum in sagittis dictum, ipsum erat sodales eros, vehicula imperdiet nisi nisl eu magna.
          </Typography> */}
        </Box>
        <Paper
          elevation={0}
          sx={{
            borderRadius: '6px',
            border: '1px solid #E6E9ED',
            backgroundColor: 'white',
            mt: 3,
            mb: 8
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: matches ? 'row' : 'column',
              alignItems: 'center',
              gap: 3,
              borderBottom: '1px solid #E6E9ED',
              py: 3,
              px: 2
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Fon Arayın..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  border: 'none', // Kenarlığı kaldır
                  backgroundColor: '#F0F3F5', // Arkaplan rengi
                  borderRadius: '20px', // Hafif yuvarlak köşeler
                  '&:hover': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none', // Fare ile üzerine gelindiğinde kenarlık gösterme
                    },
                  },
                  '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none', // Odaklanıldığında kenarlık gösterme
                    }
                  }
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none', // Varsayılan durumda kenarlık gösterme
                },
                '& .MuiOutlinedInput-input': {
                  padding: '10px', // İç boşluk
                }
              }}
            />
            <Box
              sx={{
                display: 'flex',
                gap: 3,
              }}
            >
              {matches && (
                <>
                  <CustomButton
                    label="Son 1 Gün"
                    onClick={() => { setSelectedPeriod('Son 1 Gün') }}
                    selected={selectedPeriod === 'Son 1 Gün'}
                  />
                  <CustomButton
                    label="Son 1 Hafta"
                    onClick={() => { setSelectedPeriod('Son 1 Hafta') }}
                    selected={selectedPeriod === 'Son 1 Hafta'}
                  />
                  <CustomButton
                    label="Son 1 Ay"
                    onClick={() => { setSelectedPeriod('Son 1 Ay') }}
                    selected={selectedPeriod === 'Son 1 Ay'}
                  />
                </>
              )}
              <CustomButton
                label={matches ? selectedPeriod === 'Son 3 Ay' || selectedPeriod === 'Son 6 Ay' || selectedPeriod === 'Son 1 Yıl' || selectedPeriod === 'Yıl Başından İtibaren' || selectedPeriod === 'Tüm Zamanlar' ? selectedPeriod : "Dönem Seçin" : selectedPeriod ? selectedPeriod : "Dönem Seçin"}
                onClick={handleClick}
                endIcon={<ExpandMore />}
                selected={matches ? selectedPeriod === 'Son 3 Ay' || selectedPeriod === 'Son 6 Ay' || selectedPeriod === 'Son 1 Yıl' || selectedPeriod === 'Yıl Başından İtibaren' || selectedPeriod === 'Tüm Zamanlar' : Boolean(selectedPeriod)}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {!matches &&
                  <>
                    <MenuItem onClick={() => handleClickSelectedPeriod("Son 1 Gün")}>Son 1 Gün</MenuItem>
                    <MenuItem onClick={() => handleClickSelectedPeriod("Son 1 Hafta")}>Son 1 Hafta</MenuItem>
                    <MenuItem onClick={() => handleClickSelectedPeriod("Son 1 Ay")}>Son 1 Ay</MenuItem>
                  </>
                }
                <MenuItem onClick={() => handleClickSelectedPeriod("Son 3 Ay")}>Son 3 Ay</MenuItem>
                <MenuItem onClick={() => handleClickSelectedPeriod("Son 6 Ay")}>Son 6 Ay</MenuItem>
                <MenuItem onClick={() => handleClickSelectedPeriod("Son 1 Yıl")}>Son 1 Yıl</MenuItem>
                <MenuItem onClick={() => handleClickSelectedPeriod("Yıl Başından İtibaren")}>Yıl Başından İtibaren</MenuItem>
                <MenuItem onClick={() => handleClickSelectedPeriod("Tüm Zamanlar")}>Tüm Zamanlar</MenuItem>
              </Menu>
            </Box>
          </Box>
          <Box>
            <FundsIncomeTable
              funds={filteredFunds}
              selectedPeriod={selectedPeriod}
            />
          </Box>
        </Paper>
      </PageView>
    </Box>
  )
}

type CustomButtonProps = {
  label: string;
  onClick: (e: any) => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  selected?: boolean;
  sx?: any;
}

export function CustomButton({ label, onClick, startIcon, endIcon, selected, sx }: CustomButtonProps) {

  const theme = useTheme();

  return (
    <Box
      onClick={onClick}
      sx={{
        borderRadius: '30px',
        border: `1px solid ${selected ? theme.palette.primary.main : '#E6E9ED'}`,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        gap: 2,
        py: 1,
        px: 2,
        ...sx
      }}
    >
      {startIcon}
      <Typography
        noWrap
      >
        {label}
      </Typography>
      {endIcon}
    </Box>
  );
}
