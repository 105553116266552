import { Box, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Title from '../components/Title';
import PageView from '../components/PageView';
import StopageComponent from '../components/StopageComponent';

export default function StopagePage() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        gap: 8
      }}
    >
      <Title title='Stopaj Oranı Değişikliği' />
      <PageView
        sx={{
          display: 'flex',
          gap: '10px',
          mb: 22
        }}
      >
        <StopageComponent />
      </PageView>
    </Box>
  )
}
