import { Box, useMediaQuery, useTheme } from '@mui/material';
import arifBey from '../assets/arif-isfendiyaroglu.jpg';
import bernaHanim from '../assets/berna-sema-yigit-sevindi.jpg';
import inciHanim from '../assets/inci-aksun.jpg';
import aliBey from '../assets/ali-fuat-erbil.jpg';
import ibrahimBey from '../assets/ibrahim.jpg';
import boraBey from '../assets/bora-oruc.jpg';
import ManagersInfo from '../components/ManagersInfo';
import PageView from '../components/PageView';
import Title from '../components/Title';
import CommercialLeftBar from '../components/CommercialLeftBar';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import useSWR from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';

export default function ManagerCommunity() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { data: managersData, isLoading, error } = useSWR<any>(`${baseUrl}/contents/yonetim-kurulu`, (url: string) => fetcher(url));

  if (isLoading) {
    return <Box></Box>
  }

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        gap: 8
      }}
    >
      <Title title='Yönetim Kurulu' />
      <PageView
        sx={{
          display: 'flex',
          gap: '10px',
          mb: 22
        }}
      >
        {matches && (<CommercialLeftBar />)}
        <ManagersInfo
          managersData={managersData.content ? JSON.parse(managersData.content) : []}
        />
      </PageView>
    </Box>
  )
}
