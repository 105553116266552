
// lastDayReturn                     Float?
// lastWeekReturn                    Float?
// lastMonthReturn                   Float?
// lastThreeMonthsReturn             Float?
// lastSixMonthsReturn               Float?
// lastBeginningOfYearReturn         Float?
// allTimeReturn                     Float?

export type Fon = {
  id?: string;
  name: string;
  longName: string;
  code: string;
  description?: string;
  suggestedMaturationTime?: string;
  lastOneYearReturn?: number;
  lastDayReturn?: number;
  lastWeekReturn?: number;
  lastMonthReturn?: number;
  lastThreeMonthsReturn?: number;
  lastSixMonthsReturn?: number;
  lastBeginningOfYearReturn?: number;
  allTimeReturn?: number;
  logoUrl?: string;
  pricePerItem?: number;
  strategy?: string;
  fundDuration?: string;
  sellingTarget?: string;
  minimumInvestment?: string;
  fundStartDate?: string;
  exitTime?: string;
  fundEntryExitFees?: string;
  purchasingFundParticipationShares?: string;
  managementFee?: string;
  riskGrade?: number;
  informationFiles: any[];
  portfolioId?: number;
  portfolio?: Portfolio;
  umbrellaId?: number;
  umbrella?: Umbrella;
  stoppage?: string;
  eventStartTime?: string;
  valorTime?: string;
  lastEventTime?: string;
  fundBuyValor?: string;
  fundSellValor?: string;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
}

export type Umbrella = {
  id?: number;
  name: string;
  slug: string;
  priority: number;
  description?: string;
  fons: Fon[];
  portfolioId?: number;
  portfolio?: Portfolio;
  files: FileType[];
}

export type Portfolio = {
  id?: string;
  name: string;
  slug: string;
  priority: number;
  description?: string;
  displayDetailedMetrics?: boolean;
  fons: Fon[];
  umbrella: Umbrella[];
  faq?: any;
}

export type FileType = {
  id: number
  fieldname: string
  name: string
  originalname: string
  encoding: string
  mimetype: string
  destination: string
  filename: string
  path: string
  size: number
  createdAt: Date
  updatedAt: Date
}

export type Announcement = {
  id?: number
  createdAt: Date
  updatedAt: Date
  title: string
  message: string
  coverImage: string
  priority: number
  type: AnnouncementType
  files: FileType[]
  links: any
}

export type BannerItemType = {
  title: string;
  description: string;
  link: string;
  image?: string;
  buttonText: string;
  alignment: 'center' | 'flex-start' | 'flex-end';
}

export enum Role {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum AnnouncementType {
  BULTEN = 'BULTEN',
  DUYURU = 'DUYURU',
  BANNER = 'BANNER',
  FON = 'FON',
  BASIN = 'BASIN',
}



// enum AnnouncementType {
//   BULTEN
//   DUYURU
//   BANNER
// }