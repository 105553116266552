import { Box, Typography } from '@mui/material';
import GeneralInfoCard from './GeneralInfoCard';
import PageView from './PageView';
import useSWR from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import MarkdownViewer from './MarkdownViewer';

export default function PrivatePortfolioInformation() {

  const { data, isLoading, error } = useSWR<any>(`${baseUrl}/contents/ozel-portfoy-yonetimi`, (url: string) => fetcher(url));

  if (isLoading) return <></>;

  return (
    <Box>
      <PageView>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            py: 5,
            gap: 3,
          }}
        >
          <GeneralInfoCard
            title=""
            description={
              <MarkdownViewer text={data ? JSON.parse(data.content) : ''} />
            }
          />
        </Box>
      </PageView>
    </Box>
  );
}
