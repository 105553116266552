import { Box } from '@mui/material'
import React from 'react'
import BultenContainer from '../containers/BultenContainer'

export default function Bulten() {
  return (
    <Box>
      <BultenContainer />
    </Box>
  )
}
