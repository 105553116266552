import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import useSWR from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';

const accordionDatas = [
  {
    title: "Yatırım Fonu nedir?",
    content: "Yatırımcılardan katılma belgesi karşılığı toplanan birikimlerle oluşan portföyün, yatırımcılar hesabına, çeşitli para ve sermaye piyasası araçlarında profesyonel yöneticiler tarafından riskin dağıtılması ilkesi ve inançlı mülkiyet esaslarına göre yönetildiği mal varlıklarıdır."
  },
  {
    title: "Yatırım fonları hangi varlıklara yatırım yapabilir?",
    content: "Yatırım fonları; ters repo, devlet tahvili, hazine bonosu, hisse senedi, özel sektör tahvili ve finansman bonosu, yabancı menkul kıymetler, yatırım fonları, değerli madenler, vadeli sözleşmeler ve opsiyonlar gibi varlıklara yatırım yapabilmektedir."
  },
  {
    title: "Yatırım fonlarının avantajları nelerdir?",
    content: "Yatırım fonları, tasarrufların uzman ekipler tarafından yönetilmesine olanak sağlamaktadır. Küçük tasarruf sahiplerinin, yatırımlarında büyük yatırımcıların sahip olduğu yatırım avantajlarını elde etmelerini sağlamaktadır. Yatırım fonu portföyünde yer alan yatırım araçlarının çeşitliliği, riskin en aza indirilmesine olanak sağlamaktadır. Yatırımcıların yatırım tutarlarını diledikleri zaman nakde çevirebilmeleri sayesinde likit bir yatırım aracıdır."
  },
  {
    title: "Özel Portföy Yönetimi Nedir?",
    content: "Özel Portföy Yönetimi, bireysel ve kurumsal yatırımcılar için finansal varlıklardan oluşan portföylerin yüksek getiri hedefi ile vekil sıfatıyla yönetilmesi olarak tanımlanır."
  },
  {
    title: "Portföy yönetim ücreti nedir?",
    content: "Toplam portföy değeri üzerinden aylık olarak tahsil edilen yönetim ücretidir."
  },
  {
    title: "Performans ücreti nedir?",
    content: "Müşterilerimizin risk tercihlerine göre tespit edilen getiri hedefi (kıstas getiri/benchmark) üzerinde oluşan getirinin belli bir yüzdesinin portföy yönetim şirketi tarafından 3 aylık, 6 aylık ve yıllık aralıklarla tahsis edilmesidir."
  },
  {
    title: "Serbest Fon Nedir?",
    content: "Serbest Fon, nitelikli yatırımcıların yatırım yapabildiği, yönetim stratejisi ve yatırım araçları konusunda herhangi bir kısıtlamaya tabi olmayan fon türüdür. Serbest Fonlar, Türkiye’de ve yurt dışında sadece SPK’nın “nitelikli yatırımcı” tanımına uygun kişi ve kurumlara satılabilmektedir. Diğer yatırım fonlarından farklı olarak piyasaların her iki yönde de sunacağı fırsatlardan istifade etme (long/short) esnekliğine sahiptir. Tezgahüstü türev işlemler yapabilir, risk yönetimi açısından belli kısıtlamalara tabiidir. Katılma payları sadece nitelikli yatırımcılar tarafından alınabilir."
  },
  {
    title: "Nitelikli Yatırımcı Kimdir?",
    content: "Nitelikli yatırımcı, yatırım fonları, emeklilik yatırım fonları, menkul kıymetler yatırım ortaklıkları, girişim sermayesi yatırım ortaklıkları, gayrimenkul yatırım ortaklıkları, aracı kurumlar, bankalar, sigorta şirketleri, özel finans kurumları, portföy yönetim şirketleri, emekli ve yardım sandıkları, vakıflar, 506 sayılı Sosyal Sigortalar Kanununun geçici 20. maddesi uyarınca kurulmuş olan sandıklar, kamuya yararlı dernekler ile nitelikleri itibariyle bu kurumlara benzer olduğu Kurulca belirlenecek diğer yatırımcılar ve halka arz tarihi itibariyle en az 1 milyon Türk Lirası tutarında Türk ve/veya yabancı para ve sermaye piyasası aracına sahip olan gerçek ve tüzel kişileri ifade etmektedir."
  },
  {
    title: "TEFAS Nedir?",
    content: "TEFAS tek bir yatırım hesabıyla özel şartlara tabi olan yatırım fonları hariç olmak üzere, Sermaye Piyasası Kurulu’nun izniyle kurulmuş ve sermaye piyasasında faaliyette olan tüm yatırım fonlarına erişim imkânı sağlayan elektronik bir platformdur. İstanbul Takas ve Saklama Bankası A.Ş. (Takasbank) tarafından işletilen TEFAS, 9 Ocak 2015 tarihinde faaliyete geçmiştir."
  },
  {
    title: "Fonlar güvence altında mıdır?",
    content: "YATIRIM FONLARINA İLİŞKİN ESASLAR SPK TEBLİĞİ (III-52.1) ile düzenlenir. Fon kurucuları, riskin dağıtılması ve inançlı mülkiyet esaslarına göre yatırımcı haklarını koruyacak şekilde yönetim, temsil ve saklanmasından Sermaye Piyasası Kanunu’nca sorumludur. Fonlara ait tüm hesap hareketleri, Sermaye Piyasası Kurulu’nun denetimindedir. Ayrıca fonlar, bağımsız dış denetim firmalarınca da denetlenir. Fon varlığında bulunan menkul kıymetler, resmi takas ve saklama kuruluşu olan Takasbank A.Ş. güvencesinde saklanır. Üstelik tüm fonlar malvarlığının korunması kanunu ile güvence altına alınmıştır. Kanun uyarınca, fonu yöneten bir portföy şirketinin zor durumda kalması durumunda, SPK tarafından fonun yönetimi başka bir şirkete devredilebilir veya yatırımcıların fon payları satılarak paraları iade edilir. Tüm bunlar kanunlarla koruma altındadır."
  },
  {
    title: "Kimler Fon Kullanabilir?",
    content: `
    Ana faaliyet konusu yatırım fonlarının kurulması ve yönetimi olan portföy yönetim şirketleri fon kurabilirler. Kurucu, fonun, riskin dağıtılması ve inançlı mülkiyet esaslarına göre pay sahiplerinin haklarını koruyacak şekilde yönetim, temsil ve saklanmasından sorumludur. Portföyün kurucu tarafından yönetilmiyor olması bu sorumluluğu kaldırmaz.
    <br />
    <br />
    Kurucu mevzuat ve fon izahnamesinde belirtilen sınırlar içinde kalmak üzere fon portföyünü dilediği gibi oluşturabilmekte ve daha sonra değiştirebilmektedir.
    <br />
    <br />
    Kurucu, Sermaye Piyasası Kurulu'na, resmi dairelere, katılma payı sahiplerine ve fon adına üçüncü şahıslara karşı fonu, temsil eder.
    `
  },
  {
    title: "Foneria’nın Seçtikleri Nedir?(FONERİA uygulamamız üzerinden görüntüleyebilirsiniz)",
    content: `
    TEFAS’ta yer alan tüm fonları kendi varlık sınıflarında, sadece sayısal verileri baz alarak ve herhangi bir öznel değerlendirme olmaksızın sıralanmasıyla ortaya çıkar.
    <br />
    Foneria’nın Seçtikleri, içinde bulunduğu varlık sınıfının performansını en tutarlı şekilde temsil eden fonlardan oluşan seçkimizdir.
    <br />
    Foneria’nın Seçtikleri sadece sayısal kriterlere ve verilere göre belirlenir. Böylece bütün fonlar arasından her bir yatırım fonunun Foneria’nın Seçtikleri’nde yer alabilmesi için belli bir katılımcı sayısı, fon büyüklüğü ve minimum bir senedir piyasada olması şartları gerekir.
    <br />
    “Foneria’nın Seçtikleri”,yatırım tavsiyesi niteliği taşımaz, fonun gelecek potansiyeli hakkında bir görüş içermez.
    `
  },
  {
    title: "Foneria sadece bir bilgi platformu mu? Yoksa yatırım yapabilir miyim?",
    content: "Foneria Uygulaması kullanıcıları uygulamadaki tüm bilgi servislerine ulaşabilir. Yatırım yapmak isteyen kullanıcılar, uzaktan müşteri edinimi sayesinde tamamen dijital süreçle ve video görüşme ile Foneria Portföy Yönetimi A.Ş.’nin müşterisi olur; uygulamada hesap açılışı tamamlandığında yatırım yapmaya başlayabilir."
  }
];



export default function Faq() {
  const [expanded, setExpanded] = useState<number | false>(false);
  const theme = useTheme();

  const handleChange =
    (panel: number) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const { data: accordionData, isLoading, error } = useSWR<any>(`${baseUrl}/contents/sss`, (url: string) => fetcher(url));

  if (isLoading) return <></>;

  const sssData = accordionData ? JSON.parse(accordionData.content) : [];

  return (
    <Box sx={{ py: 4 }}>
      {sssData.map((data: any, index: number) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}
          sx={{
            mb: 3,
            background: 'white',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            '&:hover': {
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            },
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}
            sx={{
              '&:hover': {
                background: theme.palette.action.hover,
              },
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '35px',
                color: 'rgba(33,37,41,1)',
              }}
            >
              {data.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '30px',
                color: 'rgba(33,37,41,1)',
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: data.content }} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}
