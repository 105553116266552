import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from 'react';
import { getUser } from '../services/UserServices';
import { Portfolio, User } from '../utils/types';
import { baseUrl } from '../utils/constants';
import { getPortfolios } from '../services/AppServices';
import { debounce } from 'lodash';

type AuthContextProps = {
    children: React.ReactNode;
}

type AuthStateType = {
    token: string;
    exp?: Date;
    login: (token: string, refresh_token: string) => void,
    logout: () => void,
    getUserInfo: (token: string) => Promise<void>,
    user?: User
    refreshToken?: string
    initialAuthDone: boolean
    portfolios: Portfolio[];
}

const initialAuthState: AuthStateType = {
    token: "",
    exp: undefined,
    login: (token: string) => { },
    logout: () => { },
    getUserInfo: (token: string) => new Promise(() => { }),
    refreshToken: "",
    initialAuthDone: false,
    user: undefined,
    portfolios: []
}

export const AuthContext = React.createContext<AuthStateType>(initialAuthState);

function getExp(token: string) {
    var jsonPayload: { exp: number } = jwtDecode(token);
    return jsonPayload.exp;
};

function getId(token: string) {
    var jsonPayload: { sub: string } = jwtDecode(token);
    return jsonPayload.sub;
}

export default function AuthProvider({ children }: AuthContextProps) {

    const [token, setToken] = useState('');
    const [refreshToken, setRefreshToken] = useState('');
    const [initialAuthDone, setInitialAuthDone] = useState(false);
    const [exp, setExp] = useState<Date>();
    const [user, setUser] = useState<User>()
    const [loading, setLoading] = useState(false);
    const [portfolios, setPortfolios] = useState<Portfolio[]>([]);

    const sendSiteVisit = () => {
        //if localhost, don't send
        if (window.location.href.includes('localhost')) return;
        try {
            const res = fetch(`${baseUrl}/site-visit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
        } catch (err) {
            console.log(err)
        }
    }

    const sendSiteVisitDebounced = debounce(sendSiteVisit, 100);

    useEffect(() => {
        sendSiteVisitDebounced();
    }, [])

    const fetchPortfolios = async () => {
        try {
            setLoading(true);
            const res = await getPortfolios()
            if (res) {
                setPortfolios(res);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchPortfolios();
    }, [])

    const login = (token: string, refreshTkn?: string) => {
        setToken(token);
        setExp(new Date(getExp(token) * 1000));
        getUserInfo(token);
        refreshTkn && setRefreshToken(refreshTkn);
    }

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        setExp(undefined);
        setUser(undefined);
        setToken('');
        setRefreshToken('');
    }

    const getUserInfo = async (token: string) => {
        try {
            setLoading(true);
            const id = getId(token);
            const res = await getUser(+id, token) as User;
            if (res) {
                setUser(res);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
            logout();
        }
    }

    useEffect(() => {
        if (token) getUserInfo(token);
    }, [token])

    useEffect(() => {
        const tokenData = localStorage.getItem('token');
        const refreshTkn = localStorage.getItem('refreshToken');
        refreshTkn && setRefreshToken(refreshTkn);
        if (tokenData) {
            login(tokenData);
        }
        setInitialAuthDone(true);
    }, [])

    return (
        <AuthContext.Provider
            value={{
                exp,
                token,
                login,
                logout,
                user,
                getUserInfo,
                refreshToken,
                initialAuthDone,
                portfolios: portfolios,
            }}>
            {
                loading ?
                    <Box
                        sx={{
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <CircularProgress />
                    </Box> :
                    children
            }
        </AuthContext.Provider>
    );
}
