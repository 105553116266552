import { Box, useTheme } from '@mui/material'
import React from 'react'
import Title from '../components/Title'
import PageView from '../components/PageView'
import Faq from '../components/Faq'

export default function FaqPage() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
      }}
    >
      <Title title='Sık Sorulan Sorular' />
      <PageView>
        <Faq />
      </PageView>
    </Box>
  )
}