import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import Title from '../components/Title';
import PageView from '../components/PageView';
import UmbrellaListHeader from '../components/UmbrellaListHeader';
import Companies from '../components/Companies';
import Document from '../components/Document';

export default function UmbrellaFunds() {

  const theme = useTheme();
  const { type, id } = useParams<{ type: string, id: string }>();
  const { portfolios } = useContext(AuthContext);

  const umbrella = portfolios.find(portfolio => portfolio.slug === type)?.umbrella.find(um => um.id === +id!);

  if (!umbrella) {
    return <></>
  }

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        pb: 8
      }}>
      <Title title={umbrella.name || "Şemsiye Fon"} />
      <PageView
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
        }}
      >
        <UmbrellaListHeader
          umbrella={umbrella}
        />
        {umbrella.description && (
          <Accordion
            expanded={true}
            sx={{
              mb: 3,
              background: 'white'
            }}
          >
            <AccordionSummary
            >
              <Typography variant="h5"
                sx={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "35px",
                  color: "rgba(33,37,41,1)",
                }}>
                Açıklama
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "30px",
                  color: "rgba(33,37,41,1)",
                }}>
                {umbrella.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
        {umbrella.fons && (
          <Companies
            funds={umbrella.fons}
          />
        )}
        <Document
          documents={umbrella.files}
        />
      </PageView>
    </Box>
  )
}
