import { Box } from '@mui/material'
import React from 'react'
import { Button, TextField } from "@mui/material";
import { Editor } from "primereact/editor";
import { useState } from "react";

type Props = {
  handleSubmit: (values: any, type: string) => void;
  data: any
}

export default function PrivatePortfolioEditor({ handleSubmit, data }: Props) {

  const [text, setText] = useState(data || '');

  return (
    <Box>
      <Editor value={text} onTextChange={(e) => setText(e.htmlValue || '')} style={{ height: '320px' }} />
      <Button
        variant="contained"
        onClick={() => handleSubmit(text, 'ozel-portfoy-yonetimi')}
        sx={{
          mt: 2,
        }}
      >
        Kaydet
      </Button>
    </Box>
  )
}
