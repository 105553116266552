import { Paper } from '@mui/material';

import ManagerItem from './ManagerItem';
import PartnerShipTable from './PartnerShipTable';
import { useLocation } from 'react-router-dom';

type Props = {
  managersData: any[]
  hidePartnerShipTable?: boolean
}

export default function ManagersInfo({ managersData, hidePartnerShipTable }: Props) {

  return (
    <Paper
      sx={{
        background: 'rgba(255, 255, 255, 1)',
        margin: '0 auto',
        width: '100%',
      }}
    >
      {managersData.map((data, index) => (
        <ManagerItem data={data} key={index} />
      ))}
      {!hidePartnerShipTable && <PartnerShipTable />}
    </Paper>
  );
}
