import { Box, Paper, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import LoginForm from '../components/LoginForm'
import { AuthContext } from '../contexts/AuthContext';
import { loginUser } from '../services/AuthServices';
import { useAlert } from '../utils/useAlert';
import { useNavigate } from 'react-router-dom';

export default function LoginContainer() {

  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const nav = useNavigate();
  const showSnackbar = useAlert();

  const handleSubmit = async (email: string, password: string) => {
    try {
      setLoading(true);
      const res = await loginUser({ email, password });
      localStorage.setItem('token', res.access_token);
      localStorage.setItem('refreshToken', res.refresh_token);
      login(res.access_token, res.refresh_token);
      setLoading(false);
      showSnackbar('Giriş başarılı', 'success');
      nav('/admin');
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      showSnackbar(error.message, 'error');
      // showAlert(error.message, 'error');
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 'calc(100vh - 64px)',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 500,
          width: '100%',
          m: 2,
        }}
      >
        <h1>Giriş</h1>
        <LoginForm handleSubmit={handleSubmit} loading={loading} />
      </Paper>
    </Box>
  )
}
