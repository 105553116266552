import React, { useContext } from 'react'
import { FileType, Fon } from '../utils/types';
import { Box, Button, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { deleteFile, uploadFile } from '../services/AppServices';
import { AuthContext } from '../contexts/AuthContext';
import AddNewFilePaper from '../components/AddNewFilePaper';
import { Delete } from '@mui/icons-material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { baseUrl } from '../utils/constants';
import DeleteDialog from '../components/DeleteDialog';
import { useAlert } from '../utils/useAlert';

type AddInformationFilesProps = {
  fund: Fon;
  fetchFund: () => void;
}

export default function AddInformationFiles({ fund, fetchFund }: AddInformationFilesProps) {

  const [newFileActive, setNewFileActive] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<File | null>(null);
  const { token } = useContext(AuthContext)
  const [fileName, setFileName] = React.useState<string>('');
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [deletingFile, setDeletingFile] = React.useState<FileType | null>(null);
  const [uploading , setUploading] = React.useState<boolean>(false);
  const showSnackbar = useAlert();

  const handleDelete = async (file: FileType) => {
    try {
      setDeleting(true);
      const res = await deleteFile(file.id!, token);
      fetchFund();
      showSnackbar('Dosya silindi', 'success');
    } catch (error) {
      showSnackbar('Dosya silinirken bir hata oluştu', 'error');
      console.log(error);
    } finally {
      setDeleting(false);
      setDeleteDialogOpen(false);
    }
  }

  const handleFileUpload = async () => {
    setUploading(true);
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', fileName);
    formData.append('type', 'fundInformation');
    formData.append('fonId', fund.id!);

    try {
      const res = await uploadFile(formData, token);
      setNewFileActive(false);
      fetchFund();
      showSnackbar('Dosya yüklendi', 'success');
    } catch (error) {
      console.log(error);
      showSnackbar('Dosya yüklenirken bir hata oluştu', 'error');
    } finally {
      setUploading(false);
    }
  }

  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    setFile(file);
  }

  return (
    <Box>
      <Typography
        sx={{
          mt: 2,
          fontSize: '1.5rem',
          fontWeight: 700,
        }}
      >
        Fon Bilgilendirme Dokümanları
      </Typography>
      <Divider />
      <List>
        {fund.informationFiles?.map((file: FileType, index: number) => (
          <>
            <ListItem>
              <ListItemText primary={file.name} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    setDeleteDialogOpen(true);
                    setDeletingFile(file);
                  }}
                >
                  <Delete />
                </IconButton>
                <IconButton
                  onClick={() => {
                    window.open(`${baseUrl}/file/${file.id}`, '_blank');
                  }}
                >
                  <FileOpenIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {index !== fund.informationFiles.length - 1 && <Divider />}
          </>
        ))}
      </List>
      <Box>
        {newFileActive && (
          <AddNewFilePaper
            file={file}
            fileName={fileName}
            setFileName={setFileName}
            handleFileUpload={handleFileUpload}
            handleFileSelect={handleFileSelect}
            uploading={uploading}
          />
        )}
      </Box>
      <Box>
        <Button
          onClick={() => {
            setNewFileActive(!newFileActive);
            setFile(null);
            setFileName('');
          }}
          variant='outlined'
          sx={{
            mt: 2
          }}
        >
          {newFileActive ? 'İptal' : 'Yeni Dosya Ekle'}
        </Button>
      </Box>
      <DeleteDialog
        title='Dosya Sil'
        description='Dosyayı silmek istediğinize emin misiniz?'
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          if (deletingFile) {
            handleDelete(deletingFile);
          }
        }}
        deleting={deleting}
      />
    </Box>
  )
}
