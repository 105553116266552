import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import PortfolioCardItem from '../components/PortfolioCardItem';
import { AuthContext } from '../contexts/AuthContext';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { Fon, Portfolio, Role } from '../utils/types';
import FundCard from '../components/FundCard';
import FundCardItem from '../components/FundCardItem';

export default function AdminFundList() {

  const { token, user } = useContext(AuthContext);
  const [selectedPortfolio, setSelectedPortfolio] = useState<string>('Hepsi');
  const [filteredFunds, setFilteredFunds] = useState<Fon[]>([]);
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);

  const { data: funds, isLoading, error } = useSWR<Fon[]>(`${baseUrl}/fon`, (url: string) => fetcher(url, token));
  const nav = useNavigate();

  useEffect(() => {
    let portfoliosData: Portfolio[] = [];
    funds?.forEach(fund => {
      const portfolio = portfoliosData.find(portfolio => portfolio?.id === fund.portfolioId);
      if (!portfolio && fund.portfolio) {
        portfoliosData.push(fund.portfolio)
      }
    })
    setPortfolios(portfoliosData);
  }, [funds])

  useEffect(() => {
    if (selectedPortfolio === 'Hepsi') {
      setFilteredFunds(funds || []);
    } else if (selectedPortfolio === 'uncategorized') {
      setFilteredFunds(funds?.filter(fund => !fund.portfolioId) || []);
    } else {
      setFilteredFunds(funds?.filter(fund => fund.portfolioId === portfolios.find(portfolio => portfolio.name === selectedPortfolio)?.id) || []);
    }
  }, [selectedPortfolio, funds])

  return (
    <Box
      sx={{
        width: '100%',
        m: 2,
      }}
    >
      {isLoading && <div>Yükleniyor...</div>}
      {error && <div>Hata oluştu</div>}
      {funds?.length === 0 && <div>Fon bulunamadı</div>}
      {(filteredFunds && filteredFunds?.length > 0) && (
        <Box>
          <Box
            sx={{
              width: '200px',
              pb: 2
            }}
          >
            <FormControl
              sx={{
                width: '100%',
              }}
            >
              <InputLabel id="demo-simple-select-label">Portföy</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPortfolio}
                label="Portföy"
                onChange={(e) => setSelectedPortfolio(e.target.value as string)}
              >
                <MenuItem value={"Hepsi"}>Hepsi</MenuItem>
                {portfolios?.map(portfolio => (
                  <MenuItem value={portfolio.name}>{portfolio.name}</MenuItem>
                ))}
                <MenuItem value={"uncategorized"}>Kategorize Edilmemiş</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Grid container spacing={2}>
            {filteredFunds.map((fund) => (
              <Grid item xs={12} sm={6} md={4} key={fund.id}>
                <FundCardItem fund={fund} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {(user?.role === Role.ADMIN || user?.role === Role.SUPERADMIN) && (
        <Fab
          onClick={() => { nav('/admin/fonlar/yeni') }}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
            color: 'white',
            backgroundColor: 'primary.main',
            '&:hover': {
              backgroundColor: 'primary.main',
            },
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </Box>
  )
}
