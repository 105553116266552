import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Box } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  indexAxis: 'y' as const,
  scales: {
    x: {
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Toplam Sayfa Görüntülemeleri',
    },
  },
};

export default function BarChart({ pageVisit }: { pageVisit: { pageName: string, count: number }[] }) {


  const colors = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(255, 99, 132, 0.2)',
    'rgba(22, 160, 133, 0.2)',
    'rgba(241, 196, 15, 0.2)',
    'rgba(211, 84, 0, 0.2)',
    'rgba(41, 128, 185, 0.2)',
    'rgba(142, 68, 173, 0.2)',
    'rgba(44, 62, 80, 0.2)',
    'rgba(243, 156, 18, 0.2)',
    'rgba(211, 84, 0, 0.2)',
    'rgba(189, 195, 199, 0.2)',
    'rgba(127, 140, 141, 0.2)',
    'rgba(22, 160, 133, 0.2)',
    'rgba(39, 174, 96, 0.2)',
    'rgba(41, 128, 185, 0.2)',
    'rgba(231, 76, 60, 0.2)'
  ]


  const data = {
    labels: pageVisit.map(pv => pv.pageName),
    datasets: [
      {
        label: 'Sayfa Görüntüleme',
        data: pageVisit.map(pv => pv.count),
        backgroundColor: pageVisit.map((pv, index) => colors[index % 20]),
        borderColor: pageVisit.map((pv, index) => colors[index % 20]),
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box
      sx={{
        width: 'calc(100% - 16px)',
      }}
    >
      <Bar
        height={pageVisit.length * 20}
        data={data}
        options={options}
      />
    </Box>
  );
}
