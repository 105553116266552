import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from '@mui/lab';

type Props = {
  file: File | null;
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  handleFileUpload: () => void;
  handleFileSelect: (e: any) => void;
  uploading?: boolean;
}

export default function AddNewFilePaper({ uploading, file, fileName, setFileName, handleFileUpload, handleFileSelect }: Props) {
  return (
    <Paper
      sx={{
        background: 'rgba(255, 255, 255, 1)',
        margin: '0 auto',
        padding: 2,
        mt: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          width: '100%',
          gap: 2
        }}
      >
        <Box
          sx={{
            flex: 1
          }}
        >
          <TextField
            label="Dosya Adı"
            name="name"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{
              mt: 2
            }}
          />
          <Typography
            sx={{
              mt: 2
            }}
          >
            {file?.name}
          </Typography>
        </Box>

        {!file ? (<Button
          variant="contained"
          component="label"
          color="primary"
          sx={{
            mt: 2,
            minWidth: 170
          }}
          startIcon={<CloudUploadIcon />}
        >
          Dosya Seç
          <input type="file" hidden onChange={handleFileSelect} />
        </Button>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              alignItems: 'center',
              mt: 2
            }}
          >
            <LoadingButton
              loading={uploading}
              onClick={handleFileUpload}
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                minWidth: 170
              }}
            >
              Yükle
            </LoadingButton>
          </Box>
        )}
      </Box>
    </Paper>
  )
}
