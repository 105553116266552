import { ExpandMore } from '@mui/icons-material';
import { Box, Grid, Menu, MenuItem, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import GaugeChart from 'react-gauge-chart';
import { Fon } from '../utils/types';
import { CustomButton } from './FundsIncome';
import FundsIncomeTable from './FundsIncomeTable';
type Props = {
  fund: Fon
}

export default function FundCharts({ fund }: Props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedPeriod, setSelectedPeriod] = React.useState<string>('Son 1 Gün');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSelectedPeriod = (period: string) => {
    setSelectedPeriod(period);
    setAnchorEl(null);
  }

  return (
    <Paper
      sx={{
        background: 'rgba(255, 255, 255, 1)',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <GaugeChart
              id="gauge-chart1"
              nrOfLevels={7}
              colors={["green", "red"]}
              arcWidth={0.3}
              percent={fund.riskGrade! / 10}
              hideText={true}
              style={{
                maxWidth: '200px',
              }}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '35px',
                color: 'rgba(28,27,31,1)',
              }}
            >
              Risk Değeri: {fund.riskGrade}
            </Typography>
            {/*  <Box>
              {Boolean(fund.lastDayReturn) && (
                <Typography>
                  <span style={{ fontWeight: 'bold', width: 450 }}>Son 1 Gün Getirisi:</span> {(fund.lastDayReturn! * 100).toFixed(7)}%
                </Typography>
              )}
              {Boolean(fund.lastWeekReturn) && (
                <Typography>
                  <strong>Son 1 Hafta Getirisi:</strong> {(fund.lastWeekReturn! * 100).toFixed(7)}%
                </Typography>
              )}
              {Boolean(fund.lastMonthReturn) && (
                <Typography>
                  <strong>Son 1 Ay Getirisi:</strong> {(fund.lastMonthReturn! * 100).toFixed(7)}%
                </Typography>
              )}
              {Boolean(fund.lastThreeMonthsReturn) && (
                <Typography>
                  <strong>Son 3 Ay Getirisi:</strong> {(fund.lastThreeMonthsReturn! * 100).toFixed(7)}%
                </Typography>
              )}
              {Boolean(fund.lastSixMonthsReturn) && (
                <Typography>
                  <strong>Son 6 Ay Getirisi:</strong> {(fund.lastSixMonthsReturn! * 100).toFixed(7)}%
                </Typography>
              )}
              {Boolean(fund.lastBeginningOfYearReturn) && (
                <Typography>
                  <strong>Yılbaşından İtibaren Getiri:</strong> {(fund.lastBeginningOfYearReturn! * 100).toFixed(7)}%
                </Typography>
              )}
              {Boolean(fund.lastOneYearReturn) && (
                <Typography>
                  <strong>Son 1 Yıl Getirisi:</strong> {(fund.lastOneYearReturn! * 100).toFixed(7)}%
                </Typography>
              )}
            </Box> */}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 2,
              height: '100%',
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '22px',
                lineHeight: '35px',
                color: 'rgba(28,27,31,1)',
              }}
            >
              RİSK DEĞERLERİ:
            </Typography>
            <Typography>
              Fonların volatilitesi dikkate alınarak, haftalık getirilerkullanılmak suretiyle hesaplanır.
            </Typography>
            <Typography>
              En az risk 1, en fazla risk 7 olmak üzere risk değeri1 - 7 arasındadır.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            sx={{
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 3,
              }}
            >
              {matches && (
                <>
                  <CustomButton
                    label="Son 1 Gün"
                    onClick={() => { setSelectedPeriod('Son 1 Gün') }}
                    selected={selectedPeriod === 'Son 1 Gün'}
                  />
                  <CustomButton
                    label="Son 1 Hafta"
                    onClick={() => { setSelectedPeriod('Son 1 Hafta') }}
                    selected={selectedPeriod === 'Son 1 Hafta'}
                  />
                  <CustomButton
                    label="Son 1 Ay"
                    onClick={() => { setSelectedPeriod('Son 1 Ay') }}
                    selected={selectedPeriod === 'Son 1 Ay'}
                  />
                </>
              )}
              <CustomButton
                label={matches ? selectedPeriod === 'Son 3 Ay' || selectedPeriod === 'Son 6 Ay' || selectedPeriod === 'Son 1 Yıl' || selectedPeriod === 'Yıl Başından İtibaren' || selectedPeriod === 'Tüm Zamanlar' ? selectedPeriod : "Dönem Seçin" : selectedPeriod ? selectedPeriod : "Dönem Seçin"}
                onClick={handleClick}
                endIcon={<ExpandMore />}
                selected={matches ? selectedPeriod === 'Son 3 Ay' || selectedPeriod === 'Son 6 Ay' || selectedPeriod === 'Son 1 Yıl' || selectedPeriod === 'Yıl Başından İtibaren' || selectedPeriod === 'Tüm Zamanlar' : Boolean(selectedPeriod)}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {!matches &&
                  <>
                    <MenuItem onClick={() => handleClickSelectedPeriod("Son 1 Gün")}>Son 1 Gün</MenuItem>
                    <MenuItem onClick={() => handleClickSelectedPeriod("Son 1 Hafta")}>Son 1 Hafta</MenuItem>
                    <MenuItem onClick={() => handleClickSelectedPeriod("Son 1 Ay")}>Son 1 Ay</MenuItem>
                  </>
                }
                <MenuItem onClick={() => handleClickSelectedPeriod("Son 3 Ay")}>Son 3 Ay</MenuItem>
                <MenuItem onClick={() => handleClickSelectedPeriod("Son 6 Ay")}>Son 6 Ay</MenuItem>
                <MenuItem onClick={() => handleClickSelectedPeriod("Son 1 Yıl")}>Son 1 Yıl</MenuItem>
                <MenuItem onClick={() => handleClickSelectedPeriod("Yıl Başından İtibaren")}>Yıl Başından İtibaren</MenuItem>
                <MenuItem onClick={() => handleClickSelectedPeriod("Tüm Zamanlar")}>Tüm Zamanlar</MenuItem>
              </Menu>
            </Box>
            <FundsIncomeTable
              funds={[fund]}
              selectedPeriod={selectedPeriod}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
