import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import React from 'react'

type AddUserDialogProps = {
  open: boolean;
  onClose: () => void;
  handleSubmit: (values: any) => void;
  loading?: boolean;
}

export default function AddUserDialog({ open, onClose, handleSubmit, loading }: AddUserDialogProps) {


  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleClose = () => {
    onClose();
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>
        <Typography>
          Kullanıcı Ekle
        </Typography>
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit({
            firstName,
            lastName,
            email,
            password,
          });
          setFirstName('');
          setLastName('');
          setEmail('');
          setPassword('');
        }}
      >
        <DialogContent>
          <Box>
            <TextField
              required
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              label="Ad"
              name="firstName"
              variant="outlined"
              margin="normal"
            />
            <TextField
              required
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              label="Soyad"
              name="lastName"
              variant="outlined"
              margin="normal"
            />
            <TextField
              required
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              name="email"
              variant="outlined"
              margin="normal"
            />
            <TextField
              required
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Şifre"
              name="password"
              variant="outlined"
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>İptal</Button>
          <LoadingButton loading={loading} variant='contained' type="submit">Ekle</LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
