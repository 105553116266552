import { Button, TextField } from "@mui/material";
import { Editor } from "primereact/editor";
import { useState } from "react";

type Props = {
  handleSubmit: (values: any, type: string) => void;
  data: any;
}

export default function AboutEditor({ handleSubmit, data }: Props) {

  const [text, setText] = useState(data.text || '');
  const [title, setTitle] = useState(data.title || '');

  return (
    <div>
      <TextField
        label="Başlık"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Editor value={text} onTextChange={(e) => setText(e.htmlValue || '')} style={{ height: '320px' }} />
      <Button
        variant="contained"
        onClick={() => handleSubmit({ title, text }, 'hakkimizda')}
        sx={{
          mt: 2,
        }}
      >
        Kaydet
      </Button>
    </div>
  )
}
