import { Box, Fab } from '@mui/material'
import React, { useContext } from 'react'
import AdminUsersContainer from '../containers/AdminUsersContainer'
import { AuthContext } from '../contexts/AuthContext';
import { Role } from '../utils/types';
import AddIcon from '@mui/icons-material/Add';
import { registerUser } from '../services/AuthServices';
import { useAlert } from '../utils/useAlert';
import { mutate } from 'swr';
import { baseUrl } from '../utils/constants';
import AddUserDialog from '../components/AddUserDialog';

export default function AdminUsers() {

  const { user } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const showSnackbar = useAlert();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const res = await registerUser(values);
      showSnackbar('Kullanıcı başarıyla oluşturuldu', 'success');
      mutate(`${baseUrl}/users`);
    } catch (error: any) {
      console.log(error);
      showSnackbar(error.message, 'error');
    } finally {
      setOpen(false);
      setLoading(false);
    }
  }

  return (
    <Box>
      <AdminUsersContainer />
      {user?.role === Role.SUPERADMIN && (
        <Fab
          onClick={() => { setOpen(true) }}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
            color: 'white',
            backgroundColor: 'primary.main',
            '&:hover': {
              backgroundColor: 'primary.main',
            },
          }}
        >
          <AddIcon />
        </Fab>
      )}
      <AddUserDialog open={open} onClose={() => setOpen(false)} handleSubmit={handleSubmit} loading={loading} />
    </Box>
  )
}
