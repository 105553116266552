import React from 'react'
import { Fon } from '../utils/types'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

type Props = {
  fund: Fon
}

export default function FundCardItem({ fund }: Props) {

  const nav = useNavigate();

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: 2,
        }
      }}
      onClick={() => { nav(`/admin/fonlar/${fund.id}/duzenle`) }}
    >
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {fund.name} - {fund.code}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Portföy:  {fund.portfolio?.name}
        </Typography>
      </CardContent>
    </Card>
  )
}
