import { Paper, Typography } from '@mui/material'
import React from 'react'
import { Announcement } from '../utils/types'
import { dateFormat } from '../utils/utils';
import { useNavigate } from 'react-router-dom';

type Props = {
  announcement: Announcement;
  link: string
}

export default function AnnouncementItem({ announcement,link }: Props) {
  const nav = useNavigate();
  return (
    <Paper
      onClick={() => nav(link)}
      sx={{
        p: 2,
        width: '100%',
        cursor: 'pointer',
      }}
    >
      <Typography
        sx={{
          fontWeight: 'bold'
        }}
      >
        {announcement.title} - {dateFormat(announcement.createdAt.toString())}
      </Typography>
      <Typography>
        {announcement.message}
      </Typography>
    </Paper>
  )
}
