import { Box, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import Document from '../components/Document';
import FundCharts from '../components/FundCharts';
import FundStrategy from '../components/FundStrategy';
import Funds from '../components/Funds';
import Title from '../components/Title';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import PageView from '../components/PageView';

export default function InvestmentFund() {

    const theme = useTheme();
    const { type, code, id } = useParams<{ type: string, code: string, id: string }>();
    const { portfolios } = useContext(AuthContext);

    const fund =
        id ? portfolios.find(portfolio => portfolio.slug === type)?.umbrella.find(um => um.id === +id!)?.fons.find(fon => fon.code === code || fon.id?.toString() === code?.toString()) :
            portfolios.find(portfolio => portfolio.slug === type)?.fons.find(fon => fon.code === code || fon.id?.toString() === code?.toString());

    if (!fund) {
        return <></>
    }

    return (
        <Box
            sx={{
                background: theme.palette.background.paper,
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                pb: 8
            }}>
            <Title title={fund.name || "Yatırım Fonu"} />
            <PageView
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                }}
            >
                <Funds
                    fund={fund}
                />
                <FundStrategy
                    fund={fund}
                />
                {Boolean(fund.riskGrade) && (
                    <FundCharts
                        fund={fund}
                    />
                )}
                <Document
                    documents={fund.informationFiles}
                />
            </PageView>

            {/* <SimilarFunds /> */}
        </Box>
    )
}
