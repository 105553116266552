import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Announcement } from '../utils/types';
import { dateFormat } from '../utils/utils';
import { baseUrl } from '../utils/constants';
import infoIcon from '../assets/info-icon.svg';
import linkIcon from '../assets/link-icon.svg';
import { NotificationsActive } from '@mui/icons-material';
interface NotificationItemProps {
  notification: Announcement;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ notification }) => {
  const { title, createdAt, files, message, links } = notification;

  return (
    <Box
      sx={{
        border: '1px solid #F0F0F0',
        backgroundColor: 'white',
        borderRadius: '8px',
        height: '100%',
        padding: 2,
        gap: 1,
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <NotificationsActive sx={{ height: 32, width: 32 }} />
          <Box>
            <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#333' }}>
              {title}
            </Typography>
            <Typography color="text.secondary" sx={{ fontSize: 12, fontWeight: 500 }}>
              {dateFormat(createdAt.toString())}
            </Typography>
          </Box>
        </Box>
        <Typography color="text.secondary" sx={{ fontSize: 12, fontWeight: 500, my: 2 }}>
          {message}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          flexWrap: 'wrap',
        }}
      >
        {links && Object.keys(links).map((link) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <img
              src={linkIcon}
              alt="link"
              style={{ height: '16px' }}
            />
            <a
              href={links[link].url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: '12px',
                fontWeight: 500,
                color: '#333',
              }}
            >
              {links[link].title}
            </a>
          </Box>
        ))}
         
        {files?.map((file) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <img
              src={linkIcon}
              alt="link"
              style={{ height: '16px' }}
            />
            <a
              href={`${baseUrl}/file/${file.id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: '12px',
                fontWeight: 500,
                color: '#333',
              }}
            >
              {file.name}
            </a>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default NotificationItem;
