import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import AboutEditor from '../components/AboutEditor';
import ManagersCommunityEditor from '../components/ManagersCommunityEditor';
import PrivatePortfolioEditor from '../components/PrivatePortfolioEditor';
import SssEditor from '../components/SssEditor';
import InformationFormEditor from '../components/InformationFormEditor';
import KvkkEditor from '../components/KvkkEditor';
import FinancialInfoEditor from '../components/FinancialInfoEditor';
import { createContentData } from '../services/AppServices';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import useSWR from 'swr';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { title } from 'process';
import { useAlert } from '../utils/useAlert';

export default function AdminData() {

  const { type } = useParams();
  const { token } = useContext(AuthContext);
  const showSnackbar = useAlert();

  const { data, isLoading, error } = useSWR<any>(`${baseUrl}/contents/${type}`, (url: string) => fetcher(url, token));

  const getTitle = () => {
    switch (type) {
      case 'hakkimizda':
        return 'Hakkımızda';
      case 'yonetim-kurulu':
        return 'Yönetim Kurulu';
      case 'yoneticilerimiz':
        return 'Yöneticilerimiz';
      case 'ozel-portfoy-yonetimi':
        return 'Özel Portföy Yönetimi';
      case 'sss':
        return 'Sıkça Sorulan Sorular';
      case 'surekli-bilgilendirme-formu':
        return 'Sürekli Bilgilendirme Formu';
      case 'kvkk':
        return 'KVKK';
      case 'acil-durum-eylem-plani':
        return 'Acil Durum Eylem Planı';
      case 'yasal-uyari':
        return 'Yasal Uyarı';
      case 'finansal-bilgiler':
        return 'Faaliyet ve Finansal Raporlar';
      default:
        return 'Portföyler';
    }
  }

  const handleSubmit = async (values: any, type: string) => {
    try {
      const res = await createContentData(token, {
        type,
        content: values
      });
      showSnackbar('İçerik başarıyla güncellendi.', 'success')
    } catch (err) {
      console.log(err);
      showSnackbar('İçerik güncellenirken bir hata oluştu.', 'error')
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Box
      sx={{
        width: '100%',
        p: 2,
      }}
    >
      <Typography variant="h4" gutterBottom>
        {getTitle()}
      </Typography>

      {type === 'hakkimizda' && (
        <AboutEditor handleSubmit={handleSubmit} data={data.content ? JSON.parse(data.content) : { title: '', text: '' }} />
      )}

      {type === 'yonetim-kurulu' && (
        <ManagersCommunityEditor
          handleSubmit={handleSubmit}
          type='yonetim-kurulu'
          data={data.content ? JSON.parse(data.content) : undefined}
        />
      )}

      {type === 'yoneticilerimiz' && (
        <ManagersCommunityEditor
          handleSubmit={handleSubmit}
          type='yoneticilerimiz'
          data={data.content ? JSON.parse(data.content) : undefined}
        />
      )}

      {type === 'ozel-portfoy-yonetimi' && (
        <PrivatePortfolioEditor
          handleSubmit={handleSubmit}
          data={data.content ? JSON.parse(data.content) : undefined}
        />
      )}

      {type === 'sss' && (
        <SssEditor
          handleSubmit={handleSubmit}
          data={data.content ? JSON.parse(data.content) : undefined}
        />

      )}

      {type === 'surekli-bilgilendirme-formu' && (
        <InformationFormEditor
          handleSubmit={handleSubmit}
          data={data.content ? JSON.parse(data.content) : undefined}
        />
      )}

      {type === 'kvkk' && (
        <KvkkEditor
          handleSubmit={handleSubmit}
          type='kvkk'
          data={data.content ? JSON.parse(data.content) : undefined}
        />
      )}

      {type === 'acil-durum-eylem-plani' && (
        <KvkkEditor
          handleSubmit={handleSubmit}
          type='acil-durum-eylem-plani'
          data={data.content ? JSON.parse(data.content) : undefined}
        />
      )}

      {type === 'yasal-uyari' && (
        <KvkkEditor
          handleSubmit={handleSubmit}
          type='yasal-uyari'
          data={data.content ? JSON.parse(data.content) : undefined}
        />
      )}

      {type === 'finansal-bilgiler' && (
        <FinancialInfoEditor
          handleSubmit={handleSubmit}
          reportsData={data.content ? JSON.parse(data.content) : undefined}
        />
      )}

    </Box>
  )
}
