import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import PageView from './PageView';
import HomePortfolioItem from './HomePortfolioItem';
import { useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';

export default function Portfoy() {

  const theme = useTheme();

  const { portfolios } = useContext(AuthContext);
  const [expanded, setExpanded] = useState('');

  const onClick = (slug: string) => {
    if (expanded === slug) setExpanded('');
    else setExpanded(slug);
  }

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        mt: -8,
        py: 10
      }}>
      <PageView>
        <Box>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: {
                xl: '44px',  // Ekstra büyük ekranlar
                lg: '40px',  // Büyük ekranlar
                md: '36px',  // Orta büyük ekranlar
                sm: '32px',  // Küçük ekranlar (tablet)
                xs: '28px',  // Çok küçük ekranlar (mobil)
              },
              lineHeight: {
                xl: '53px',
                lg: '48px',
                md: '43px',
                sm: '38px',
                xs: '33px',
              },
              textAlign: 'center',
              color: 'rgba(28,27,31,1)',
              mb: 5,
            }}
          >
            Foneria Porföy Yatırım Fonlarımız
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: {
                xl: '28px',
                lg: '26px',
                md: '24px',
                sm: '22px',
                xs: '20px',
              },
              lineHeight: {
                xl: '42px',
                lg: '38px',
                md: '34px',
                sm: '30px',
                xs: '26px',
              },
              textAlign: 'center',
              color: 'rgba(45,43,51,1)',
            }}
          >
            Yatırım tercihlerini en doğru şekilde belirlediğimiz müşterilerimize, yatırım tercihlerine göre en uygun ve yüksek getiri sağlayan fonları kolayca erişebilecekleri şekilde sunmaktır.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
            mt: 5
          }}>
          {portfolios.map((item) => (
            <HomePortfolioItem
              portfolio={item}
              open={expanded === item.slug}
              onClick={() => {onClick(item.slug)}}
            />
          ))}
        </Box>
      </PageView>
    </Box>
  );
}
