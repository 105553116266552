import { Divider, List, ListItem, ListItemButton, ListItemText, Paper, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function CommercialLeftBar() {

  const theme = useTheme();
  const loc = useLocation();
  const nav = useNavigate();

  return (
    <Paper
      sx={{
        minWidth: 250,
        background: 'rgba(255, 255, 255, 1)',
        margin: '0 auto'
      }}
    >
      <Typography
        sx={{
          fontWeight: '700',
          fontSize: '25px',
          lineHeight: '60px',
          color: theme.palette.primary.main,
          textAlign: 'center',
        }}
      >
        Kurumsal
      </Typography>
      <Divider />
      <List
        sx={{
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          //selected background color
          '& .Mui-selected': {
            backgroundColor: 'rgba(247, 247, 250, 1)',
            color: theme.palette.primary.main,
            borderRadius: '10px',
          },
          //hover background color
          '& .MuiListItemButton-root:hover': {
            backgroundColor: 'rgba(247, 247, 250, 1)',
            color: theme.palette.primary.main,
            borderRadius: '10px',
          },
        }}
      >
        <ListItemButton
          onClick={() => { nav('/hakkimizda') }}
          selected={loc.pathname.includes('hakkimizda')}
        >
          <ListItemText primary='Hakkımızda' />
        </ListItemButton>
        <ListItemButton
          onClick={() => { nav('/yonetim-kurulu') }}
          selected={loc.pathname.includes('yonetim-kurulu')}
        >
          <ListItemText primary='Yönetim Kurulu' />
        </ListItemButton>
        <ListItemButton
          onClick={() => { nav('/yoneticilerimiz') }}
          selected={loc.pathname.includes('yoneticilerimiz')}
        >
          <ListItemText primary='Yöneticilerimiz' />
        </ListItemButton>
      </List>
    </Paper>
  )
}
