import { Fon } from "./types";

export const playStoreUrl = "https://play.google.com/store/apps/details?id=com.foneria";
export const appStoreUrl = "https://apps.apple.com/sa/app/foneria-herkese-portf%C3%B6yhizmeti/id1570783238";

export const otonomFundsMock: Fon[] = [
  {
    name: "Otonom Portföy - GO1",
    longName: "Foneria Otonom Portföy - GO1",
    code: "GO1",
    description: "",
    suggestedMaturationTime: "",
    lastOneYearReturn: 39.69,
    logoUrl: "https://static.foneria.com.tr/firebase-storage/issuers/foneria_logo.png",
    pricePerItem: 1.515749,
    strategy: "",
    fundDuration: "",
    sellingTarget: "",
    minimumInvestment: "",
    fundStartDate: "",
    exitTime: "",
    fundEntryExitFees: "",
    purchasingFundParticipationShares: "",
    managementFee: "",
    riskGrade: 2,
    informationFiles: [
      {
        name: "Foneria Portföy Birinci Fon Sepeti Fonu Yatırımcı Bilgi Formu",
        url: "https://foneriaportfoy.com.tr/wp-content/uploads/2023/09/Birinci-Fon-Sepeti-YBF.pdf"
      },
      {
        name: "Foneria Portföy Birinci Fon Sepeti Fonu İzahname",
        url: "https://foneriaportfoy.com.tr/wp-content/uploads/2023/09/1_Birinci-Fon-Sepeti-Fonu-Izahnamesi-Guncel.pdf"
      }
    ],
  },
  {
    name: "Otonom Portföy - GO2",
    longName: "Foneria Otonom Portföy - GO2",
    code: "GO2",
    description: "",
    suggestedMaturationTime: "",
    lastOneYearReturn: 54.44,
    logoUrl: "https://static.foneria.com.tr/firebase-storage/issuers/foneria_logo.png",
    pricePerItem: 1.756116,
    strategy: "",
    fundDuration: "",
    sellingTarget: "",
    minimumInvestment: "",
    fundStartDate: "",
    exitTime: "",
    fundEntryExitFees: "",
    purchasingFundParticipationShares: "",
    managementFee: "",
    riskGrade: 4,
    informationFiles: [
      {
        name: "Foneria Portföy İkinci Fon Sepeti Fonu Yatırımcı Bilgi Formu",
        url: "https://foneriaportfoy.com.tr/wp-content/uploads/2023/09/Birinci-Fon-Sepeti-YBF.pdf"
      },
      {
        name: "Foneria Portföy İkinci Fon Sepeti Fonu İzahname",
        url: "https://foneriaportfoy.com.tr/wp-content/uploads/2023/09/1_Birinci-Fon-Sepeti-Fonu-Izahnamesi-Guncel.pdf"
      }
    ],
  },
  {
    name: "Otonom Portföy - GO3",
    longName: "Foneria Otonom Portföy - GO3",
    code: "GO3",
    description: "",
    suggestedMaturationTime: "",
    lastOneYearReturn: 70.16,
    logoUrl: "https://static.foneria.com.tr/firebase-storage/issuers/foneria_logo.png",
    pricePerItem: 1.515749,
    strategy: "",
    fundDuration: "",
    sellingTarget: "",
    minimumInvestment: "",
    fundStartDate: "",
    exitTime: "",
    fundEntryExitFees: "",
    purchasingFundParticipationShares: "",
    managementFee: "",
    riskGrade: 5,
    informationFiles: [
      {
        name: "Foneria Portföy Üçüüncü Fon Sepeti Fonu Yatırımcı Bilgi Formu",
        url: "https://foneriaportfoy.com.tr/wp-content/uploads/2023/09/Birinci-Fon-Sepeti-YBF.pdf"
      },
      {
        name: "Foneria Portföy Üçüncü Fon Sepeti Fonu İzahname",
        url: "https://foneriaportfoy.com.tr/wp-content/uploads/2023/09/1_Birinci-Fon-Sepeti-Fonu-Izahnamesi-Guncel.pdf"
      }
    ],
  },
  {
    name: "Otonom Portföy - GO4",
    longName: "Foneria Otonom Portföy - GO4",
    code: "GO4",
    description: "",
    suggestedMaturationTime: "string",
    lastOneYearReturn: 84.72,
    logoUrl: "https://static.foneria.com.tr/firebase-storage/issuers/foneria_logo.png",
    pricePerItem: 2.152683,
    strategy: "",
    fundDuration: "",
    sellingTarget: "",
    minimumInvestment: "",
    fundStartDate: "",
    exitTime: "",
    fundEntryExitFees: "",
    purchasingFundParticipationShares: "",
    managementFee: "",
    riskGrade: 6,
    informationFiles: [
      {
        name: "Foneria Portföy Dördüncü Fon Sepeti Fonu Yatırımcı Bilgi Formu",
        url: "https://foneriaportfoy.com.tr/wp-content/uploads/2023/09/Birinci-Fon-Sepeti-YBF.pdf"
      },
      {
        name: "Foneria Portföy Dördüncü Fon Sepeti Fonu İzahname",
        url: "https://foneriaportfoy.com.tr/wp-content/uploads/2023/09/1_Birinci-Fon-Sepeti-Fonu-Izahnamesi-Guncel.pdf"
      }
    ],
  },
]

export const dateFormat = (date: string) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();
  return [day, month, year].join('.');
}