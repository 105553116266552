import { Box, Button, Card, TextField, IconButton } from '@mui/material';
import React, { useContext } from 'react';
import { ArrowUpward, ArrowDownward, ArrowBack, ArrowForward, Delete } from '@mui/icons-material';

import fa221 from '../assets/pdfs/fa-22-1.pdf';
import fa222 from '../assets/pdfs/fa-22-2.pdf';
import fa223 from '../assets/pdfs/fa-22-3.pdf';
import fa224 from '../assets/pdfs/fa-22-4.pdf';
import fa231 from '../assets/pdfs/fa-23-1.pdf';
import fa232 from '../assets/pdfs/fa-23-2.pdf';
import fa233 from '../assets/pdfs/fa-23-3.pdf';
import fa234 from '../assets/pdfs/fa-23-4.pdf';
import fi221 from '../assets/pdfs/fi-22-1.pdf';
import fi222 from '../assets/pdfs/fi-22-2.pdf';
import fi223 from '../assets/pdfs/fi-22-3.pdf';
import fi224 from '../assets/pdfs/fi-22-4.pdf';
import fi231 from '../assets/pdfs/fi-23-1.pdf';
import fi232 from '../assets/pdfs/fi-23-2.pdf';
import fi233 from '../assets/pdfs/fi-23-3.pdf';
import fi234 from '../assets/pdfs/fi-23-4.pdf';
import { baseUrl } from '../utils/constants';
import DeleteDialog from './DeleteDialog';
import { deleteFile, uploadFile } from '../services/AppServices';
import { AuthContext } from '../contexts/AuthContext';
import { useAlert } from '../utils/useAlert';

type Props = {
  handleSubmit: (values: any, type: string) => void;
  reportsData: any;
};

export default function FinancialInfoEditor({ handleSubmit, reportsData }: Props) {
  const sampleFile = {
    id: 13,
    fieldname: 'file',
    name: 'Foneria Portföy Birinci Fon Sepeti Fonu Yatırımcı Bilgi Formu',
    originalname: 'Birinci-Fon-Sepeti-YBF.pdf',
    encoding: '7bit',
    mimetype: 'application/pdf',
    destination: './uploads',
    filename: '151f2817072bebe76b423cd2d63209ac',
    path: 'uploads/151f2817072bebe76b423cd2d63209ac',
    size: 587623,
    createdAt: '2024-01-22T20:30:52.122Z',
    updatedAt: '2024-01-22T20:30:52.122Z',
    fonId: 5,
    announcementId: null,
    financialDataId: null,
    umbrellaId: null,
  };

  type Report = {
    quarter: string;
    faaliyetUrl?: any;
    finansalUrl?: any;
  };

  type Year = {
    id: number;
    year: string;
    reports: Report[];
  };

  const reportsDatas: Year[] = [
    {
      id: 1,
      year: '2022',
      reports: [
        { quarter: '1. Çeyrek', faaliyetUrl: sampleFile, finansalUrl: sampleFile },
        { quarter: '2. Çeyrek', faaliyetUrl: sampleFile, finansalUrl: sampleFile },
        { quarter: '3. Çeyrek', faaliyetUrl: sampleFile, finansalUrl: sampleFile },
        { quarter: '4. Çeyrek', faaliyetUrl: sampleFile, finansalUrl: sampleFile },
      ],
    },
    {
      id: 2,
      year: '2023',
      reports: [
        { quarter: '1. Çeyrek', faaliyetUrl: sampleFile, finansalUrl: sampleFile },
        { quarter: '2. Çeyrek', faaliyetUrl: sampleFile, finansalUrl: sampleFile },
        { quarter: '3. Çeyrek', faaliyetUrl: sampleFile, finansalUrl: sampleFile },
      ],
    },
  ];

  const [data, setData] = React.useState(reportsData as Year[] || []);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [deletingYearIndex, setDeletingYearIndex] = React.useState<number | null>(null);
  const [deletingQuarterIndex, setDeletingQuarterIndex] = React.useState<number | null>(null);
  const { token } = useContext(AuthContext);
  const showSnackbar = useAlert();

  const handleDeleteQuarter = (yearIndex: number, quarterIndex: number) => {
    setDeletingYearIndex(yearIndex);
    setDeletingQuarterIndex(quarterIndex);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      if (deletingQuarterIndex === -1) {
        const year = data[deletingYearIndex!];
        for (const quarter of year.reports) {
          if (quarter.faaliyetUrl) {
            await deleteFile(quarter.faaliyetUrl.id, token);
          }
          if (quarter.finansalUrl) {
            await deleteFile(quarter.finansalUrl.id, token);
          }
        }
        const newData = [...data];
        newData.splice(deletingYearIndex!, 1);
        setData(newData);
        setDeleteDialogOpen(false);
      } else {
        const quarter = data[deletingYearIndex!].reports[deletingQuarterIndex!];
        if (quarter.faaliyetUrl) {
          await deleteFile(quarter.faaliyetUrl.id, token);
        }
        if (quarter.finansalUrl) {
          await deleteFile(quarter.finansalUrl.id, token);
        }
        const newData = [...data];
        newData[deletingYearIndex!].reports.splice(deletingQuarterIndex!, 1);
        setData(newData);
        setDeleteDialogOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddYear = () => {
    const newData = [
      ...data,
      {
        id: data.length + 1,
        year: '',
        reports: [],
      },
    ];
    setData(newData);
  };

  const handleAddQuarter = (yearIndex: number) => {
    const newData = [...data];
    newData[yearIndex].reports.push({
      quarter: '',
      faaliyetUrl: null,
      finansalUrl: null,
    });
    setData(newData);
  };

  const moveYearUp = (index: number) => {
    if (index === 0) return;
    const newData = [...data];
    const temp = newData[index];
    newData[index] = newData[index - 1];
    newData[index - 1] = temp;
    setData(newData);
  };

  const moveYearDown = (index: number) => {
    if (index === data.length - 1) return;
    const newData = [...data];
    const temp = newData[index];
    newData[index] = newData[index + 1];
    newData[index + 1] = temp;
    setData(newData);
  };

  const moveQuarterUp = (yearIndex: number, quarterIndex: number) => {
    if (quarterIndex === 0) return;
    const newData = [...data];
    const temp = newData[yearIndex].reports[quarterIndex];
    newData[yearIndex].reports[quarterIndex] = newData[yearIndex].reports[quarterIndex - 1];
    newData[yearIndex].reports[quarterIndex - 1] = temp;
    setData(newData);
  };

  const moveQuarterDown = (yearIndex: number, quarterIndex: number) => {
    if (quarterIndex === data[yearIndex].reports.length - 1) return;
    const newData = [...data];
    const temp = newData[yearIndex].reports[quarterIndex];
    newData[yearIndex].reports[quarterIndex] = newData[yearIndex].reports[quarterIndex + 1];
    newData[yearIndex].reports[quarterIndex + 1] = temp;
    setData(newData);
  };

  const moveQuarterLeft = (yearIndex: number, quarterIndex: number) => {
    if (quarterIndex === 0) return;
    const newData = [...data];
    const temp = newData[yearIndex].reports[quarterIndex];
    newData[yearIndex].reports[quarterIndex] = newData[yearIndex].reports[quarterIndex - 1];
    newData[yearIndex].reports[quarterIndex - 1] = temp;
    setData(newData);
  };

  const moveQuarterRight = (yearIndex: number, quarterIndex: number) => {
    if (quarterIndex === data[yearIndex].reports.length - 1) return;
    const newData = [...data];
    const temp = newData[yearIndex].reports[quarterIndex];
    newData[yearIndex].reports[quarterIndex] = newData[yearIndex].reports[quarterIndex + 1];
    newData[yearIndex].reports[quarterIndex + 1] = temp;
    setData(newData);
  };

  const handleSelectFile = async (e: any, type: 'fa' | 'fi', yearIndex: number, quarterIndex: number) => {
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    const fileName = file.name;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', fileName);
    formData.append('type', 'returnLink');

    try {
      const res = await uploadFile(formData, token);
      const newData = [...data];
      if (type === 'fa') {
        newData[yearIndex].reports[quarterIndex].faaliyetUrl = res;
      } else {
        newData[yearIndex].reports[quarterIndex].finansalUrl = res;
      }
      setData(newData);
      showSnackbar('Dosya yüklendi', 'success');
    } catch (error) {
      console.log(error);
      showSnackbar('Dosya yüklenirken bir hata oluştu', 'error');
    } finally {
      e.target.value = null;
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {data.map((yearData, yearIndex) => (
        <Card
          key={yearData.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            gap: 2,
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <TextField
                label='Yıl'
                fullWidth
                value={yearData.year || ''}
                onChange={(e) => {
                  const newData = [...data];
                  newData[yearIndex].year = e.target.value;
                  setData(newData);
                }}
              />
              <IconButton onClick={() => moveYearUp(yearIndex)}>
                <ArrowUpward />
              </IconButton>
              <IconButton onClick={() => moveYearDown(yearIndex)}>
                <ArrowDownward />
              </IconButton>
              <IconButton onClick={() => handleDeleteQuarter(yearIndex, -1)}>
                <Delete />
              </IconButton>
            </Box>
            <Button
              variant='contained'
              onClick={() => handleAddQuarter(yearIndex)}
              sx={{ mb: 2 }}
            >
              Yeni Çeyrek Ekle
            </Button>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                width: '100%',
              }}
            >
              {yearData.reports.map((report, quarterIndex) => (
                <Card
                  key={`${yearData.id}-${quarterIndex}`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    p: 2,
                    width: '100%',
                  }}
                >
                  <TextField
                    label='Çeyrek'
                    sx={{ mb: 2 }}
                    value={report.quarter || ''}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[yearIndex].reports[quarterIndex].quarter = e.target.value;
                      setData(newData);
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                    }}
                  >
                    <Button
                      variant='contained'
                      component='label'
                    >
                      Faaliyet Raporu Seç
                      <input
                        type='file'
                        hidden
                        onChange={(e) => { handleSelectFile(e, 'fa', yearIndex, quarterIndex) }}
                      />
                    </Button>
                    {report.faaliyetUrl && (
                      <Button
                        variant='outlined'
                        onClick={() => {
                          if (report.faaliyetUrl) {
                            window.open(`${baseUrl}/file/${report.faaliyetUrl.id}`, '_blank');
                          }
                        }}
                      >
                        Faaliyet Raporunu Görüntüle
                      </Button>
                    )}
                    <Button
                      variant='contained'
                      component='label'
                    >
                      Finansal Rapor Seç
                      <input
                        type='file'
                        hidden
                        onChange={(e) => { handleSelectFile(e, 'fi', yearIndex, quarterIndex) }}
                      />
                    </Button>
                    {report.finansalUrl && (
                      <Button
                        variant='outlined'
                        onClick={() => {
                          if (report.finansalUrl) {
                            window.open(`${baseUrl}/file/${report.finansalUrl.id}`, '_blank');
                          }
                        }}
                      >
                        Finansal Raporu Görüntüle
                      </Button>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <IconButton onClick={() => moveQuarterLeft(yearIndex, quarterIndex)}>
                        <ArrowBack />
                      </IconButton>
                      <IconButton onClick={() => moveQuarterRight(yearIndex, quarterIndex)}>
                        <ArrowForward />
                      </IconButton>
                    </Box>
                    <Button
                      variant='outlined'
                      color='error'
                      onClick={() => handleDeleteQuarter(yearIndex, quarterIndex)}
                    >
                      Sil
                    </Button>
                  </Box>
                </Card>
              ))}
            </Box>
          </Box>
        </Card>
      ))}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <Button
          variant='contained'
          onClick={() => handleSubmit(data, 'finansal-bilgiler')}
        >
          Kaydet
        </Button>
        <Button variant='contained' onClick={handleAddYear}>
          Yeni Yıl Ekle
        </Button>
      </Box>

      <DeleteDialog
        title={deletingQuarterIndex === -1 ? 'Yılı Sil' : 'Çeyreği Sil'}
        description='Silmek istediğinize emin misiniz?'
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        deleting={deleting}
      />
    </Box>
  );
}
