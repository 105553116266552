import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { useContext } from 'react';
import useSWR from 'swr';
import { AuthContext } from '../contexts/AuthContext';
import { baseUrl } from '../utils/constants';
import { fetcher } from '../utils/global';
import { Fon } from '../utils/types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type FonSelectProps = {
  values: Fon[];
  onChange: (values: Fon[]) => void;
}

export default function FonSelect({ values, onChange }: FonSelectProps) {

  const { token } = useContext(AuthContext);

  const { data: fons, isLoading, error } = useSWR<Fon[]>(`${baseUrl}/fon`, (url: string) => fetcher(url, token));

  return (
    <Autocomplete
      multiple
      fullWidth
      loading={isLoading}
      id="checkboxes-tags-demo"
      options={fons || []}
      value={values}
      onChange={(e, values) => onChange(values)}
      disableCloseOnSelect
      getOptionLabel={(option) => `${option.name} - ${option.code}`}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name} - {option.code}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} fullWidth label="Fonlar" placeholder="Fonlar" />
      )}
    />
  );
}
